import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "components/Badge/Badge.js";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";

// core components
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";
import "views/Style/WithdrawStyle.css";

const useStyles = makeStyles(styles);

function Withdraw({ t }) {
  const mod = "withdraw";
  const classes = useStyles();
  const history = useHistory();
  const app = useSelector((state) => state.appState);
  const [notify, setNotify] = useState(null);
  const [member, setMember] = useState({});
  const [list, setList] = useState([]);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    setLoading(true);
    setAmount("");
    setList([]);
    setMember({});

    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setMember(result.member);
        setList(result.history);
      } else {
        history.push("/home");
      }
      setLoading(false);
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="bc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const requestWithdraw = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_add_title")}
        onConfirm={() => {
          setAlert(null);

          setLoading(true);
          PostData(mod, "request", {
            token: app.token,
            lang: app.lang,
            amount: amount,
          }).then((result) => {
            if (result.status === "OK") {
              showNotify(t(result.msg), "primary");
              init();
            } else if (result.status === "ALERT") {
              showNotify(t(result.msg), "danger");
            } else {
              history.push("/home");
            }

            setLoading(false);
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_add_msg")}
      </SweetAlert>
    );
  };

  const cancelWithdraw = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_delete_title")}
        onConfirm={() => {
          setAlert(null);

          setLoading(true);
          PostData(mod, "cancel", {
            token: app.token,
            lang: app.lang,
            code: item.code,
          }).then((result) => {
            if (result.status === "OK") {
              showNotify(t(result.msg), "primary");
              init();
            } else if (result.status === "ALERT") {
              showNotify(t(result.msg), "danger");
            } else {
              history.push("/home");
            }

            setLoading(false);
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_delete_msg")}
      </SweetAlert>
    );
  };

  const renderBusd = () => {
    return (
      <div className="box-item">
        <table>
          <tbody>
            <tr>
              <td width="150">BUSD Address :</td>
              <td>
                <div className="text-account">{member.busd}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderBalance = () => {
    return (
      <div className="box-balance">
        <table width="100%" cellPadding={5} cellSpacing={0}>
          <tbody>
            <tr>
              <td className="icon">
                <i className="fas fa-wallet fa-2x" />
              </td>
              <td className="balance">
                {Func.numberDisplay(member.balance)} $
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderWithdraw = () => {
    return (
      <div className="box-item">
        <form>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id="amount"
                fullWidth
                InputProps={{
                  type: "number",
                  className: "text-topup",
                  value: amount,
                  onChange: (e) => {
                    setAmount(e.target.value);
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>{t(`${mod}.amount`)} :</span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fas fa-dollar-sign" />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Button
                className="btn-price"
                size="sm"
                block
                color="tumblr"
                onClick={() => setAmount("10")}
              >
                10 $
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                className="btn-price"
                size="sm"
                block
                color="tumblr"
                onClick={() => setAmount("100")}
              >
                100 $
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                className="btn-price"
                size="sm"
                block
                color="tumblr"
                onClick={() => setAmount("500")}
              >
                500 $
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                className="btn-price"
                size="sm"
                block
                color="tumblr"
                onClick={() => setAmount("1000")}
              >
                1,000 $
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                className="btn-submit"
                onClick={requestWithdraw}
                block
              >
                <i className="fal fa-hands-usd"></i> {t(`${mod}.submit`)}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };

  const renderHistory = () => {
    return (
      <div className="box-data">
        <h4 className="history-title">
          <i className="fas fa-history" /> ประวัติการถอนเงิน
        </h4>
        <List dense={true}>
          {list.map((item) => (
            <div key={item.code}>
              <ListItem>
                <ListItemAvatar>
                  {item.status === "1" ? (
                    <button onClick={() => cancelWithdraw(item)}>
                      <i className="fal fa-times-circle fa-2x color-danger" />
                    </button>
                  ) : (
                    <i className="fad fa-envelope-open-dollar fa-2x color-default" />
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={<>{Func.numberDisplay(item.amount)} $</>}
                  secondary={
                    <i className="text-secondary">
                      {Func.dateTimeDisplay(item.date_create)}
                    </i>
                  }
                />
                <ListItemSecondaryAction>
                  <Badge color={item.status_color}>
                    <i className={item.status_icon} /> {item.status_name}
                  </Badge>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider className="divider" variant="middle" />
            </div>
          ))}
        </List>
      </div>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={"/home"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">{t(`menu.withdraw`)}</h3>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="withdraw">
          {renderAppBar()}
          {renderBalance()}
          {renderBusd()}
          {renderWithdraw()}
          {list.length > 0 ? renderHistory() : null}
          {renderLoading()}
          {notify}
          {alert}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Withdraw);
