import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { saveAs } from "file-saver";
import copy from "copy-to-clipboard";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";

// core components
import Button from "components/CustomButtons/Button.js";
import Badge from "components/Badge/Badge.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import * as Func from "services/Function";
import "views/Style/ShoppingStyle.css";

const useStyles = makeStyles(styles);

const initAttr = {
  payment: "",
  chain: "",
  cooldown: 0,
  timelimit: 0,
  active: "N",
  amount: "0.00",
  vat: "0.00",
  total: "0.00",
};

function Order({ t }) {
  const mod = "order";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const history = useHistory();
  const { id } = useParams();
  const [attr, setAttr] = useState(initAttr);
  const [list, setList] = useState([]);
  const [coupon, setCoupon] = useState([]);
  const [loading, setLoading] = useState(false);
  const [member, setMember] = useState({});
  const [notify, setNotify] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    if (id === undefined) init();
    else load();
  }, [id]);

  const init = () => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);
    setCoupon([]);
    setMember({});

    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setList(result.list);
      }
      setLoading(false);
    });
  };

  const load = () => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);
    setCoupon([]);
    setMember({});

    PostData(mod, "load", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.order);
        setMember(result.member);
        setList(result.list);
        setCoupon(result.coupon);

        if (result.order.status === "1") {
          setTimeout(function () {
            reload();
          }, 30000);
        }
      }
      setLoading(false);
    });
  };

  const reload = () => {
    PostData(mod, "reload", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        window.location.reload();
      } else if (result.status === "PENDING") {
        setTimeout(function () {
          reload();
        }, 30000);
      }
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="bc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const copyText = (text) => {
    copy(text);
    showNotify(t(`alert.copysuccess`), "primary");
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={id === undefined ? "/home" : "/order"}>
                <IconButton>
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              {id === undefined ? (
                <h3 className="header-title">{t(`menu.order`)}</h3>
              ) : (
                <h3 className="header-title">ID : {attr.id}</h3>
              )}
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderList = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <List dense={true}>
            {list.map((item, i) => (
              <div key={i} className="read">
                <ListItem
                  button
                  onClick={() => history.push(`/order/${item.code}`)}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <img src={item.filepic} width="50" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<div className="text-primary">{item.id}</div>}
                    secondary={
                      <i className="text-secondary">
                        {Func.dateTimeDisplay(item.date_create)}
                      </i>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Badge color={item.status_color}>
                      <i className={item.status_icon} /> {item.status_name}
                    </Badge>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider className="divider" variant="middle" />
              </div>
            ))}
          </List>
        </Grid>
      </Grid>
    );
  };

  const renderCountdown = () => {
    return (
      <div className="box-item">
        <div className="timer-wrapper">
          <CountdownCircleTimer
            isPlaying
            duration={attr.timelimit}
            initialRemainingTime={attr.cooldown}
            colors={["#52f20d", "#fbef0d", "#ff8000", "#A30000"]}
            colorsTime={[900, 600, 300, 0]}
            onComplete={() => {
              window.location.reload();
            }}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>
      </div>
    );
  };

  const renderOrder = () => {
    return (
      <div className="box-item">
        <table width="100%" cellPadding={3} cellSpacing={0} border="0">
          <tbody>
            {list.map((item, i) => (
              <tr key={i}>
                <td width="10%">
                  <img src={item.filepic} className="item-img" />
                </td>
                <td className="item-topic">
                  {item.product_name} [{item.price_name}]
                </td>
                <td width="40%" align="right" className="item-amount">
                  {Func.numberDisplay(item.price, 2)} ฿
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Time out...</div>;
    }

    var minutes = Math.floor(remainingTime / 60);
    var seconds = remainingTime - minutes * 60;

    return (
      <div className="timer">
        <div className="text">Remaining</div>
        <div className="value">
          {minutes}:{seconds.toString().padStart(2, "0")}
        </div>
        <div className="text">minutes</div>
      </div>
    );
  };

  const renderSummary = () => {
    return (
      <div className="box-item">
        <Grid container>
          <Grid item xs={6}>
            {t(`shopping.amount`)}
          </Grid>
          <Grid item xs={6} className={classes.right}>
            {attr.amount} ฿
          </Grid>
          <Grid item xs={6}>
            {t(`shopping.vat`)}
          </Grid>
          <Grid item xs={6} className={classes.right}>
            {attr.vat} ฿
          </Grid>
          <Grid item xs={6}>
            {t(`shopping.total`)}
          </Grid>
          <Grid item xs={6} className={classes.right}>
            {attr.total} ฿
          </Grid>
          <Grid item xs={6}>
            {t(`shopping.discount`)}
          </Grid>
          <Grid item xs={6} className={classes.right}>
            {attr.discount} ฿
          </Grid>
          <Grid item xs={6}>
            {t(`shopping.nettotal`)}
          </Grid>
          <Grid item xs={6} className={classes.right}>
            {attr.nettotal} ฿
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderQrCode = () => {
    return (
      <div className="box-item">
        <h5>{t(`shopping.scan_buyapp`)}</h5>
        <div className={classes.center}>
          <img src={attr.qrcode} />
        </div>
        <div className={classes.center}>
          <Button
            size="sm"
            color="primary"
            onClick={() => saveAs(attr.qrcode, `${attr.id}.jpg`)}
            round
          >
            <i className="fal fa-download" />
            Download Image
          </Button>
        </div>
      </div>
    );
  };

  const renderCoupon = () => {
    return coupon.map((item, i) => (
      <div className="box-item" key={i}>
        <table width="100%" cellPadding={3} cellSpacing={0} border="0">
          <tbody>
            <tr>
              <td className="item-topic">
                {item.price_name} [ {item.id} ]
              </td>
              <td width="40%" align="right" className="item-amount">
                {item.enable === "Y" ? (
                  <Button
                    type="button"
                    color="primary"
                    size="sm"
                    simple
                    className="btn-copy"
                    onClick={() => copyText(item.id)}
                  >
                    <i className="fal fa-copy" /> {t(`affiliate.copy`)}
                  </Button>
                ) : (
                  <span>
                    <i className="fad fa-check-circle" /> {t(`shopping.used`)}
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ));
  };

  const renderStatus = () => {
    return (
      <h3 className={`item-status item-${attr.status_color}`}>
        <i className={attr.status_icon} /> {attr.status_name}
      </h3>
    );
  };

  const renderLoad = () => {
    return (
      <div className="bill">
        {renderStatus()}
        {renderOrder()}
        {renderSummary()}
        {attr.active === "Y" ? renderCountdown() : null}
        {attr.active === "Y" ? renderQrCode() : null}
        {renderCoupon()}
      </div>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="shopping">
          {renderAppBar()}
          {id === undefined ? renderList() : renderLoad()}
          {renderLoading()}
          {notify}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Order);
