import {
  setEnLng,
  setThLng,
  SET_TOKEN,
  SET_LANGUAGE,
  SET_SETUP,
} from "../types";

const initialState = {
  token: "",
  lang: "th",
  setup: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case setEnLng:
      return { ...state, lang: "en" };
    case setThLng:
      return { ...state, lang: "th" };
    case SET_LANGUAGE:
      return { ...state, lang: action.payload };
    case SET_TOKEN:
      return { ...state, token: action.payload };
    case SET_SETUP:
      return { ...state, setup: action.payload };
    default:
      return state;
  }
};
