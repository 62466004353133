import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import copy from "copy-to-clipboard";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";
import IconButton from "@material-ui/core/IconButton";

// core components
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Divider from "@material-ui/core/Divider";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import i18n from "components/Translation/Translate.js";
import { setToken, setLanguage } from "redux/actions/AppStateAction";
import { setUser } from "redux/actions/UserAction";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";
import "views/Style/ProfileStyle.css";

const useStyles = makeStyles(styles);

function Profile({ t }) {
  const mod = "profile";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const dispatch = useDispatch();
  const [member, setMember] = useState({});
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setMember(result.member);
      } else {
        Func.exitApp();
      }
      setLoading(false);
    });
  };

  const changeLanguage = () => {
    const lang = app.lang === "th" ? "en" : "th";

    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };

  const logout = () => {
    setLoading(true);

    PostData(mod, "logout", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      dispatch(setUser({}));
      dispatch(setToken(""));
      changeLanguage("en");

      window.location.replace("/authen");
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="bc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const copyText = (text) => {
    copy(text);
    showNotify(t(`alert.copysuccess`), "primary");
  };

  const renderProfile = () => {
    return (
      <Grid container className="box-member">
        <Grid item xs={12}>
          <h4 className="title">
            <i className="fas fa-user-tie color-primary" /> {member.name}
          </h4>
          <table width="100%" cellPadding={3}>
            <tbody>
              <tr>
                <td width="30%" valign="top">
                  <div className="user-img">
                    <img src={member.filepic} width="70" className="img" />
                  </div>
                </td>
                <td valign="top">
                  <div className="user">
                    <i className="fad fa-id-badge icn" /> {member.id}
                  </div>
                  <div className="user">
                    <i className="fad fa-wreath icn" /> {member.status}
                  </div>
                  <div className="user">
                    <i className="fad fa-calendar icn" /> {member.register}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={"/home"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">{t(`menu.profile`)}</h3>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderLogout = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Button color="danger" block className="btn-exit" onClick={logout}>
            <i className="fas fa-lock"></i> {t(`profile.logout`)}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="profile">
          {renderAppBar()}
          {renderProfile()}
          {renderLoading()}
          {notify}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Profile);
