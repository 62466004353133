import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import liff from "@line/liff";
import GoogleLogin from "react-google-login";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import InputIcon from "@material-ui/icons/Input";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostSecure } from "services/Request";
import { setToken, setLanguage } from "redux/actions/AppStateAction";
import { setUser } from "redux/actions/UserAction";
import * as Func from "services/Function";
import * as Defi from "services/Define";
import styles from "assets/jss/main-style.js";
import { usePageName } from "react-page-name";
import "views/Style/AuthStyle.css";

const useStyles = makeStyles(styles);

const initAttr = {
  userId: "",
  displayName: "",
  pictureUrl: "",
  statusMessage: "",
  idToken: "",
  email: "",

  acc_no: "",
  firstname: "",
  lastname: "",
  tel: "",
  lineid: "",
  pass: "",
  pass_check: "",
  ref_code: "",
  bank: "",
  captcha: "",
  upline: "",
  upline_name: "",
};

function Authen({ t }) {
  const mod = "authen";
  const classes = useStyles();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.appState);
  const history = useHistory();
  const txtUsername = useRef(null);
  const { id } = useParams();
  const [alert, setAlert] = useState(null);
  const [attr, setAttr] = useState(initAttr);
  const [status, setStatus] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [signin, setSignin] = useState(false);
  const [connect, setConnect] = useState(false);
  const [social, setSocial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(null);

  usePageName(app.setup.name + " :: " + i18n.t(`authen.login`));

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const init = () => {
    setLoading(true);

    PostSecure(mod, "signin", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        // window.location.replace("/home");
        history.push("/home");
      } else {
        setSignin(true);
        setStatus("LOGIN");
      }

      setLoading(false);
    });
  };

  const lineLogin = () => {
    liff.init(
      { liffId: Defi.LIFF_ID },
      () => {
        if (liff.isLoggedIn()) {
          const idToken = liff.getIDToken();

          liff
            .getProfile()
            .then((profile) => {
              authen({
                ...profile,
                idToken: idToken,
                email: liff.getDecodedIDToken().email,
                type: "LINE",
              });
            })
            .catch((err) => console.error(err));
        } else {
          liff.login();
        }
      },
      (err) => console.error(err)
    );
  };

  const authen = (item) => {
    setLoading(true);
    setAttr({
      ...attr,
      type: item.type,
      userId: item.userId,
      displayName: item.displayName,
      pictureUrl: item.pictureUrl,
      statusMessage: item.statusMessage,
      idToken: item.idToken,
      email: item.email,
    });

    PostSecure(mod, "authen", {
      token: app.token,
      lang: app.lang,
      data: {
        type: item.type,
        token: item.idToken,
        userid: item.userId,
        name: item.displayName,
        filepic: item.pictureUrl,
        status: item.statusMessage,
        email: item.email,
      },
    }).then((result) => {
      if (result.status === "OK") {
        // dispatch(setUser(result.data));
        // dispatch(setToken(result.token));
        // changeLanguage(result.data.language);
        // if (result.data.pin === "Y") {
        //   window.location.replace("/pin");
        // } else {
        //   window.location.replace("/home");
        // }
      } else if (result.status === "CONNECT") {
        setStatus("CONNECT");
      } else {
        showNotify(t(result.msg), "danger");
      }

      setLoading(false);
    });
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };

  const formLogin = (event) => {
    event.preventDefault();

    setLoading(true);
    PostSecure(mod, "login", {
      username: username,
      password: password,
      status: status,
      data: {
        type: attr.type,
        token: attr.idToken,
        userid: attr.userId,
        name: attr.displayName,
        filepic: attr.pictureUrl,
        status: attr.statusMessage,
        email: attr.email,
      },
    }).then((result) => {
      if (result.status === "OK") {
        dispatch(setUser(result.data));
        dispatch(setToken(result.token));
        changeLanguage(result.data.language);

        window.location.replace("/home");
      } else {
        setLoading(false);
        showNotify(t(result.msg), "danger");
      }
    });
  };

  const googleError = (response) => {
    showNotify(t(`alert.loginfail`), "danger");
    console.log(response);
  };

  const googleSuccess = (response) => {
    var profile = response.getBasicProfile();

    authen({
      type: "GOOGLE",
      idToken: response.accessToken,
      userId: profile.getId(),
      displayName: profile.getName(),
      pictureUrl: profile.getImageUrl(),
      statusMessage: "",
      email: profile.getEmail(),
    });

    // console.log("TOKEN: " + response.accessToken);
    // console.log("ID: " + profile.getId());
    // console.log("Name: " + profile.getName());
    // console.log("Image URL: " + profile.getImageUrl());
    // console.log("Email: " + profile.getEmail());
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderLogin = () => {
    return (
      <form noValidate onSubmit={formLogin}>
        <div className="login">
          <GridContainer>
            <GridItem xs={12}>
              <div className="box-img">
                <img src={app.setup.logo} width="88" height="88" />
              </div>

              <div className="box-outer" style={{ margin: "30px 0 15px 0" }}>
                <div
                  className="main_box"
                  style={{
                    background: "transparent",
                    height: "auto",
                    padding: "0",
                  }}
                ></div>
              </div>
            </GridItem>
          </GridContainer>
          {status === "LOGIN" || status === "CONNECT" ? ( //USERNAME | PASSWORD
            <GridContainer>
              <GridItem xs={12}>
                <TextField
                  id="email"
                  fullWidth
                  InputProps={{
                    inputRef: txtUsername,
                    style: { marginBottom: "0" },
                    type: "text",
                    maxLength: 10,
                    className: "text-userpass",
                    onChange: (e) => {
                      setUsername(e.target.value);
                    },
                    startAdornment: (
                      <InputAdornment position="start">E-mail :</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <EmailOutlinedIcon />
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                />
                <TextField
                  id="password"
                  fullWidth
                  InputProps={{
                    style: { marginBottom: "0" },
                    type: "password",
                    className: "text-userpass",
                    onChange: (e) => {
                      setPassword(e.target.value);
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        Password :
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockOpenIcon />
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                />
              </GridItem>
              <GridItem xs={12} className="box-signin">
                {loading ? (
                  <Button
                    type="button"
                    className="btn-signin"
                    block
                    color="warning"
                    size="sm"
                  >
                    <HourglassEmptyIcon /> Loading ...
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className="btn-signin"
                    block
                    color="primary"
                    size="sm"
                  >
                    <InputIcon />
                    {t(`${mod}.signin`)}
                  </Button>
                )}
              </GridItem>
            </GridContainer>
          ) : null}
          {status === "LOGIN" ? ( //REGISTER | FORGETPASS
            <GridContainer>
              <GridItem xs={12}></GridItem>
              <GridItem xs={6}>
                <Button
                  color="primary"
                  size="sm"
                  className="btn-regis"
                  simple
                  onClick={() =>
                    window.location.replace("https://promote.octatrade.me")
                  }
                >
                  Register
                </Button>
              </GridItem>
              <GridItem xs={6}>
                <Button color="primary" size="sm" className="btn-forget" simple>
                  Forget password
                </Button>
              </GridItem>
            </GridContainer>
          ) : null}
          {status === "LOGIN" || status === "SOCIAL" ? (
            <GridContainer>
              {status === "SOCIAL" ? (
                <GridItem xs={12}>
                  <h4 className="text-connect">Connect your social account</h4>
                </GridItem>
              ) : (
                <GridItem xs={12}>
                  <h4 className="text-connect">Login by social account</h4>
                </GridItem>
              )}
              {id === "liff" ? null : (
                <GridItem xs={12}>
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    buttonText="Login with google"
                    disabled={false}
                    onSuccess={googleSuccess}
                    onFailure={googleError}
                    className={"btn-google"}
                    cookiePolicy={"single_host_origin"}
                  />
                </GridItem>
              )}
              <GridItem xs={12}>
                <Button
                  className="btn-line"
                  color="success"
                  onClick={lineLogin}
                >
                  <i className="fab fa-line fa-2x" />
                  <i className="space" /> Login with line
                </Button>
              </GridItem>
            </GridContainer>
          ) : null}
          {status === "SOCIAL" || status === "CONNECT" ? (
            <GridContainer>
              <GridItem xs={12}>
                <br />
                <Button
                  color="warning"
                  size="sm"
                  simple
                  onClick={() => setStatus("LOGIN")}
                >
                  <i className="fad fa-arrow-alt-circle-left fa-2x" /> Back to
                  login
                </Button>
              </GridItem>
            </GridContainer>
          ) : null}
        </div>
      </form>
    );
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.fullPage}>
        <div className={classes.container}>
          {signin ? renderLogin() : null}
          {renderLoading()}
          {notify}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Authen);
