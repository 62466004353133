import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import IconButton from "@material-ui/core/IconButton";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import Snackbar from "components/Snackbar/Snackbar.js";

import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";
import "views/Style/ProfileStyle.css";

const useStyles = makeStyles(styles);

const initAttr = {
    busd: "",
};

const initError = {
    busd: false,
};

function CommEdit({ t }) {
    const mod = "profile";
    const history = useHistory();
    const classes = useStyles();
    const app = useSelector((state) => state.appState);
    const [alert, setAlert] = useState(null);
    const [attr, setAttr] = useState(initAttr);
    const [error, setError] = useState(initError);
    const [loading, setLoading] = useState(false);
    const [notify, setNotify] = useState(null);

    useEffect(() => {
        i18n.changeLanguage(app.lang);

        init();
    }, []);

    const init = () => {
        setLoading(true);
        PostData(mod, "init", {
            token: app.token,
            lang: app.lang,
        }).then((result) => {
            if (result.status === "OK") {
                setAttr(result.member);
            } else {
                Func.exitApp();
            }
            setLoading(false);
        });
    };

    const checkEmpty = () => {
        let result = true;

        if (attr.payment === "2") {
            if (!Func.verifyLength(attr.busd, 5)) result = false;
        }

        return result;
    };

    const edit = () => {
        if (!checkEmpty()) {
            showNotify(t("alert.pleaseinsert"), "danger");
        } else {
            setAlert(
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={t("alert.confirm_edit_title")}
                    onConfirm={() => {
                        setAlert(null);
                        setLoading(true);

                        PostData(mod, "changecomm", {
                            token: app.token,
                            lang: app.lang,
                            payment: attr.payment,
                            busd: attr.busd,
                        }).then((result) => {
                            setLoading(false);

                            if (result.status === "OK") {
                                showNotify(t(result.msg), "primary");
                            } else if (result.status === "ALERT") {
                                showNotify(t(result.msg), "danger");
                            } else {
                                window.location.replace(`/home`)
                            }
                        });
                    }}
                    onCancel={() => setAlert(null)}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                    cancelBtnCssClass={classes.button + " " + classes.danger}
                    confirmBtnText={t("alert.ok")}
                    cancelBtnText={t("alert.cancel")}
                    showCancel
                >
                    {t("alert.confirm_edit_msg")}
                </SweetAlert>
            );

        }
    };

    const showNotify = (msg, color) => {
        setNotify(
            <Snackbar
                place="bc"
                color={color}
                icon={AlertIcon}
                message={msg}
                open={true}
                closeNotification={() => setNotify(null)}
                close
            />
        );

        setTimeout(function () {
            setNotify(null);
        }, 3000);
    };

    const renderAppBar = () => {
        return (
            <AppBar position="static" className="header-bg">
                <Toolbar className="toolbar">
                    <Grid container>
                        <Grid item xs={2}>
                            <NavLink to={"/business"}>
                                <IconButton aria-label="">
                                    <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                                </IconButton>
                            </NavLink>
                        </Grid>
                        <Grid item xs={8}>
                            <h3 className="header-title">{t(`menu.commedit`)}</h3>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    };

    const renderBUSD = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        id="busd"
                        fullWidth
                        helperText={
                            error.password ? (
                                <>
                                    <i className="fas fa-exclamation-triangle" /> BUSD (BEP20)
                                </>
                            ) : null
                        }
                        InputProps={{
                            type: "text",
                            className: "text-pass",
                            value: attr.busd,
                            onChange: (e) => {
                                setAttr({ ...attr, busd: e.target.value });
                                setError({
                                    ...error,
                                    busd: !Func.verifyLength(e.target.value, 5),
                                });
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    BEP20 :
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <VpnKeyIcon />
                                </InputAdornment>
                            ),
                            autoComplete: "off",
                            error: error.busd,
                        }}
                    />
                </Grid>
            </Grid>
        );
    };

    const renderBox = () => {
        return (
            <div className="box-pass">
                <div
                    className={
                        classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
                    }
                >
                    <FormControlLabel
                        control={
                            <Radio
                                checked={attr.payment === "1"}
                                onChange={(e) =>
                                    setAttr({
                                        ...attr,
                                        payment: e.target.value,
                                    })
                                }
                                value="1"
                                name="rdoPaymenType"
                                aria-label="WALLET"
                                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                                checkedIcon={
                                    <FiberManualRecord className={classes.radioChecked} />
                                }
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot,
                                }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                        }}
                        label={<span>Wallet</span>}
                    />
                </div>
                <div
                    className={
                        classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
                    }
                >
                    <FormControlLabel
                        control={
                            <Radio
                                checked={attr.payment === "2"}
                                onChange={(e) =>
                                    setAttr({
                                        ...attr,
                                        payment: e.target.value,
                                    })
                                }
                                value="2"
                                name="rdoPaymenType"
                                aria-label="BUSD"
                                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                                checkedIcon={
                                    <FiberManualRecord className={classes.radioChecked} />
                                }
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot,
                                }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                        }}
                        label={<span>BUSD</span>}
                    />
                </div>
                {attr.payment === "2" ? renderBUSD() : null}
            </div>
        );
    };

    const renderFooter = () => {
        return (
            <BottomNavigation
                onChange={(e, link) => {
                    if (link === "back") history.push(`/business`);
                    else if (link === "save") edit();
                }}
                showLabels
                className={"bottom"}
            >
                <BottomNavigationAction
                    label={
                        <div className="menu">
                            <i className="fad fa-times color-danger" /> {t(`button.cancel`)}
                        </div>
                    }
                    value="back"
                />
                <BottomNavigationAction
                    label={
                        <div className="menu">
                            {t(`button.save`)} <i className="fad fa-save color-success" />
                        </div>
                    }
                    value="save"
                />
            </BottomNavigation>
        );
    };

    const renderLoading = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    };

    return (
        <div className="block">
            <div className={classes.container}>
                <div className="profile">
                    {renderAppBar()}
                    {renderBox()}
                    {renderFooter()}
                    {renderLoading()}
                    {notify}
                    {alert}
                </div>
            </div>
        </div>
    );
}

export default withNamespaces()(CommEdit);
