import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import copy from "copy-to-clipboard";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";
import CloseIcon from "@material-ui/icons/Close";

// core components
import Snackbar from "components/Snackbar/Snackbar.js";
import Button from "components/CustomButtons/Button.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import * as Func from "services/Function";
import "views/Style/AffiliateStyle.css";

const useStyles = makeStyles(styles);

function Sponsor({ t }) {
  const mod = "sponsor";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const history = useHistory();
  const { id } = useParams();
  const [attr, setAttr] = useState({});
  const [list, setList] = useState([]);
  const [crumb, setCrumb] = useState([]);
  const [back, setBack] = useState({});
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [notify, setNotify] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setList(result.downline);
        setAttr(result.account);
        setCrumb(result.crumb);
        setBack(result.back);
      } else if (result.status === "ALERT") {
        history.push(`/sponsor`);
      }
      setLoading(false);
    });
  };

  const copyText = (text) => {
    copy(text);
    showNotify(t(`alert.copysuccess`), "primary");
  };

  const openLink = () => {
    setModal(false);
    history.push("/sponsor/" + attr.code);
    // window.location.replace("/sponsor/" + attr.code);
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="bc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink
                to={
                  back.code === undefined
                    ? "/business"
                    : `/sponsor/${back.code}`
                }
              >
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">{attr.name}</h3>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderInfo = () => {
    return (
      <Dialog
        open={modal}
        maxWidth={"sm"}
        keepMounted
        fullWidth
        onClose={() => setModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            color="white"
            round
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="close-modal"
            onClick={() => setModal(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            <i className="fa fa-user-tie color-primary" /> {attr.name}</h4>
        </DialogTitle>

        <DialogContent className={classes.modalBody}>
          <table
            className="tb-member"
            width="100%"
            cellPadding={5}
            cellSpacing={0}
          >
            <tbody>
              <tr>
                <td width="30%" valign="top">{t(`authen.id`)}:</td>
                <td onClick={() => copyText(attr.id)}>
                  {attr.id} <i className="fal fa-copy color-default" />
                </td>
              </tr>
              <tr>
                <td valign="top">{t(`authen.name`)}:</td>
                <td>{attr.firstname} {attr.lastname}</td>
              </tr>
              <tr>
                <td valign="top">{t(`authen.tels`)}:</td>
                <td onClick={() => copyText(attr.tel)}>
                  {attr.tel} <i className="fal fa-copy color-default" />
                </td>
              </tr>
              <tr>
                <td valign="top">{t(`authen.email`)}:</td>
                <td onClick={() => copyText(attr.email)}>
                  {attr.email} <i className="fal fa-copy color-default" />
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
    );
  };

  const renderCrumb = () => {
    if (crumb.length > 0)
      return (
        <div className="crumb">
          <i className="fas fa-user" />{" "}
          {crumb.map((item, i) => (
            <NavLink to={`/sponsor/${item.code}`} key={i} className="link">
              {" "}
              {item.firstname} /
            </NavLink>
          ))}{" "}
          {attr.firstname}
        </div>
      );
    else return null;
  };

  const renderList = () => {
    return (
      <div className="box-bg">
        <List dense={true}>
          {list.map((item, i) => (
            <div key={i} className="read">
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <img src={item.filepic} width="50" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <span
                      onClick={() => {
                        setAttr(item);
                        setModal(true);
                      }}
                      className="text-primary"
                    >
                      {item.name}
                    </span>
                  }
                  secondary={
                    <i className="text-secondary">
                      {Func.dateTimeDisplay(item.date_create)}
                    </i>
                  }
                />
                <ListItemSecondaryAction>
                  {item.count > 0 ? (
                    <IconButton
                      edge="end"
                      aria-label="view"
                      className="list-icon"
                      onClick={() => history.push(`/sponsor/${item.code}`)}
                    >
                      <i className="fas fa-angle-right" />
                    </IconButton>
                  ) : null}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="middle" />
            </div>
          ))}
        </List>
      </div>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="affiliate">
          {renderAppBar()}
          {renderCrumb()}
          {renderList()}
          {renderInfo()}
          {renderLoading()}
          {notify}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Sponsor);
