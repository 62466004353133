import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// @material-ui/icons

// core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import "views/Style/SettingStyle.css";

const useStyles = makeStyles(styles);

function Setting({ t }) {
  const mod = "profile";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const history = useHistory();
  const { id } = useParams();
  const [attr, setAttr] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.member);
      }
      setLoading(false);
    });
  };

  const closePin = () => {
    setLoading(true);
    PostData(mod, "closepin", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.member);
      }
      setLoading(false);
    });
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={"/home"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">{t(`menu.setting`)}</h3>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderList = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <List dense={true} className="box-item">
            {renderLanguage()}
            {renderPin()}
          </List>
        </Grid>
      </Grid>
    );
  };

  const renderLanguage = () => {
    return (
      <div>
        <ListItem>
          <ListItemAvatar>
            <i className="fal fa-language fa-2x color-primary" />
          </ListItemAvatar>
          <ListItemText
            primary={<div>{t(`profile.language`)}</div>}
            secondary={
              <span>
                <i>
                  {attr.language === undefined
                    ? null
                    : t(`profile.${attr.language}`)}
                </i>
              </span>
            }
          />
          <ListItemSecondaryAction>
            <NavLink to={`/changelanguage`}>
              <IconButton edge="end" aria-label="delete" className="status">
                <i className="fal fa-edit" />
              </IconButton>
            </NavLink>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </div>
    );
  };

  const renderOnOff = () => {
    return (
      <Switch
        checked={attr.pin === "Y"}
        onChange={(e) => {
          if (e.target.checked) history.push("/pinregis");
          else closePin();
        }}
        color="primary"
      />
    );
  };

  const renderPin = () => {
    return (
      <div>
        <ListItem>
          <ListItemAvatar>
            <i className="fal fa-user-lock fa-2x color-primary" />
          </ListItemAvatar>
          <ListItemText
            primary={<div>Pin</div>}
            secondary={
              <span>
                {" "}
                <i>{attr.pin === "Y" ? t(`profile.on`) : t(`profile.off`)}</i>
              </span>
            }
          />
          <ListItemSecondaryAction>{renderOnOff()}</ListItemSecondaryAction>
        </ListItem>
      </div>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="setting">
          {renderAppBar()}
          {renderList()}
          {renderLoading()}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Setting);
