import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "components/Badge/Badge.js";
import Divider from "@material-ui/core/Divider";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";

// @material-ui/icons
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";

import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";

const useStyles = makeStyles(styles);

function History({ t }) {
  const mod = "history";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const [member, setMember] = useState({});
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    loadHistory();
  }, []);

  const loadHistory = () => {
    setLoading(true);
    setHistory([]);
    PostData(mod, "history", {
      token: app.token,
      lang: app.lang,
      count: 0,
    }).then((result) => {
      if (result.status === "OK") {
        setHistory(result.data);
      } else {
        Func.exitApp();
      }
      setLoading(false);
    });
  };

  const renderIcon = (item) => {
    switch (item.status) {
      case "1":
        return <img src={item.game_pic} alt={item.game_name} width="30" />;
      case "2":
        return <img src={item.game_pic} alt={item.game_name} width="30" />;
      case "3":
        return <AccountBalanceWalletIcon />;
      case "4":
        return <AccountBalanceIcon />;
      case "5":
        return <MonetizationOnIcon />;
      case "6":
        return <MonetizationOnIcon />;
      case "7":
        return <TrackChangesIcon />;
      case "8":
        return <TrackChangesIcon />;
      case "9":
        return <AssignmentIndIcon />;
      case "11":
        return <MoveToInboxIcon />;
      case "13":
        return <img src={item.game_pic} alt={item.game_name} width="30" />;
      default:
        return <DragIndicatorIcon />;
    }
  };

  const detailDisplay = (item) => {
    switch (item.status) {
      case "1":
        return (
          <div>
            {t(`game.wallet`)} <i className="fas fa-arrow-right" />{" "}
            {item.game_name}
            {item.pro_code > 0 ? (
              <i
                className="fas fa-gift"
                style={{ marginLeft: "5px", color: "#ff0080" }}
              />
            ) : null}
          </div>
        );
      case "2":
        return (
          <div>
            {item.game_name} <i className="fas fa-arrow-right" />{" "}
            {t(`game.wallet`)}
          </div>
        );
      case "3":
        return t(`game.deposit`);
      case "4":
        return t(`game.withdraw`);
      case "5":
        return t(`game.addcredit`);
      case "6":
        return t(`game.removecredit`);
      case "7":
        return t(`game.spin-win`);
      case "8":
        return t(`game.spin-loss`);
      case "9":
        return t(`game.cashback`);
      case "11":
        return (
          <div>
            {t(`game.cashbackbox`)} <i className="fas fa-arrow-right" />{" "}
            {t(`game.wallet`)}
          </div>
        );
      case "13":
        return (
          <div>
            {t(`game.cashbackbox`)} <i className="fas fa-arrow-right" />{" "}
            {item.game_name}
          </div>
        );
      default:
        return item.detail;
    }
  };

  const amountDisplay = (item) => {
    if (item.credit_bonus > 0) {
      return (
        <div>
          $ {Func.numberDisplay(item.amount, 0)} +{" "}
          {Func.numberDisplay(item.credit_bonus, 0)}
        </div>
      );
    } else {
      return <div>$ {Func.numberDisplay(item.amount, 0)}</div>;
    }
  };

  const renderHistory = () => {
    return (
      <Card style={{ marginTop: "0" }}>
        <CardBody className="history">
          <List dense={true}>
            {history.map((item, i) => (
              <div key={i}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>{renderIcon(item)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={detailDisplay(item)}
                    secondary={
                      <i className="text-secondary">
                        {Func.dateTimeDisplay(item.date_create)}
                      </i>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      className="status"
                    >
                      {/* <Badge color={item.status_color}>
                        <i className={item.status_icon} /> {item.status_name}
                      </Badge> */}
                      <Badge color={item.status_color}>
                        {amountDisplay(item)}
                      </Badge>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider
                  style={{ background: "var(--divider)" }}
                  variant="middle"
                />
              </div>
            ))}
          </List>
        </CardBody>
      </Card>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <div className="deposit">
      <AuthNavbar mod={mod} />
      <div className={classes.container}>
        {renderHistory()}
        {renderLoading()}
      </div>
    </div>
  );
}

export default withNamespaces()(History);
