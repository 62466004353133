import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

// @material-ui/icons
import CasinoIcon from "@material-ui/icons/Casino";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import SportsSoccerIcon from "@material-ui/icons/SportsSoccer";

// core components
import GameTab from "./GameTab";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import * as Func from "services/Function";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";

const useStyles = makeStyles(styles);

function Promotion({ t }) {
  const mod = "promotion";
  const classes = useStyles();
  const users = useSelector((state) => state.user);
  const app = useSelector((state) => state.appState);
  const [slot, setSlot] = useState([]);
  const [casino, setCasino] = useState([]);
  const [sport, setSport] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setSlot(result.slot);
        setCasino(result.casino);
        setSport(result.sport);
      } else {
        Func.exitApp();
      }
    });
  };

  const renderTab = () => {
    return (
      <GameTab
        headerColor="primary"
        tabs={[
          {
            tabName: <div>SLOT</div>,
            tabIcon: ViewWeekIcon,
            tabContent: renderSlot(),
          },
          {
            tabName: <div>Casino</div>,
            tabIcon: CasinoIcon,
            tabContent: renderCasino(),
          },
          {
            tabName: <div>SPORT</div>,
            tabIcon: SportsSoccerIcon,
            tabContent: renderSport(),
          },
        ]}
      />
    );
  };

  const renderSlot = () => {
    return (
      <>
        <Grid container>
          {slot.map((item) => (
            <Grid item xs={12} key={item.code}>
              <Card className="box">
                <CardHeader>
                  <h4 className={classes.cardTitle} style={{ color: "white" }}>
                    {item.name}
                  </h4>
                </CardHeader>
                <CardBody>
                  <div className="fileinput">
                    <img
                      src={item.filepic}
                      className={"thumbnail"}
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                  <span dangerouslySetInnerHTML={{ __html: item.content }} />
                </CardBody>
              </Card>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const renderCasino = () => {
    return (
      <>
        <Grid container>
          {casino.map((item) => (
            <Grid item xs={12} key={item.code}>
              <Card className="box">
                <CardHeader>
                  <h4 className={classes.cardTitle} style={{ color: "white" }}>
                    {item.name}
                  </h4>
                </CardHeader>
                <CardBody>
                  <div className="fileinput">
                    <img
                      src={item.filepic}
                      className={"thumbnail"}
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                  <span dangerouslySetInnerHTML={{ __html: item.content }} />
                </CardBody>
              </Card>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const renderSport = () => {
    return (
      <>
        <Grid container>
          {sport.map((item) => (
            <Grid item xs={12} key={item.code}>
              <Card className="box">
                <CardHeader>
                  <h4 className={classes.cardTitle} style={{ color: "white" }}>
                    {item.name}
                  </h4>
                </CardHeader>
                <CardBody>
                  <div className="fileinput">
                    <img
                      src={item.filepic}
                      className={"thumbnail"}
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                  <span dangerouslySetInnerHTML={{ __html: item.content }} />
                </CardBody>
              </Card>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <div className="promotion">
      <AuthNavbar mod={mod} />
      <div className={classes.container}>{renderTab()}</div>
    </div>
  );
}

export default withNamespaces()(Promotion);
