import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import * as Func from "services/Function";
import "views/Style/ShoppingStyle.css";

const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  request_address: "N",
  name: "",
  address: "",
  address2: "",
  province: "",
  zipcode: "",
  tel: "",
};

function VoucherPoint({ t }) {
  const mod = "voucher";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const history = useHistory();
  const { id } = useParams();
  const [attr, setAttr] = useState(initAttr);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(null);
  const [alert, setAlert] = useState(null);
  const [member, setMember] = useState({});

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    if (id === undefined) init();
    else load();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "voucher", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setList(result.list);
        setMember(result.member);
      }
      setLoading(false);
    });
  };

  const load = () => {
    setLoading(true);
    setAttr(initAttr);
    PostData(mod, "loadvoucher", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);
        setMember(result.member);
      }
      setLoading(false);
    });
  };

  const request = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_add_title")}
        onConfirm={() => {
          setAlert(null);

          setLoading(true);
          PostData(mod, "request", {
            token: app.token,
            lang: app.lang,
            id: id,
            data: {
              name: attr.name,
              address: attr.address,
              address2: attr.address2,
              province: attr.province,
              zipcode: attr.zipcode,
              tel: attr.tel,
            },
          }).then((result) => {
            if (result.status === "OK") {
              showNotify(t(result.msg), "primary");
              setTimeout(function () {
                window.location.replace(result.url);
              }, 1000);
            } else if (result.status === "ALERT") {
              showNotify(t(result.msg), "danger");
            } else {
              Func.exitApp();
            }
            setLoading(false);
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_add_msg")}
      </SweetAlert>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="bc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={id === undefined ? "/voucher" : "/voucherpoint"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">
                {id === undefined ? t(`menu.voucher`) : attr.product_name}
              </h3>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderList = () => {
    return list.map((item, i) => (
      <div key={i} className="box-item">
        <NavLink to={`/voucherpoint/${item.code}`}>
          <table width="100%" cellPadding={3} cellSpacing={0} border="0">
            <tbody>
              <tr>
                <td width="50%" rowSpan={"2"}>
                  <img src={item.filepic} className="item-img" />
                </td>
                <td valign="top">
                  <h3 className="item-title">{item.product_name}</h3>
                  <p className="item-detail">{item.detail}</p>
                </td>
              </tr>
              <tr>
                <td valign="bottom">
                  <div className="item-box">
                    <table width="100%" cellPadding={3}>
                      <tbody>
                        <tr>
                          <td className="item-price" align="right">
                            {Func.numberDisplay(item.price, 0)} {t(`shopping.point`)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </NavLink>
      </div>
    ));
  };

  const renderItem = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <img src={attr.filepic} className="img" />
        </Grid>
        <Grid item xs={12}>
          <div className="box-point">
            {Func.numberDisplay(attr.price, 0)} {t(`shopping.point`)}
          </div>
        </Grid>
      </Grid>
    );
  };

  const renderAddress = () => {
    return (
      <div className="box-item">
        <form>
          <GridContainer>
            <GridItem xs={12}>
              <TextField
                id="name"
                fullWidth
                InputProps={{
                  className: "text-address",
                  value: attr.name,
                  onChange: (e) => setAttr({ ...attr, name: e.target.value }),
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>{t(`profile.name`)} :</span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fas fa-user" />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                id="address"
                fullWidth
                InputProps={{
                  className: "text-address",
                  value: attr.address,
                  onChange: (e) =>
                    setAttr({ ...attr, address: e.target.value }),
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>{t(`profile.address`)} :</span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fas fa-map-marker-alt" />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                id="address2"
                fullWidth
                InputProps={{
                  className: "text-address",
                  value: attr.address2,
                  onChange: (e) =>
                    setAttr({ ...attr, address2: e.target.value }),
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>{t(`profile.address2`)} :</span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fas fa-map-marker-alt" />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                id="province"
                fullWidth
                InputProps={{
                  className: "text-address",
                  value: attr.province,
                  onChange: (e) =>
                    setAttr({ ...attr, province: e.target.value }),
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>{t(`profile.province`)} :</span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fas fa-map-marker-alt" />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                id="zipcode"
                fullWidth
                InputProps={{
                  className: "text-address",
                  value: attr.zipcode,
                  onChange: (e) =>
                    setAttr({ ...attr, zipcode: e.target.value }),
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>{t(`profile.zipcode`)} :</span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fas fa-map-marker-alt" />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                id="tel"
                fullWidth
                InputProps={{
                  className: "text-address",
                  value: attr.tel,
                  onChange: (e) => setAttr({ ...attr, tel: e.target.value }),
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>{t(`profile.tel`)} :</span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fas fa-phone-alt" />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </GridItem>
          </GridContainer>
        </form>
      </div>
    );
  };

  const renderBalance = () => {
    return (
      <div className="box-balance">
        <table width="100%" cellPadding={5} cellSpacing={0}>
          <tbody>
            <tr>
              <td className="icon">
                <i className="fas fa-gift fa-2x" />
              </td>
              <td className="balance">{Func.numberDisplay(member.point)} {t(`shopping.point`)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <BottomNavigation
        onChange={(e, link) => {
          if (link === "back") history.push(`/voucherpoint`);
          else if (link === "confirm") request(attr);
        }}
        showLabels
        className={"bottom"}
      >
        <BottomNavigationAction
          label={
            <div className="menu">
              <i className="fad fa-arrow-alt-circle-left color-danger" /> {t(`button.back`)}
            </div>
          }
          value="back"
        />
        <BottomNavigationAction
          label={
            <div className="menu">
              <i className="fad fa-check-circle color-success" />   {t(`shopping.changepoint`)}
            </div>
          }
          value="confirm"
        />
      </BottomNavigation>
    );
  };

  const renderLoad = () => {
    return (
      <>
        {renderBalance()}
        {renderItem()}
        {attr.request_address === "Y" ? renderAddress() : null}
        {renderFooter()}
      </>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="shopping">
          {renderAppBar()}
          {id === undefined ? renderList() : renderLoad()}
          {renderLoading()}
          {notify}
          {alert}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(VoucherPoint);
