import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import TradeTab from "./TradeTab";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import Close from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import SystemUpdateIcon from "@material-ui/icons/SystemUpdate";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import QueuePlayNextIcon from "@material-ui/icons/QueuePlayNext";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import copy from "copy-to-clipboard";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";

const useStyles = makeStyles(styles);

function Trade({ t }) {
  const mod = "trade";
  const prefix = "game";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const [notify, setNotify] = useState(null);
  const [attr, setAttr] = useState({});
  const [member, setMember] = useState({});
  const [loading, setLoading] = useState(false);
  const [cashbacks, setCashbacks] = useState([]);
  const [ic, setIc] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalOther, setModalOther] = useState(false);
  const [gameUser, setGameUser] = useState([]);
  const [gameOther, setGameOther] = useState([]);
  const [modalPass, setModalPass] = useState(false);
  const [errorPass, setErrorPass] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setGameUser(result.gameuser);
        setGameOther(result.gameother);
        setMember(result.member);
        setCashbacks(result.cashback);
        setIc(result.ic);

        showPopup(result.popup);
      } else {
        Func.exitApp();
      }
      setLoading(false);
    });
  };

  const showPopup = (item) => {
    if (item.show == "Y")
      setAlert(
        <SweetAlert
          info
          style={{ display: "block", marginTop: "-100px" }}
          title={item.title}
          onConfirm={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={"OK"}
        >
          {item.content}
        </SweetAlert>
      );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const moveWallet = () => {
    PostData(mod, "movewallet", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        init();
        showNotify(t(`${prefix}.alert-movesuccess`), "success");
      } else if (result.status === "ALERT") {
        showNotify(t(`${prefix}.${result.msg}`), "danger");
      } else {
        Func.exitApp();
      }
      setLoading(false);
    });
  };

  const openGame = () => {
    setModalOther(false);
    setLoading(true);
    PostData("game", "open", {
      token: app.token,
      lang: app.lang,
      game: attr.code,
    }).then((result) => {
      if (result.status === "OK") {
        loadGame();
        showNotify(t(`game.${result.msg}`), "success");
      } else if (result.status === "ALERT") {
        showNotify(t(`game.${result.msg}`), "danger");
      } else {
        Func.exitApp();
      }
      setLoading(false);
    });
  };

  const changePass = () => {
    if (!Func.verifyLength(newPass, 5)) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setModalPass(false);
      setLoading(true);

      PostData("game", "changepass", {
        token: app.token,
        lang: app.lang,
        game: attr.code,
        password: newPass,
      }).then((result) => {
        setLoading(false);

        if (result.status === "OK") {
          loadGame();
          showNotify(t(`game.${result.msg}`), "success");
        } else if (result.status === "ALERT") {
          showNotify(t(`game.${result.msg}`), "danger");
        } else {
          Func.exitApp();
        }
      });
    }
  };

  const loadGame = () => {
    setLoading(true);

    PostData("game", "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setGameUser(result.gameuser);
        setGameOther(result.gameother);
      } else {
        Func.exitApp();
      }
    });
  };

  const loginAuto = () => {
    setModal(false);
    setLoading(true);
    PostData("game", "loginauto", {
      token: app.token,
      lang: app.lang,
      game: attr.code,
    }).then((result) => {
      setLoading(false);
      if (result.status === "OK") {
        window.location.replace(result.url);
      } else if (result.status === "ALERT") {
        showNotify(t(`game.${result.msg}`), "danger");
      } else {
        Func.exitApp();
      }
    });
  };

  const toGame = () => {
    if (attr.lock_cashback == "Y") showNotify(t(`game.alert-turn`), "danger");
    else if (member.wallet == 0) showNotify(t(`game.alert-money`), "danger");
    else window.location.replace(`/togamecb/${attr.code}`);
  };

  const renderTab = () => {
    return (
      <div className="btn-cashback">
        <TradeTab
          headerColor="primary"
          tabs={[
            {
              tabName: <div>Beginner</div>,
              tabIcon: SportsEsportsIcon,
              tabContent: renderGame(),
            },
            {
              tabName: <div>Standard</div>,
              tabIcon: MonetizationOnIcon,
              tabContent: renderCashback(),
            },
            {
              tabName: <div>VIP</div>,
              tabIcon: LocalAtmIcon,
              tabContent: renderIc(),
            },
          ]}
        />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderCashback = () => {
    return (
      <List dense={true}>
        {cashbacks.map((item) => (
          <div key={item.code}>
            <ListItem>
              <ListItemText
                className="title"
                primary={item.member_name}
                secondary={
                  <i className="text-secondary">
                    {Func.dateDisplay(item.date_cashback)}
                  </i>
                }
              />
              <ListItemSecondaryAction className="title">
                {Func.numberDisplay(item.amount)}
              </ListItemSecondaryAction>
            </ListItem>
            <Divider
              style={{ background: "var(--divider)" }}
              variant="middle"
            />
          </div>
        ))}
      </List>
    );
  };

  const renderIc = () => {
    return (
      <List dense={true}>
        {ic.map((item) => (
          <div key={item.code}>
            <ListItem>
              <ListItemText
                className="title"
                primary={item.member_name}
                secondary={
                  <i className="text-secondary">
                    {Func.dateDisplay(item.date_cashback)}
                  </i>
                }
              />
              <ListItemSecondaryAction className="title">
                {Func.numberDisplay(item.amount)}
              </ListItemSecondaryAction>
            </ListItem>
            <Divider
              style={{ background: "var(--divider)" }}
              variant="middle"
            />
          </div>
        ))}
      </List>
    );
  };

  const renderBalance = () => {
    return (
      <Card className="card-bg">
        <CardBody className="card-body">
          <Grid container>
            <Grid item xs={6}>
              Wallet
            </Grid>
            <Grid item xs={6}>
              <h3 className="balance" style={{ textAlign: "right" }}>
                $ {Func.numberDisplay(member.wallet)}
              </h3>
            </Grid>
          </Grid>
        </CardBody>
        <CardFooter stats style={{ marginTop: "0" }}>
          <Grid item xs={8} className="moneyguide">
            กระเป๋าลงทุน
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            {member.move == "Y" ? (
              <Button
                justIcon
                round
                color="danger"
                className="givemoney"
                size="sm"
                onClick={moveWallet}
              >
                <i
                  className="fas fa-hand-holding-usd fa-2x"
                  style={{ top: "0" }}
                />
              </Button>
            ) : null}
          </Grid>
        </CardFooter>
      </Card>
    );
  };

  const renderGame = () => {
    return (
      <>
        <Grid container>
          {gameUser.map((item) => (
            <Grid item xs={6} key={item.code}>
              <button
                className="btn"
                onClick={() => {
                  setAttr(item);
                  setModal(true);
                }}
              >
                <table width="100%" cellPadding="3">
                  <tbody>
                    <tr>
                      <td width="30%">
                        <img src={item.filepic} className="img" />
                      </td>
                      <td className="balance">
                        <i className="fas fa-coins" />{" "}
                        {Func.moneyDisplay(item.balance)}
                      </td>
                      <td width="10%">
                        <i className="fas fa-chevron-circle-right" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </button>
            </Grid>
          ))}
        </Grid>
        <Grid container>
          {gameOther.map((item) => (
            <Grid item xs={6} key={item.code}>
              <button
                className="btn"
                onClick={() => {
                  setAttr(item);
                  setModalOther(true);
                }}
              >
                <table width="100%" cellPadding="3">
                  <tbody>
                    <tr>
                      <td width="30%">
                        <img src={item.filepic} className="img" />
                      </td>
                      <td className="balance">{item.name}</td>
                      <td width="10%">
                        <i className="fas fa-plus-circle" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </button>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const renderModalGame = () => {
    return (
      <Dialog
        className="game-modal"
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={modal}
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{attr.name}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
          style={{ paddingTop: "0" }}
        >
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <img src={attr.filepic} width="100%" />
            </Grid>
            <Grid item xs={8} className="balance">
              <i className="fas fa-coins" /> {attr.balance}
            </Grid>
            <Grid item xs={8} className="user-pass">
              {t(`game.user`)}: {attr.user_name}
            </Grid>
            <Grid item xs={4} className="box-copy">
              <Button
                size="sm"
                justIcon
                round
                className="btn-copy"
                color="warning"
                onClick={() => {
                  copy(attr.user_name);
                  showNotify(t(`game.alert-copyuser`), "success");
                }}
              >
                <FileCopyIcon />
              </Button>
            </Grid>
            {attr.showpass != "Y" ? null : (
              <Grid item xs={8} className="user-pass">
                {t(`game.pass`)}: {attr.user_pass}
              </Grid>
            )}
            {attr.showpass != "Y" ? null : (
              <Grid item xs={4} className="box-copy">
                <Button
                  size="sm"
                  justIcon
                  round
                  className="btn-copy"
                  color="warning"
                  onClick={() => {
                    copy(attr.user_pass);
                    showNotify(t(`game.alert-copypass`), "success");
                  }}
                >
                  <FileCopyIcon />
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className="hr"></div>
            </Grid>
            {attr.turn_pro == "Y" ? (
              <Grid item xs={12}>
                <h4>
                  {t(`game.turn`)} : {Func.numberDisplay(attr.turn_amount)}
                </h4>
              </Grid>
            ) : null}
            <Grid item xs={6} className="transfer-left">
              <Button
                block
                color="success"
                className="btn-transfer"
                onClick={() => toGame()}
              >
                {t(`game.transfer_in`) + " "}{" "}
                {attr.lock_cashback == "Y" ? <Close /> : <LastPageIcon />}
              </Button>
            </Grid>
            <Grid item xs={6} className="transfer-right">
              <Button
                block
                color="danger"
                className="btn-transfer"
                disabled={true}
              >
                {attr.lock_towallet == "Y" ? <Close /> : <FirstPageIcon />}{" "}
                {t(`game.transfer_out`)}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <div className="hr"></div>
            </Grid>
            {attr.login_auto != "Y" ? null : (
              <Grid item xs={12}>
                <Button block size="sm" className="btn" onClick={loginAuto}>
                  <LockOpenIcon /> {t(`game.loginauto`)}
                </Button>
              </Grid>
            )}
            {attr.showpass != "Y" ? null : (
              <Grid item xs={12}>
                <Button
                  block
                  size="sm"
                  className="btn"
                  onClick={() => {
                    setModal(false);
                    setModalPass(true);
                  }}
                >
                  <VpnKeyIcon /> {t(`game.changepass`)}
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className="hr"></div>
            </Grid>
            {attr.link_android === "" ? null : (
              <Grid item xs={4}>
                <Button
                  block
                  size="sm"
                  className="btn"
                  onClick={() => window.open(attr.link_android, "_blank")}
                >
                  <SystemUpdateIcon /> Andoid
                </Button>
              </Grid>
            )}
            {attr.link_ios === "" ? null : (
              <Grid item xs={4} className="download">
                <Button
                  block
                  size="sm"
                  className="btn"
                  onClick={() => window.open(attr.link_ios, "_blank")}
                >
                  <SystemUpdateIcon /> IOS
                </Button>
              </Grid>
            )}
            {attr.link_web === "" ? null : (
              <Grid item xs={4}>
                <Button
                  block
                  size="sm"
                  className="btn"
                  onClick={() => window.open(attr.link_web, "_blank")}
                >
                  <SystemUpdateIcon /> web
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        ></DialogActions>
      </Dialog>
    );
  };

  const renderModalOther = () => {
    return (
      <Dialog
        className="game-modal"
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={modalOther}
        keepMounted
        onClose={() => setModalOther(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalOther(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{attr.name}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
          style={{ paddingTop: "0" }}
        >
          <Grid container alignItems="center">
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <img src={attr.filepic} width="100%" />
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={12}>
              <div className="hr"></div>
            </Grid>
            <Grid item xs={12}>
              <Button
                block
                color="danger"
                className="btn-open"
                onClick={openGame}
              >
                <QueuePlayNextIcon /> {t(`game.opengame`)}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <div className="hr"></div>
            </Grid>
            {attr.link_android === "" ? null : (
              <Grid item xs={4}>
                <Button
                  block
                  size="sm"
                  className="btn"
                  onClick={() => window.open(attr.link_android, "_blank")}
                >
                  <SystemUpdateIcon /> Andoid
                </Button>
              </Grid>
            )}
            {attr.link_ios === "" ? null : (
              <Grid item xs={4} className="download">
                <Button
                  block
                  size="sm"
                  className="btn"
                  onClick={() => window.open(attr.link_ios, "_blank")}
                >
                  <SystemUpdateIcon /> IOS
                </Button>
              </Grid>
            )}
            {attr.link_web === "" ? null : (
              <Grid item xs={4}>
                <Button
                  block
                  size="sm"
                  className="btn"
                  onClick={() => window.open(attr.link_web, "_blank")}
                >
                  <SystemUpdateIcon /> web
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        ></DialogActions>
      </Dialog>
    );
  };

  const renderModalPass = () => {
    return (
      <Dialog
        className="game-modal"
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={modalPass}
        keepMounted
        onClose={() => setModalPass(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalPass(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{attr.name}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
          style={{ paddingTop: "0" }}
        >
          <Grid container alignItems="center" className="profile">
            <Grid item xs={4}>
              <img src={attr.filepic} width="100%" />
            </Grid>
            <Grid item xs={8} className="balance">
              <i className="fas fa-coins" /> {attr.balance}
            </Grid>
            <Grid item xs={12} className="user-pass">
              {t(`game.user`)}: {attr.user_name}
            </Grid>
            <Grid item xs={12}>
              <p></p>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="newpassword"
                fullWidth
                helperText={
                  errorPass ? (
                    <>
                      <i className="fas fa-exclamation-triangle" />{" "}
                      {t(`profile.pleaseinsert-password_new`)}
                    </>
                  ) : null
                }
                InputProps={{
                  type: "text",
                  className: "text-pass",
                  value: newPass,
                  onChange: (e) => {
                    setNewPass(e.target.value);
                    setErrorPass(!Func.verifyLength(e.target.value, 5));
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <span style={{ color: "white" }}>
                        {t("profile.newpassword")} :
                      </span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <VpnKeyIcon />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                  error: errorPass,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <p></p>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="warning"
                block
                className="btn-submit"
                onClick={changePass}
              >
                <i className="fas fa-edit"></i>
                {t("profile.submit")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        ></DialogActions>
      </Dialog>
    );
  };

  return (
    <div className="cashback">
      <AuthNavbar mod={mod} />
      <div className={classes.container}>
        {renderBalance()}
        {renderTab()}
        {renderModalGame()}
        {renderModalOther()}
        {renderModalPass()}
        {renderLoading()}
        {notify}
        {alert}
      </div>
    </div>
  );
}

export default withNamespaces()(Trade);
