import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "components/Badge/Badge.js";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import Close from "@material-ui/icons/Close";
import CachedIcon from "@material-ui/icons/Cached";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Accordion from "components/Accordion/Accordion.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CardFooter from "components/Card/CardFooter.js";
import Grid from "@material-ui/core/Grid";
import copy from "copy-to-clipboard";

import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";

import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";

const useStyles = makeStyles(styles);

function Wallet({ t }) {
    const mod = "deposit";
    const classes = useStyles();
    const app = useSelector((state) => state.appState);
    const [notify, setNotify] = useState(null);
    const [member, setMember] = useState({});
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [bank, setBank] = useState([]);
    const [history, setHistory] = useState([]);
    const [amount, setAmount] = useState("");
    const [accountCode, setAccountCode] = useState("");
    const [point, setPoint] = useState("00");

    useEffect(() => {
        i18n.changeLanguage(app.lang);

        init();
    }, []);

    const init = () => {
        setLoading(true);

        setMember({});
        setBank([]);
        setHistory([]);

        PostData(mod, "init", {
            token: app.token,
            lang: app.lang,
        }).then((result) => {
            if (result.status === "OK") {
                setMember(result.member);
                setHistory(result.history);

                result.bank.map((item, i) => {
                    setBank((bank) => [
                        ...bank,
                        {
                            title: item.bank_name,
                            color: item.bank_color,
                            content: renderBank(item),
                        },
                    ]);
                });
            } else {
                // Func.exitApp();
            }
            setLoading(false);
        });
    };

    const showNotify = (msg, color) => {
        setNotify(
            <Snackbar
                place="tc"
                color={color}
                icon={AlertIcon}
                message={msg}
                open={true}
                closeNotification={() => setNotify(null)}
                close
            />
        );

        setTimeout(function () {
            setNotify(null);
        }, 3000);
    };

    const requestTopup = () => {
        setModal(false);
        setLoading(true);
        PostData(mod, "request", {
            token: app.token,
            lang: app.lang,
            amount: amount,
            account: accountCode,
        }).then((result) => {
            if (result.status === "OK") {
                init();
                showNotify(t(`${mod}.${result.msg}`), "success");
            } else if (result.status === "ALERT") {
                showNotify(t(`${mod}.${result.msg}`), "danger");
            } else {
                Func.exitApp();
            }
            setLoading(false);
        });
    };

    const renderBalance = () => {
        const cssIcon = { color: "#ffffff" };

        return (
            <Card className="card-bg">
                <CardBody className="card-body">
                    <Grid container>
                        <Grid item xs={6}>
                            {t(`dashboard.balance_wallet`)}
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <CachedIcon
                                style={cssIcon}
                                onClick={() => {
                                    init();
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardBody>
                <CardFooter stats style={{ marginTop: "0" }}>
                    <h3 className="balance">$ {Func.numberDisplay(member.balance)}</h3>
                </CardFooter>
            </Card>
        );
    };

    const renderMyBank = () => {
        return (
            <GridContainer justify="center">
                <GridItem xs={12}>
                    <Card>
                        <CardBody
                            className="myaccount"
                            style={{ backgroundColor: member.bank_color }}
                        >
                            <table>
                                <tbody>
                                    <tr>
                                        <td width="90">
                                            <img
                                                src={member.bank_pic}
                                                alt={member.bank_name}
                                                width="70"
                                            />
                                        </td>
                                        <td>
                                            <div>{t(`${mod}.mybank`)}</div>
                                            <div className="text-account">{member.acc_no}</div>
                                            <div>
                                                {member.firstname} {member.lastname}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    const renderDeposit = () => {
        return (
            <div className="acc-bank">
                <Accordion active={0} collapses={bank} />
            </div>
        );
    };

    const renderBank = (item) => {
        return (
            <GridContainer className="bank">
                <GridItem xs={12}>
                    <table>
                        <tbody>
                            <tr>
                                <td width="90">
                                    <img src={item.bank_pic} alt={item.bank_name} width="70" />
                                </td>
                                <td>
                                    <div
                                        className="text-account"
                                        onClick={() => {
                                            copy(item.acc_no);
                                            showNotify(t(`${mod}.alert-copysuccess`), "success");
                                        }}
                                    >
                                        {item.acc_no} <i className="fas fa-clone warning" />
                                    </div>
                                    <div>{item.acc_name}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </GridItem>
                <GridItem xs={12}>
                    <Button
                        onClick={() => {
                            setAmount("");
                            setPoint(item.point);
                            setAccountCode(item.code);
                            setModal(true);
                        }}
                        color="warning"
                        size="sm"
                        block
                        className="btn-request"
                    >
                        <i className="fas fa-bullhorn" />
                        {t(`${mod}.request`)}
                    </Button>
                </GridItem>
                <GridItem xs={12}>
                    {item.point == "00" ? null : (
                        <p className={classes.center}>
                            กรุณาโอนเงินด้วยทศนิยม <span>{item.point}</span> เช่น 100.
                            {item.point}
                        </p>
                    )}
                </GridItem>
            </GridContainer>
        );
    };

    const renderHistory = () => {
        return (
            <Card>
                <CardBody className="history">
                    <h4 className="history-title">
                        <i className="fas fa-history" /> {t(`${mod}.history`)}
                    </h4>
                    <List dense={true}>
                        {history.map((item) => (
                            <div key={item.code}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <img
                                                src={item.bank_pic}
                                                alt={item.bank_name}
                                                width="30"
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<>{Func.numberDisplay(item.amount)} $</>}
                                        secondary={
                                            <i className="text-secondary">
                                                {Func.dateTimeDisplay(item.date_create)}
                                            </i>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            className="status"
                                        >
                                            <Badge color={item.status_color}>
                                                <i className={item.status_icon} /> {item.status_name}
                                            </Badge>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider style={{ background: "#753239" }} variant="middle" />
                            </div>
                        ))}
                    </List>
                </CardBody>
            </Card>
        );
    };

    const renderModal = () => {
        return (
            <Dialog
                className="deposit-modal"
                classes={{
                    root: classes.center,
                    paper: classes.modal,
                }}
                open={modal}
                keepMounted
                onClose={() => setModal(false)}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setModal(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>
                        {t(`${mod}.balance`)} : {Func.numberDisplay(member.balance)}
                    </h4>
                </DialogTitle>
                <DialogContent
                    id="modal-slide-description"
                    className={classes.modalBody}
                >
                    <form>
                        <GridContainer>
                            <GridItem xs={12}>
                                <TextField
                                    id="amount"
                                    fullWidth
                                    InputProps={{
                                        type: "number",
                                        className: "text-topup",
                                        value: amount,
                                        onChange: (e) => {
                                            setAmount(e.target.value);
                                        },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <span style={{ color: "white" }}>
                                                    {t(`${mod}.amount`)} :
                                                </span>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocalAtmIcon />
                                            </InputAdornment>
                                        ),
                                        autoComplete: "off",
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Button
                                    className="btn-price"
                                    size="sm"
                                    color="warning"
                                    onClick={() => setAmount(`100.${point}`)}
                                >
                                    100.{point}
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    className="btn-price"
                                    size="sm"
                                    color="warning"
                                    onClick={() => setAmount(`500.${point}`)}
                                >
                                    500.{point}
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    className="btn-price"
                                    size="sm"
                                    color="warning"
                                    onClick={() => setAmount(`1000.${point}`)}
                                >
                                    1,000.{point}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.modalFooterCenter}
                >
                    <Button
                        color="success"
                        className="btn-submit"
                        onClick={requestTopup}
                        block
                    >
                        <i className="fas fa-paper-plane"></i> {t(`${mod}.submit`)}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderLoading = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    };

    return (
        <div className="deposit">
            <AuthNavbar mod={mod} />
            <div className={classes.container}>
                {renderBalance()}
                {renderMyBank()}
                {renderDeposit()}
                {renderHistory()}
                {renderModal()}
                {renderLoading()}
                {notify}
            </div>
        </div>
    );
}

export default withNamespaces()(Wallet);
