import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { usePageName } from "react-page-name";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";

// core components
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";
import Button from "components/CustomButtons/Button.js";
import "views/Style/PinStyle.css";

const useStyles = makeStyles(styles);

const initAttr = {
  key1: "",
  key2: "",
  key3: "",
  key4: "",
};

function PinRegis({ t }) {
  const mod = "profile";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const history = useHistory();
  const [attr, setAttr] = useState(initAttr);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(1);
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");

  useEffect(() => {
    i18n.changeLanguage(app.lang);
  }, []);

  const regisPin = () => {
    setLoading(true);
    PostData(mod, "regispin", {
      token: app.token,
      lang: app.lang,
      pin: password,
    }).then((result) => {
      if (result.status === "OK") {
        showNotify(t(result.msg), "primary");

        setTimeout(function () {
          window.location.replace(`/setting`);
        }, 500);
      } else if (result.status === "ALERT") {
        showNotify(t(result.msg), "danger");
      } else {
        Func.exitApp();
      }
      setLoading(false);
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="bc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={"/setting"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">Register pin</h3>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderHeader = () => {
    return (
      <div className="step">
        <Grid container>
          {error ? (
            <Grid item xs={12}>
              <h3 className="steperror">Your entries did not match</h3>
              <p className="steperror">please try again</p>
            </Grid>
          ) : status === 1 ? (
            <Grid item xs={12}>
              <h3 className="step1">1 - Enter a PIN Code</h3>
              <p className="step1">to keep your information secure</p>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <h3 className="step2">2 - Confirm your PIN Code</h3>
              <p className="step2">...</p>
            </Grid>
          )}
        </Grid>
      </div>
    );
  };

  const renderItem = () => {
    const numpad = (num) => {
      if (num === "-") {
        if (status === 1 && password.length > 0) {
          let pass = password;

          if (pass.length === 3) {
            setAttr({ key1: "x", key2: "x", key3: "", key4: "" });
          } else if (pass.length === 2) {
            setAttr({ key1: "x", key2: "", key3: "", key4: "" });
          } else {
            setAttr({ key1: "", key2: "", key3: "", key4: "" });
          }

          pass = pass.substring(0, pass.length - 1);
          setPassword(pass);
        } else if (status === 2 && passwordAgain.length > 0) {
          let pass = passwordAgain;

          if (pass.length === 3) {
            setAttr({ key1: "x", key2: "x", key3: "", key4: "" });
          } else if (pass.length === 2) {
            setAttr({ key1: "x", key2: "", key3: "", key4: "" });
          } else {
            setAttr({ key1: "", key2: "", key3: "", key4: "" });
          }

          pass = pass.substring(0, pass.length - 1);
          setPasswordAgain(pass);
        }
      } else {
        if (status === 1) {
          let pass = password + "" + num;
          setPassword(pass);

          if (pass.length === 4) {
            setAttr({ key1: "x", key2: "x", key3: "x", key4: "x" });
            setTimeout(function () {
              setAttr({ key1: "", key2: "", key3: "", key4: "" });
              setStatus(2);
            }, 500);
          } else if (pass.length === 3) {
            setAttr({ key1: "x", key2: "x", key3: "x", key4: "" });
          } else if (pass.length === 2) {
            setAttr({ key1: "x", key2: "x", key3: "", key4: "" });
          } else if (pass.length === 1) {
            setAttr({ key1: "x", key2: "", key3: "", key4: "" });
          } else {
            setAttr({ key1: "", key2: "", key3: "", key4: "" });
          }
        } else if (status === 2) {
          let pass = passwordAgain + "" + num;
          setPasswordAgain(pass);

          if (pass.length === 4) {
            setAttr({ key1: "x", key2: "x", key3: "x", key4: "x" });
            setTimeout(function () {
              if (pass !== password) {
                setAttr({ key1: "", key2: "", key3: "", key4: "" });
                setPasswordAgain("");
                setError(true);
              } else {
                regisPin();
              }
            }, 500);
          } else if (pass.length === 3) {
            setAttr({ key1: "x", key2: "x", key3: "x", key4: "" });
          } else if (pass.length === 2) {
            setAttr({ key1: "x", key2: "x", key3: "", key4: "" });
          } else if (pass.length === 1) {
            setAttr({ key1: "x", key2: "", key3: "", key4: "" });
            setError(false);
          } else {
            setAttr({ key1: "", key2: "", key3: "", key4: "" });
          }
        }
      }
    };

    const btnNumpad = (num) => {
      return (
        <Button
          size={"lg"}
          color="primary"
          className="btn"
          onClick={() => numpad(num)}
        >
          {num}
        </Button>
      );
    };

    return (
      <div>
        <div className="box-input">
          <table width="100%">
            <tbody>
              <tr>
                <td width="20%"></td>
                <td width="15%">
                  <input
                    type="password"
                    value={attr.key1}
                    className="input"
                    placeholder="•"
                    readOnly
                  />
                </td>
                <td width="15%">
                  <input
                    type="password"
                    value={attr.key2}
                    placeholder="•"
                    className="input"
                    readOnly
                  />
                </td>
                <td width="15%">
                  <input
                    type="password"
                    value={attr.key3}
                    placeholder="•"
                    className="input"
                    readOnly
                  />
                </td>
                <td width="15%">
                  <input
                    type="password"
                    value={attr.key4}
                    placeholder="•"
                    className="input"
                    readOnly
                  />
                </td>
                <td width="20%"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="box-btn">
          <table width="100%">
            <tbody>
              <tr>
                <td width="33%">{btnNumpad(1)}</td>
                <td width="34%">{btnNumpad(2)}</td>
                <td width="33%">{btnNumpad(3)}</td>
              </tr>
              <tr>
                <td>{btnNumpad(4)}</td>
                <td>{btnNumpad(5)}</td>
                <td>{btnNumpad(6)}</td>
              </tr>
              <tr>
                <td>{btnNumpad(7)}</td>
                <td>{btnNumpad(8)}</td>
                <td>{btnNumpad(9)}</td>
              </tr>
              <tr>
                <td></td>
                <td>{btnNumpad(0)}</td>
                <td>
                  <Button
                    simple
                    className="btn-back"
                    onClick={() => numpad("-")}
                  >
                    <i className="fas fa-backspace fa-3x btn-icon" />
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="pin">
          {renderAppBar()}
          {renderHeader()}
          {renderItem()}
          {renderLoading()}
          {notify}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(PinRegis);
