import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { usePageName } from "react-page-name";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";

// core components
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import { setLanguage } from "redux/actions/AppStateAction";
import styles from "assets/jss/main-style.js";
import "views/Style/SettingStyle.css";

import enFlag from "assets/img/flags/GB.png";
import thFlag from "assets/img/flags/TH.png";

const useStyles = makeStyles(styles);

function ChangeLang({ t }) {
  const mod = "profile";
  const classes = useStyles();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.appState);
  const history = useHistory();
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
  }, [id]);

  const editLanguage = (language) => {
    setLoading(true);
    PostData(mod, "changelanguage", {
      token: app.token,
      lang: app.lang,
      language: language,
    }).then((result) => {
      if (result.status === "OK") {
        changeLanguage(language);
        setTimeout(function () {
          window.location.replace(`/setting`);
        }, 100);
      }
      setLoading(false);
    });
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={`/setting`}>
                <IconButton>
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">{t(`profile.changelanguage`)}</h3>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderList = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <List dense={true}>
            <ListItem button onClick={() => editLanguage("th")}>
              <ListItemAvatar>
                <Avatar>
                  <img src={thFlag} width="50" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<span className="text-primary">TH</span>}
                secondary={
                  <span className="text-secondary">
                    <i>{t(`profile.th`)}</i>
                  </span>
                }
              />
              <ListItemSecondaryAction>
                <i className="fas fa-angle-right" />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider className="divider" variant="middle" />
            <ListItem button onClick={() => editLanguage("en")}>
              <ListItemAvatar>
                <Avatar>
                  <img src={enFlag} width="50" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<span className="text-primary">EN</span>}
                secondary={
                  <span className="text-secondary">
                    <i>{t(`profile.en`)}</i>
                  </span>
                }
              />
              <ListItemSecondaryAction>
                <i className="fas fa-angle-right" />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="setting">
          {renderAppBar()}
          {renderList()}
          {renderLoading()}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(ChangeLang);
