import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { usePageName } from "react-page-name";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";

// core components
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import "views/Style/SettingStyle.css";

const useStyles = makeStyles(styles);

function RobotSymbol({ t }) {
    const mod = "robot";
    const classes = useStyles();
    const app = useSelector((state) => state.appState);
    const history = useHistory();
    const { id } = useParams();
    const [attr, setAttr] = useState({});
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        i18n.changeLanguage(app.lang);

        init();
    }, [id]);

    const init = () => {
        setLoading(true);
        PostData(mod, "initsymbol", {
            token: app.token,
            lang: app.lang,
            id: id
        }).then((result) => {
            if (result.status === "OK") {
                setList(result.list);
            }
            setLoading(false);
        });
    };

    const editSymbol = (symbol) => {
        setLoading(true);
        PostData(mod, "changesymbol", {
            token: app.token,
            lang: app.lang,
            id: id,
            symbol: symbol
        }).then((result) => {
            if (result.status === "OK") {
                setTimeout(function () {
                    window.location.replace(`/robot/${id}`);
                }, 100);
            }
            setLoading(false);
        });
    };

    const renderLoading = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    };

    const renderAppBar = () => {
        return (
            <AppBar position="static" className="header-bg">
                <Toolbar className="toolbar">
                    <Grid container>
                        <Grid item xs={2}>
                            <NavLink to={`/robot/${id}`}>
                                <IconButton>
                                    <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                                </IconButton>
                            </NavLink>
                        </Grid>
                        <Grid item xs={8}>
                            <h3 className="header-title">Change Coin/Base</h3>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    };

    const renderList = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <List dense={true}>
                        {list.map((item, i) => (
                            <div key={i} className="read">
                                <ListItem button onClick={() => editSymbol(item.code)}>
                                    <ListItemAvatar>
                                        <i className="fas fa-coins color-primary" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.name}
                                    />
                                    <ListItemSecondaryAction>
                                        <i className="fas fa-angle-right" />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider variant="middle" />
                            </div>
                        ))}
                    </List>
                </Grid>
            </Grid>
        );
    };

    return (
        <div className="block">
            <div className={classes.container}>
                <div className="affiliate">
                    {renderAppBar()}
                    {renderList()}
                    {renderLoading()}
                </div>
            </div>
        </div>
    );
}

export default withNamespaces()(RobotSymbol);
