import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";

// core components
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";
import "views/Style/ShoppingStyle.css";

const useStyles = makeStyles(styles);

function Cart({ t }) {
  const mod = "cart";
  const classes = useStyles();
  const history = useHistory();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        if (result.list.length > 0) setList(result.list);
        else window.location.replace("/home");
      }
      setLoading(false);
    });
  };

  const remove = (item) => {
    setLoading(true);
    setList([]);
    PostData(mod, "remove", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      if (result.status === "OK") {
        init();
        showNotify(t(result.msg), "primary");
      } else if (result.status === "ALERT") {
        showNotify(t(result.msg), "danger");
      } else {
        Func.exitApp();
      }
      setLoading(false);
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="bc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={"/home"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">{t(`menu.cart`)}</h3>
            </Grid>
            <Grid item xs={2} className="cart"></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderList = () => {
    return list.map((item) => (
      <div key={item.code} className="box-item">
        <table width="100%" cellPadding={3} cellSpacing={0} border="0">
          <tbody>
            <tr>
              <td width="50%" rowSpan={5}>
                <img src={item.filepic} className="item-img" />
              </td>
              <td colSpan={2} align="center">
                <h5 className="text-cart-head">{item.product_name}</h5>
              </td>
            </tr>
            <tr>
              <td>
                {item.renew === "Y"
                  ? t(`robot.robotrenew`)
                  : t(`shopping.package`)}{" "}
                :
              </td>
              <td align="right">{item.price_name}</td>
            </tr>
            <tr>
              <td>{t(`shopping.price`)} :</td>
              <td align="right">{Func.numberDisplay(item.price)} ฿</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td colSpan={2} align="center">
                <Button
                  color="danger"
                  size="sm"
                  className="item-cart"
                  onClick={() => remove(item)}
                  round
                >
                  <i className="fas fa-trash-alt" /> {t(`button.remove`)}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ));
  };

  const renderFooter = () => {
    return (
      <BottomNavigation
        onChange={(e, link) => {
          history.push(`/${link}`);
        }}
        showLabels
        className={"bottom"}
      >
        <BottomNavigationAction
          label={
            <div className="menu">
              <i className="fad fa-arrow-alt-circle-left color-danger" />{" "}
              {t(`shopping.selectmore`)}
            </div>
          }
          value="product"
        />
        <BottomNavigationAction
          label={
            <div className="menu">
              {t(`shopping.checkout`)}{" "}
              <i className="fad fa-arrow-circle-right color-success" />
            </div>
          }
          value="checkout"
        />
      </BottomNavigation>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="shopping">
          {renderAppBar()}
          {renderList()}
          {renderFooter()}
          {renderLoading()}
          {notify}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Cart);
