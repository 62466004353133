import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// core components
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";

function ProductLow({ t }) {
  const app = useSelector((state) => state.appState);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    window.location.replace("/product/5");
  }, []);

  return <div className="block"></div>;
}

export default withNamespaces()(ProductLow);
