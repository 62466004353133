import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";

// core components
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import * as Func from "services/Function";
import "views/Style/ShoppingStyle.css";

const useStyles = makeStyles(styles);

function Seminar({ t }) {
  const mod = "seminar";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setList(result.list);
      }
      setLoading(false);
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="bc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const request = (item) => {
    setLoading(true);
    PostData(mod, "request", {
      token: app.token,
      lang: app.lang,
      id: item.code,
    }).then((result) => {
      if (result.status === "OK") {
        init();
        showNotify(t(result.msg), "primary");
      } else if (result.status === "ALERT") {
        showNotify(t(result.msg), "danger");
      } else {
        Func.exitApp();
      }
      setLoading(false);
    });
  };

  const cancel = (item) => {
    setLoading(true);
    PostData(mod, "cancel", {
      token: app.token,
      lang: app.lang,
      id: item.code,
    }).then((result) => {
      if (result.status === "OK") {
        init();
        showNotify(t(result.msg), "primary");
      } else if (result.status === "ALERT") {
        showNotify(t(result.msg), "danger");
      } else {
        Func.exitApp();
      }
      setLoading(false);
    });
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={"/home"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={10}>
              <h3 className="header-title">{t(`menu.seminar`)}</h3>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderList = () => {
    return list.map((item, i) => (
      <div key={i} className="box-item">
        <table width="100%" cellPadding={3} cellSpacing={0} border="0">
          <tbody>
            <tr>
              <td colSpan={2}>
                {item.register === "Y" ? (
                  <p className="item-status">
                    <i className="fal fa-check-circle" />{" "}
                    {t(`profile.registered`)}
                  </p>
                ) : null}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <h3 className="item-title">
                  <i className="fal fa-calendar-day" /> {item.name}
                </h3>
              </td>
            </tr>
            <tr>
              <td width="50%" valign="top">
                <img src={item.filepic} className="item-img" />
              </td>
              <td valign="top">
                <h5 className="item-date">
                  {t(`profile.date`)} {item.date_record}
                </h5>
                <p className="item-detail">{item.detail}</p>

                <div className="item-box">
                  <table width="100%" cellPadding={3}>
                    <tbody>
                      <tr>
                        <td className="item-price">{t(`profile.start`)} :</td>
                        <td className="item-price" align="right">
                          {item.time_start}
                        </td>
                      </tr>
                      <tr>
                        <td className="item-price">{t(`profile.stop`)} :</td>
                        <td className="item-price" align="right">
                          {item.time_stop}
                        </td>
                      </tr>
                      <tr>
                        <td align="center" colSpan={2}></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="item-btn">
                  {item.register === "Y" ? (
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => cancel(item)}
                      round
                    >
                      <i className="fal fa-times-circle" />
                      {t(`button.cancel`)}
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => request(item)}
                      round
                    >
                      <i className="fal fa-plus-circle" />
                      {t(`authen.register`)}
                    </Button>
                  )}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ));
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="shopping">
          {renderAppBar()}
          {renderList()}
          {renderLoading()}
          {notify}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Seminar);
