import { combineReducers } from "redux";

import AppStateReducer from "./AppStateReducer";
import UserReducer from "./UserReducer";
import CartReducer from "./CartReducer";
import NoticeReducer from "./NoticeReducer";

const rootReducer = combineReducers({
  appState: AppStateReducer,
  user: UserReducer,
  cart: CartReducer,
  notice: NoticeReducer,
});

export default rootReducer;
