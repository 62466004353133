import { SET_NOTICE, CLEAR_NOTICE } from "../types";

const initialState = {
  count: 0,
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTICE:
      return action.payload;
    case CLEAR_NOTICE:
      return action.payload;
    default:
      return state;
  }
};
