import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// core components
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import MaskedInput from "react-text-mask";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import ReCAPTCHA from "react-google-recaptcha";

import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { usePageName } from "react-page-name";
import { PostSecure } from "services/Request";
import { setUser } from "redux/actions/UserAction";
import { setToken } from "redux/actions/AppStateAction";
import * as Func from "services/Function";
import * as Defi from "services/Define";

import styles from "assets/jss/main-style.js";

const useStyles = makeStyles(styles);

const CAPTCHA_KEY = "6Ld58cMaAAAAAML3S9jLZFIDg7fL1cjq3vDGUHXN";

const initAttr = {
  acc_no: "",
  firstname: "",
  lastname: "",
  tel: "",
  lineid: "",
  pass: "",
  pass_check: "",
  ref_code: "",
  bank: "",
  captcha: "",
  upline: "",
  upline_name: "",
};

const initError = {
  firstname: true,
  lastname: true,
  pass: true,
  pass_check: true,
};

function Register({ t }) {
  const mod = "authen";
  const dispatch = useDispatch();
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [notify, setNotify] = useState(null);
  const [alert, setAlert] = useState(null);
  const [attr, setAttr] = useState(initAttr);
  const [error, setError] = useState(initError);
  const [bank, setBank] = useState([]);
  const [showPass, setShowPass] = useState(false);

  usePageName(i18n.t(`app.name`) + " :: " + i18n.t(`authen.register`));

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    PostSecure(mod, "initregister", {
      token: app.token,
      lang: app.lang,
      upline: id,
    }).then((result) => {
      if (result.status === "OK") {
        setBank(result.bank);
        setAttr({
          ...attr,
          upline: result.upline.code,
          upline_name: result.upline.name,
        });
      } else {
        Func.exitApp();
      }
    });
  };

  const checkError = () => {
    let result = true;

    if (!Func.verifyLength(attr.firstname, 1)) result = false;
    if (!Func.verifyLength(attr.lastname, 1)) result = false;
    if (!Func.verifyLength(attr.pass, 4)) result = false;
    if (!Func.verifyLength(attr.pass_check, 4)) result = false;

    return result;
  };

  const register = () => {
    if (!checkError()) {
      showNotify(t(`alert.please`), "danger");
    } else {
      PostSecure(mod, "register", {
        token: app.token,
        lang: app.lang,
        data: attr,
      }).then((result) => {
        if (result.status === "OK") {
          dispatch(setUser(result.data));
          dispatch(setToken(result.token));

          window.location.replace(result.url);
        } else {
          showNotify(t(`${mod}.${result.msg}`), "danger");
        }
      });
    }
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderForm = () => {
    return (
      <GridContainer className="box">
        <GridItem xs={12}>
          <TextField
            fullWidth
            helperText={
              error.firstname ? (
                <>
                  <i className="fas fa-exclamation-triangle" />{" "}
                  {t(`${mod}.pleaseinsert-firstname`)}
                </>
              ) : null
            }
            InputProps={{
              type: "text",
              className: "text",
              onChange: (e) => {
                setAttr({ ...attr, firstname: e.target.value });
                setError({
                  ...error,
                  firstname: !Func.verifyLength(e.target.value, 1),
                });
              },
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-user" />
                  <span className="text-title">{t(`${mod}.firstname`)} : </span>
                </InputAdornment>
              ),
              autoComplete: "off",
              error: error.firstname,
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            fullWidth
            helperText={
              error.lastname ? (
                <>
                  <i className="fas fa-exclamation-triangle" />{" "}
                  {t(`${mod}.pleaseinsert-lastname`)}
                </>
              ) : null
            }
            InputProps={{
              type: "text",
              className: "text",
              onChange: (e) => {
                setAttr({ ...attr, lastname: e.target.value });
                setError({
                  ...error,
                  lastname: !Func.verifyLength(e.target.value, 1),
                });
              },
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-user" />
                  <span className="text-title">{t(`${mod}.lastname`)} : </span>
                </InputAdornment>
              ),
              autoComplete: "off",
              error: error.lastname,
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <div className="text">
            <i className="fas fa-mobile-alt" />
            <span className="text-title">{t(`${mod}.tel`)} : </span>
            <MaskedInput
              placeholder={t(`${mod}.tel`)}
              value={attr.tel}
              className="text-mask"
              onChange={(e) => {
                setAttr({ ...attr, tel: e.target.value });
              }}
              onFocus={(e) => e.target.select()}
              mask={[
                "(",
                /[0]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              placeholderChar={"\u2000"}
              showMask
            />
          </div>
        </GridItem>
        <GridItem xs={12}>
          <TextField
            fullWidth
            InputProps={{
              type: "text",
              className: "text",
              onChange: (e) => {
                setAttr({ ...attr, lineid: e.target.value });
              },
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fab fa-line" />
                  <span className="text-title">{t(`${mod}.lineid`)} : </span>
                </InputAdornment>
              ),
              autoComplete: "off",
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            fullWidth
            select
            value={attr.bank}
            onChange={(e) => {
              setAttr({ ...attr, bank: e.target.value });
            }}
            InputProps={{
              className: "text",
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-university" />
                  <span className="text-title">{t(`${mod}.bank`)} : </span>
                </InputAdornment>
              ),
            }}
          >
            {bank.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </GridItem>
        <GridItem xs={12}>
          <div className="text">
            <i className="far fa-credit-card" />{" "}
            <span className="text-title">{t(`${mod}.acc_no`)} : </span>
            <MaskedInput
              placeholder={t(`${mod}.acc_no`)}
              value={attr.acc_no}
              className="text-mask"
              onChange={(e) => {
                setAttr({ ...attr, acc_no: e.target.value });
              }}
              onFocus={(e) => e.target.select()}
              mask={[
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              placeholderChar={"\u2000"}
              showMask
            />
          </div>
        </GridItem>
        <GridItem xs={12}>
          <TextField
            fullWidth
            helperText={
              error.pass ? (
                <>
                  <i className="fas fa-exclamation-triangle" />{" "}
                  {t(`${mod}.pleaseinsert-password`)}
                </>
              ) : null
            }
            InputProps={{
              type: showPass ? "text" : "password",
              className: "text",
              onChange: (e) => {
                setAttr({ ...attr, pass: e.target.value });
                setError({
                  ...error,
                  pass: !Func.verifyLength(e.target.value, 4),
                });
              },
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-key" />
                  <span className="text-title">{t(`${mod}.password`)} : </span>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPass(!showPass)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showPass ? (
                      <Visibility className="view" />
                    ) : (
                      <VisibilityOff className="view" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              autoComplete: "off",
              error: error.pass,
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            fullWidth
            helperText={
              error.pass ? (
                <>
                  <i className="fas fa-exclamation-triangle" />{" "}
                  {t(`${mod}.pleaseinsert-password`)}
                </>
              ) : null
            }
            InputProps={{
              type: showPass ? "text" : "password",
              className: "text",
              onChange: (e) => {
                setAttr({ ...attr, pass_check: e.target.value });
                setError({
                  ...error,
                  pass_check: !Func.verifyLength(e.target.value, 4),
                });
              },
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-key" />
                  <span className="text-title">
                    {t(`${mod}.password_again`)}
                  </span>{" "}
                  :
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPass(!showPass)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showPass ? (
                      <Visibility className="view" />
                    ) : (
                      <VisibilityOff className="view" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              autoComplete: "off",
              error: error.pass_check,
            }}
          />
        </GridItem>
        {attr.upline === undefined ? null : (
          <GridItem xs={12}>
            <TextField
              fullWidth
              InputProps={{
                type: "text",
                className: "text",
                value: attr.upline_name,
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <i className="fas fa-user-plus" />
                    <span className="text-title">{t(`${mod}.upline`)} : </span>
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
        )}
        <GridItem xs={12}>
          <ReCAPTCHA
            sitekey={CAPTCHA_KEY}
            onChange={(value) => {
              setAttr({ ...attr, captcha: value });
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <Button
            color="warning"
            block
            onClick={register}
            className="btn-submit"
          >
            <i className="fas fa-paper-plane"></i>
            {t(`${mod}.register`)}
          </Button>
        </GridItem>
      </GridContainer>
    );
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.fullPage}>
        <div className={classes.container}>
          <GridContainer justify="center" className="register">
            <GridItem xs={12} className="box-img">
              <img src={app.setup.logo} width="88" height="88" />
            </GridItem>
            <GridItem xs={12} className="box-account">
              <h3 className="box-topic">{t(`${mod}.register`)}</h3>
            </GridItem>
            <GridItem xs={12}>{renderForm()}</GridItem>
            <GridItem xs={12} className="box-register">
              <NavLink to={"/login"}>
                <Button className="btn-link" simple size="lg" block>
                  <ArrowBackIcon /> {t(`${mod}.back`)}
                </Button>
              </NavLink>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Register);
