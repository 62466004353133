// @material-ui/icons
import LinkIcon from "@material-ui/icons/Link";

// @Component
import Dashboard from "views/Dashboard/Dashboard";
import Deposit from "views/Deposit/Deposit";
import Withdraw from "views/Withdraw/Withdraw";
import Profile from "views/Profile/Profile";
import Register from "views/Register/Register";
import History from "views/Report/History";
import Promotion from "views/Promotion/Promotion";
import Contact from "views/Contact/Contact";
import Message from "views/Message/Message";
import Manual from "views/Manual/Manual";
import Authen from "views/Authen/Authen";
import Trade from "views/Trade/Trade";
import Master from "views/Trade/Master";
import Monitor from "views/Trade/Monitor";
import Strength from "views/Trade/Strength";
import Trend from "views/Trade/Trend";
import Signal from "views/Trade/Signal";
import Home from "views/Home/Home";
import Product from "views/Shopping/Product";
import Affiliate from "views/Business/Affiliate";
import RobotList from "views/Robot/RobotList";
import Robot from "views/Robot/Robot";
import Binary from "views/Business/Binary";
import Sponsor from "views/Business/Sponsor";
import Crypto from "views/Trade/Crypto";
import PinRegis from "views/Authen/PinRegis";
import Pin from "views/Authen/Pin";
import Setting from "views/Setting/Setting";
import Cart from "views/Shopping/Cart";
import CheckOut from "views/Shopping/CheckOut";
import Wallet from "views/Deposit/Wallet";
import Business from "views/Business/Business";
import Voucher from "views/Shopping/Voucher";
import Order from "views/Shopping/Order";
import Donate from "views/Deposit/Donate";
import Login from "views/Authen/Login";
import Transfer from "views/Business/Transfer";
import RobotChange from "views/Robot/RobotChange";
import RobotEdit from "views/Robot/RobotEdit";
import Payment from "views/Shopping/Payment";
import ProfileBusd from "views/Profile/ProfileBusd";
import Receive from "views/Business/Receive";
import VoucherPoint from "views/Shopping/VoucherPoint";
import RobotKey from "views/Robot/RobotKey";
import Cashflow from "views/Report/Cashflow";
import ChangeLang from "views/Setting/ChangeLang";
import RobotSymbol from "views/Robot/RobotSymbol";
import RobotRenew from "views/Robot/RobotRenew";
import CommEdit from "views/Business/CommEdit";
import RobotBroker from "views/Robot/RobotBroker";
import RobotPort from "views/Robot/RobotPort";
import Seminar from "views/Seminar/Seminar";
import ROI from "views/Shopping/ROI";
import ProductLow from "views/Shopping/ProductLow";
import ProductHigh from "views/Shopping/ProductHigh";

var dashRoutes = [
  {
    path: "/authen",
    name: "",
    rtlName: "",
    icon: LinkIcon,
    component: Authen,
    layout: "",
  },
  {
    path: "/login",
    name: "",
    rtlName: "",
    icon: LinkIcon,
    component: Login,
    layout: "",
  },
  {
    path: "/pinregis",
    name: "",
    rtlName: "",
    icon: LinkIcon,
    component: PinRegis,
    layout: "",
  },
  {
    path: "/pin",
    name: "",
    rtlName: "",
    icon: LinkIcon,
    component: Pin,
    layout: "",
  },
  {
    path: "/register",
    name: "",
    rtlName: "",
    icon: LinkIcon,
    component: Register,
    layout: "",
  },
  {
    path: "/home",
    name: "",
    rtlName: "",
    icon: LinkIcon,
    component: Home,
    layout: "",
  },
  {
    path: "/setting",
    name: "",
    rtlName: "",
    icon: LinkIcon,
    component: Setting,
    layout: "",
  },
  {
    path: "/changelanguage",
    name: "",
    rtlName: "",
    icon: LinkIcon,
    component: ChangeLang,
    layout: "",
  },
  {
    path: "/dashboard",
    name: "",
    rtlName: "",
    icon: LinkIcon,
    component: Dashboard,
    layout: "",
  },
  {
    path: "/message",
    name: "",
    rtlName: "",
    icon: LinkIcon,
    component: Message,
    layout: "",
  },
  {
    path: "/monitor",
    name: "",
    rtlName: "",
    icon: LinkIcon,
    component: Monitor,
    layout: "",
  },
  {
    path: "/strength",
    name: "",
    rtlName: "",
    icon: LinkIcon,
    component: Strength,
    layout: "",
  },
  {
    path: "/trend",
    name: "",
    rtlName: "",
    icon: LinkIcon,
    component: Trend,
    layout: "",
  },
  {
    path: "/signal",
    name: "",
    rtlName: "",
    icon: LinkIcon,
    component: Signal,
    layout: "",
  },
  {
    path: "/crypto",
    name: "",
    rtlName: "",
    icon: LinkIcon,
    component: Crypto,
    layout: "",
  },
  {
    collapse: true,
    name: "Pages",
    rtlName: "",
    icon: LinkIcon,
    state: "pageCollapse",
    views: [
      {
        path: "/wallet",
        name: "wallet",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Wallet,
        layout: "",
      },
      {
        path: "/deposit",
        name: "Deposit",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Deposit,
        layout: "",
      },
      {
        path: "/donate",
        name: "donate",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Donate,
        layout: "",
      },
      {
        path: "/withdraw",
        name: "Withdraw",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Withdraw,
        layout: "",
      },
      {
        path: "/trade",
        name: "trade",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Trade,
        layout: "",
      },
      {
        path: "/history",
        name: "History",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: History,
        layout: "",
      },
      {
        path: "/promotion",
        name: "Promotion",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Promotion,
        layout: "",
      },
      {
        path: "/master",
        name: "Master",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Master,
        layout: "",
      },
      {
        path: "/contact",
        name: "Contact",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Contact,
        layout: "",
      },
      {
        path: "/manual",
        name: "Manual",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Manual,
        layout: "",
      },
      {
        path: "/seminar",
        name: "Seminar",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Seminar,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "shopping",
    rtlName: "",
    icon: LinkIcon,
    state: "shoppingCollapse",
    views: [
      {
        path: "/cart",
        name: "cart",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Cart,
        layout: "",
      },
      {
        path: "/checkout",
        name: "checkout",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: CheckOut,
        layout: "",
      },
      {
        path: "/product",
        name: "product",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Product,
        layout: "",
      },
      {
        path: "/productlow",
        name: "productlow",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: ProductLow,
        layout: "",
      },
      {
        path: "/producthigh",
        name: "producthigh",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: ProductHigh,
        layout: "",
      },
      {
        path: "/voucher",
        name: "voucher",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Voucher,
        layout: "",
      },
      {
        path: "/voucherpoint",
        name: "voucherpoint",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: VoucherPoint,
        layout: "",
      },
      {
        path: "/roi",
        name: "roi",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: ROI,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "business",
    rtlName: "",
    icon: LinkIcon,
    state: "businessCollapse",
    views: [
      {
        path: "/business",
        name: "business",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Business,
        layout: "",
      },
      {
        path: "/affiliate",
        name: "affiliate",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Affiliate,
        layout: "",
      },
      {
        path: "/binary",
        name: "binary",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Binary,
        layout: "",
      },
      {
        path: "/sponsor",
        name: "sponsor",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Sponsor,
        layout: "",
      },
      {
        path: "/transfer",
        name: "transfer",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Transfer,
        layout: "",
      },
      {
        path: "/receive",
        name: "receive",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Receive,
        layout: "",
      },
      {
        path: "/commedit",
        name: "commedit",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: CommEdit,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "robot",
    rtlName: "",
    icon: LinkIcon,
    state: "robotCollapse",
    views: [
      {
        path: "/robotlist",
        name: "robotlist",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: RobotList,
        layout: "",
      },
      {
        path: "/robot",
        name: "robot",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Robot,
        layout: "",
      },
      {
        path: "/robotchange",
        name: "robotchange",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: RobotChange,
        layout: "",
      },
      {
        path: "/editbroker",
        name: "editbroker",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: RobotBroker,
        layout: "",
      },
      {
        path: "/robotsymbol",
        name: "robotsymbol",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: RobotSymbol,
        layout: "",
      },
      {
        path: "/robotedit",
        name: "robotedit",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: RobotEdit,
        layout: "",
      },
      {
        path: "/editport",
        name: "editport",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: RobotPort,
        layout: "",
      },
      {
        path: "/robotkey",
        name: "robotkey",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: RobotKey,
        layout: "",
      },
      {
        path: "/renew",
        name: "renew",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: RobotRenew,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "orders",
    rtlName: "",
    icon: LinkIcon,
    state: "orderCollapse",
    views: [
      {
        path: "/order",
        name: "order",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Order,
        layout: "",
      },
      {
        path: "/payment",
        name: "payment",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Payment,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "profile",
    rtlName: "",
    icon: LinkIcon,
    state: "profileCollapse",
    views: [
      {
        path: "/profile",
        name: "profile",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Profile,
        layout: "",
      },
      {
        path: "/profilebusd",
        name: "profilebusd",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: ProfileBusd,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "report",
    rtlName: "",
    icon: LinkIcon,
    state: "reportCollapse",
    views: [
      {
        path: "/cashflow",
        name: "cashflow",
        rtlName: "",
        mini: "",
        rtlMini: "",
        component: Cashflow,
        layout: "",
      },
    ],
  },
];
export default dashRoutes;
