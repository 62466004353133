import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
  LineIcon,
  TwitterIcon,
} from "react-share";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Table from "components/Table/Table.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import copy from "copy-to-clipboard";

import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";

const useStyles = makeStyles(styles);

const initMember = {
  link_facebook: "",
};

function Master({ t }) {
  const mod = "affiliate";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const [notify, setNotify] = useState(null);
  const [member, setMember] = useState(initMember);
  const [loading, setLoading] = useState(false);
  const [downline, setDownline] = useState([]);
  const [sponsor, setSponsor] = useState([]);
  const [countDownline, setCountDownline] = useState("0");

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
    loadDownline();
  }, []);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setMember(result.member);
      } else {
        Func.exitApp();
      }
      setLoading(false);
    });
  };

  const loadDownline = () => {
    setLoading(true);
    PostData(mod, "downline", {
      token: app.token,
      lang: app.lang,
      count: 0,
    }).then((result) => {
      if (result.status === "OK") {
        setCountDownline(Func.numberDisplay(result.all, 0));
        result.data.map((item, i) => {
          setDownline((downline) => [
            ...downline,
            [item.downline_name, Func.dateDisplay(item.date_create)],
          ]);
        });
      } else {
        Func.exitApp();
      }
      setLoading(false);
    });
  };

  const loadSponsor = () => {
    setLoading(true);
    PostData(mod, "sponsor", {
      token: app.token,
      lang: app.lang,
      count: 0,
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setSponsor((sponsor) => [
            ...sponsor,
            [item.downline, Func.numberDisplay(item.bonus)],
          ]);
        });
      } else {
        Func.exitApp();
      }
      setLoading(false);
    });
  };

  const renderBalance = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card className="box">
            <CardBody className="body">
              <h4 className="title">
                <i className="fas fa-users" /> {t(`${mod}.countmember`)}{" "}
                {countDownline} {t(`${mod}.user`)}
              </h4>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderLinkPromote = () => {
    return (
      <Grid container justify="center" className="box-link">
        <Grid item xs={12}>
          <h4 className="title">{t(`${mod}.linkpromote`)}</h4>
        </Grid>
        <Grid item xs={12}>
          <div className="linkpromote">
            <i className="fas fa-share-alt-square" /> {member.link_promote}
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "10px", textAlign: "center" }}>
          <table width="100%">
            <tbody>
              <tr>
                <td width="25%" className="text-center">
                  {member.link_share === undefined ? null : (
                    <FacebookShareButton
                      url={member.link_share}
                      quote={member.quote}
                      hashtag={member.hashtag}
                      className=""
                    >
                      <FacebookIcon size={45} round />
                    </FacebookShareButton>
                  )}
                </td>
                <td width="25%" className="text-center">
                  {member.link_share === undefined ? null : (
                    <TwitterShareButton
                      url={member.link_share}
                      title={member.title}
                      className=""
                    >
                      <TwitterIcon size={45} round />
                    </TwitterShareButton>
                  )}
                </td>
                <td width="25%" className="text-center">
                  {member.link_share === undefined ? null : (
                    <LineShareButton
                      url={member.link_share}
                      title={member.title}
                      className=""
                    >
                      <LineIcon size={45} round />
                    </LineShareButton>
                  )}
                </td>
                <td width="25%" className="text-center">
                  {member.link_share === undefined ? null : (
                    <Button
                      justIcon
                      color="warning"
                      round
                      className=""
                      onClick={() => {
                        copy(member.link_share);
                        showNotify(t(`deposit.alert-copysuccess`), "success");
                      }}
                    >
                      <i className="fal fa-copy" />
                    </Button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid item xs={12}>
          <a href={`/register/${member.id}`} target="_blank">
            <Button color="danger" block size="lg" className="btn-register">
              <i className="fas fa-user-plus" /> {t(`authen.register`)}
            </Button>
          </a>
        </Grid>
      </Grid>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderMember = () => {
    return (
      <Grid container className="table">
        <Grid item xs={12}>
          <Table
            tableHeaderColor="primary"
            tableHead={[t(`${mod}.member`), t(`${mod}.date_regis`)]}
            tableData={downline}
            customHeadClassesForCells={[0, 1]}
            customHeadCellClasses={[classes.center, classes.center]}
            customClassesForCells={[0, 1]}
            customCellClasses={[classes.center, classes.center]}
            coloredColls={[1]}
            colorsColls={["primary"]}
          />
        </Grid>
      </Grid>
    );
  };

  const renderProfit = () => {
    return (
      <Grid container className="table">
        <Grid item xs={12}>
          <Table
            tableHeaderColor="primary"
            tableHead={[t(`${mod}.member`), t(`${mod}.profit`)]}
            tableData={sponsor}
            customHeadClassesForCells={[0, 1]}
            customHeadCellClasses={[classes.center, classes.center]}
            customClassesForCells={[0, 1]}
            customCellClasses={[classes.center, classes.center]}
            coloredColls={[1]}
            colorsColls={["primary"]}
          />
        </Grid>
      </Grid>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <div className="affiliate">
      <AuthNavbar mod={mod} />
      <div className={classes.container}>
        {renderBalance()}
        {renderLinkPromote()}
        {renderMember()}
        {renderLoading()}
        {notify}
      </div>
    </div>
  );
}

export default withNamespaces()(Master);
