import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { usePageName } from "react-page-name";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "components/Badge/Badge.js";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// @material-ui/icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// core components
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";

const useStyles = makeStyles(styles);

function Monitor({ t }) {
  const mod = "monitor";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [attr, setAttr] = useState({});
  const [list, setList] = useState([]);
  const [monitor, setMonitor] = useState([]);
  const [loading, setLoading] = useState(false);

  usePageName(t(`menu.monitor`));

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    if (id === undefined) init();
    else loadMonitor();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setList(result.list);
      }
      setLoading(false);
    });
  };

  const loadMonitor = () => {
    setLoading(true);
    PostData(mod, "load", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.trick);

        result.monitor.map((item, i) => {
          setMonitor((monitor) => [
            ...monitor,
            [
              <span className="symbol">{item.symbol}</span>,
              displayOption(item.tf_m5),
              displayOption(item.tf_m15),
              displayOption(item.tf_m30),
              displayOption(item.tf_h1),
              displayOption(item.tf_h4),
              displayOption(item.tf_d1),
              displayOption(item.tf_w1),
            ],
          ]);
        });
      }
      setLoading(false);
    });
  };

  const displayOption = (inp) => {
    if (inp === "B")
      return (
        <code className="color-success">
          <i className="fas fa-chart-line" />
        </code>
      );
    else if (inp === "S")
      return (
        <code className="color-danger">
          <i className="fad fa-chart-line-down" />
        </code>
      );
    else return <span>-</span>;
  };

  const renderList = () => {
    return (
      <Card className="card">
        <CardBody className="card-body">
          <List dense={false} className="list">
            {list.map((item) => (
              <div key={item.code}>
                <a href={`/monitor/${item.code}`}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className="avatar">
                        <i className="fad fa-analytics" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<span className="item">{item.name}</span>}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        className="status"
                      >
                        <Badge>
                          <i className="chevron fas fa-chevron-right fa-fw" />
                        </Badge>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider className="divider" variant="middle" />
                </a>
              </div>
            ))}
          </List>
        </CardBody>
      </Card>
    );
  };

  const renderMonitor = () => {
    return (
      <Card className="card">
        <CardBody className="card-body">
          <Table
            tableHead={["", "M5", "15", "30", "H1", "H4", "D1", "W1"]}
            tableData={monitor}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
            ]}
            customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
            ]}
            coloredColls={[]}
            colorsColls={[]}
          />
        </CardBody>
      </Card>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.container}>
          <Grid container>
            <Grid item xs={2}>
              {id === undefined ? null : (
                <NavLink to="/monitor">
                  <IconButton className="appbar-icon" aria-label="">
                    <ArrowBackIcon />
                  </IconButton>
                </NavLink>
              )}
            </Grid>
            <Grid item xs={8} style={{ textAlign: "center" }}>
              <h4 className="appbar-title">
                {id === undefined ? i18n.t(`menu.monitor`) : attr.name}
              </h4>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  return (
    <div className="block">
      {renderAppBar()}
      <div className={classes.container}>
        {id === undefined ? renderList() : renderMonitor()}
        {renderLoading()}
      </div>
    </div>
  );
}

export default withNamespaces()(Monitor);
