export const SET_LANGUAGE = "SET_LANGUAGE";
export const setEnLng = "AppStateEnLng";
export const setThLng = "AppStateThLng";
export const SET_TOKEN = "SET_TOKEN";
export const SET_SETUP = "SET_SETUP";

export const SET_USER = "SETUSERINFO";
export const CLEAR_USER = "CLEARUSERINFO";

export const SET_MENU = "SET_MENU";
export const CLEAR_MENU = "CLEAR_MENU";

export const SET_PERM = "SET_PERM";
export const CLEAR_PERM = "CLEAR_PERM";

export const SET_CART = "SET_CART";
export const CLEAR_CART = "CLEAR_CART";

export const SET_NOTICE = "SET_NOTICE";
export const CLEAR_NOTICE = "CLEAR_NOTICE";
