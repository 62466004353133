import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { usePageName } from "react-page-name";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";

// @material-ui/icons
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";

// core components
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import pic_home from "assets/img/cover/home.jpg";
import * as Func from "services/Function";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "views/Style/HomeStyle.css";
import pic_logo from "assets/img/logo/logo.png";

const useStyles = makeStyles(styles);

const initStrength = {
  sort_1: "",
  sort_2: "",
  sort_3: "",
  sort_4: "",
  sort_5: "",
  sort_6: "",
  sort_7: "",
  sort_8: "",
};

function Home({ t }) {
  const mod = "home";
  const classes = useStyles();
  const history = useHistory();
  const app = useSelector((state) => state.appState);
  const [member, setMember] = useState({});
  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState(0);
  const [signal, setSignal] = useState([]);
  const [strength, setStrength] = useState(initStrength);

  usePageName(t(`app.name`));

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setSignal(result.signal);
        setMember(result.member);
        setStrength(result.strength);
        setCart(result.count);
      }
      setLoading(false);
    });
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.container}>
          <Grid container>
            <Grid item xs={4}>
              <NavLink to={"/setting"}>
                <IconButton aria-label="">
                  <i className="fal fa-cog toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "center" }}>
              <NavLink to={"/profile"}>
                <img
                  src={member.filepic === undefined ? pic_logo : member.filepic}
                  width="70"
                  height="70"
                  className="img-round"
                />
              </NavLink>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              {cart > 0 ? (
                <NavLink to={"/cart"}>
                  <IconButton aria-label="">
                    <Badge
                      badgeContent={cart}
                      color="secondary"
                      className="badge"
                    >
                      <i className="fal fa-shopping-cart toolbar-icon" />
                    </Badge>
                  </IconButton>
                </NavLink>
              ) : (
                <NavLink to={"/product"}>
                  <IconButton aria-label="">
                    <Badge badgeContent={0} color="secondary" className="badge">
                      <i className="fal fa-shopping-cart toolbar-icon" />
                    </Badge>
                  </IconButton>
                </NavLink>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderStrength = () => {
    return (
      <div className="strength">
        <table width={"100%"} border={0} cellPadding={0} cellSpacing={0}>
          <tbody>
            <tr>
              <td className="strlabel sort_1">{strength.sort_1}</td>
              <td className="strblank">
                <i className="fad fa-chevron-right" />
              </td>
              <td className="strlabel sort_2">{strength.sort_2}</td>
              <td className="strblank">
                <i className="fad fa-chevron-right" />
              </td>
              <td className="strlabel sort_3">{strength.sort_3}</td>
              <td className="strblank">
                <i className="fad fa-chevron-right" />
              </td>
              <td className="strlabel sort_4">{strength.sort_4}</td>
              <td className="strblank">
                <i className="fad fa-chevron-right" />
              </td>
              <td className="strlabel sort_5">{strength.sort_5}</td>
              <td className="strblank">
                <i className="fad fa-chevron-right" />
              </td>
              <td className="strlabel sort_6">{strength.sort_6}</td>
              <td className="strblank">
                <i className="fad fa-chevron-right" />
              </td>
              <td className="strlabel sort_7">{strength.sort_7}</td>
              <td className="strblank">
                <i className="fad fa-chevron-right" />
              </td>
              <td className="strlabel sort_8">{strength.sort_8}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderSignal = () => {
    const optionDisplay = (item) => {
      if (item.options === "BUY")
        return (
          <span className={`text-option-BUY`}>
            <i className="fad fa-chart-line" /> BUY : {item.price}
          </span>
        );
      else
        return (
          <span className={`text-option-SELL`}>
            <i className="fad fa-chart-line-down" /> SELL : {item.price}
          </span>
        );
    };

    const resultDisplay = (item) => {
      if (item.result === "TP") {
        return (
          <div className={`text-option-TP`}>
            T/P <i className="fad fa-smile" />
          </div>
        );
      } else if (item.result === "SL") {
        return (
          <div className={`text-option-SL`}>
            S/L <i className="fad fa-sad-cry" />
          </div>
        );
      } else {
        if (item.swing > 0) {
          return <div className={`text-option-WIN`}>+{item.swing} p</div>;
        } else if (item.swing < 0) {
          return <div className={`text-option-LOSS`}>{item.swing} p</div>;
        } else {
          return <div className={`text-option-NONE`}>-</div>;
        }
      }
    };

    return (
      <Grid container>
        <Grid item xs={12}>
          <List dense={true}>
            {signal.map((item, i) => (
              <div key={i} className="read">
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <img src={item.product_pic} width="50" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <div>
                        <span className="text-primary">{item.symbol_name}</span>{" "}
                        <span>{optionDisplay(item)}</span>
                      </div>
                    }
                    secondary={
                      <i className="text-secondary">{item.date_create}</i>
                    }
                  />
                  <ListItemSecondaryAction>
                    {resultDisplay(item)}
                    <div className="text-price">{item.price_now}</div>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider className="divider" variant="middle" />
              </div>
            ))}
          </List>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="block">
      {renderAppBar()}
      <div className={classes.container}>
        <div className="home">
          {renderStrength()}
          {renderSignal()}
          {renderLoading()}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Home);
