/*================================================*\
*  Author : Programmer895
*  Created Date : 19/10/2020
\*================================================*/
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/icons
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import BanIcon from "@material-ui/icons/Block";

export const numberDisplay = (number, point = 2) => {
  number = number === undefined ? 0 : number * 1;
  return number.toFixed(point).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const moneyDisplay = (number, point = 0) => {
  let result = "";
  number = number === undefined ? 0 : number * 1;

  if (number >= 1000000) {
    number = number / 1000000;
    number = Math.floor(number);
    result = `${number}M`;
  } else if (number >= 1000) {
    number = number / 1000;
    number = Math.floor(number);
    result = `${number}K`;
  } else {
    result = number.toFixed(point).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  return result;
};

export const dateDisplay = (date, style = 1) => {
  let dt = new Date(date);

  let d = dt.getDate();
  let m = dt.getMonth() + 1;
  let y = dt.getFullYear();

  d = d < 10 ? "0" + d : d;
  m = m < 10 ? "0" + m : m;
  y = style == 2 ? y - 2000 : y;

  if (y > 0) return `${d}/${m}/${y}`;
  else return "";
};

export const dateTimeDisplay = (date, style = 1) => {
  let dt = new Date(date);

  let d = dt.getDate();
  let m = dt.getMonth() + 1;
  let y = dt.getFullYear();
  let h = dt.getHours();
  let i = dt.getMinutes();

  d = d < 10 ? "0" + d : d;
  m = m < 10 ? "0" + m : m;
  h = h < 10 ? "0" + h : h;
  i = i < 10 ? "0" + i : i;
  y = style == 2 ? y - 2000 : y;

  if (y > 0) return `${d}/${m}/${y} ${h}:${i}`;
  else return "";
};

export const dateFormat = (date) => {
  let dt = new Date(date);

  let d = dt.getDate();
  let m = dt.getMonth() + 1;
  let y = dt.getFullYear();

  d = d < 10 ? "0" + d : d;
  m = m < 10 ? "0" + m : m;

  return `${y}-${m}-${d}`;
};

export const dateFirstMonth = () => {
  let dt = new Date();
  let m = dt.getMonth() + 1;
  let y = dt.getFullYear();

  m = m < 10 ? "0" + m : m;

  return new Date(`${y}-${m}-01`);
};

export const checkDisplay = (inp) => {
  return inp === "Y" ? (
    <CheckIcon color="primary" />
  ) : (
    <BanIcon color="error" />
  );
};

export const showLoading = () => {
  return (
    <SweetAlert
      style={{ display: "block", marginTop: "-100px" }}
      title="Loading..."
      onConfirm={() => {}}
      showConfirm={false}
    />
  );
};

export const checkBool = (inp) => {
  return inp === "Y" ? true : false;
};

export const checkBase = (inp) => {
  return inp ? "Y" : "N";
};

export const verifyEmail = (value) => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};
// function that verifies if a string has a given length or not
export const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};
// function that verifies if value contains only numbers
export const verifyNumber = (value) => {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};
// verifies if value is a valid URL
export const verifyUrl = (value) => {
  try {
    new URL(value);
    return true;
  } catch (_) {
    return false;
  }
};

export const exitApp = () => {
  window.location.replace("/authen");
};
