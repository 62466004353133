import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Badge from "components/Badge/Badge.js";
import Divider from "@material-ui/core/Divider";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";

// core components
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Grid from "@material-ui/core/Grid";

import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";

import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";
import "views/Style/ShoppingStyle.css";

const useStyles = makeStyles(styles);

function Voucher({ t }) {
  const mod = "voucher";
  const classes = useStyles();
  const history = useHistory();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [attr, setAttr] = useState({});
  const [member, setMember] = useState({});
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    if (id === undefined) init();
    else load();
  }, [id]);

  const init = () => {
    setLoading(true);

    setMember({});
    setList([]);

    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setMember(result.member);
        setList(result.history);
      } else {
        history.push("/home");
      }
      setLoading(false);
    });
  };

  const load = () => {
    setLoading(true);

    setMember({});
    setList([]);
    setAttr({});

    PostData(mod, "load", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);
        setMember(result.member);
      } else {
        history.push("/home");
      }
      setLoading(false);
    });
  };

  const renderBalance = () => {
    return (
      <div className="box-balance">
        <table width="100%" cellPadding={5} cellSpacing={0}>
          <tbody>
            <tr>
              <td className="icon">
                <i className="fas fa-gift fa-2x" />
              </td>
              <td className="balance">{Func.numberDisplay(member.point)} {t(`shopping.point`)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderPay = () => {
    return (
      <NavLink to={`/voucherpoint`}>
        <Button block className="btn-point" size="sm" color="success">
          <i className="fad fa-gift" /> {t(`shopping.changepoint`)}
        </Button>
      </NavLink>
    );
  };

  const renderHistory = () => {
    if (list.length > 0)
      return (
        <div className="box-item">
          <h4 className="history-title">
            <i className="fas fa-history" /> {t(`shopping.pointhistory`)}
          </h4>
          <List dense={true}>
            {list.map((item) => (
              <NavLink to={`/voucher/${item.code}`} key={item.code}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <img src={item.filepic} width="50" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<div>{item.voucher_name}</div>}
                    secondary={
                      <i className="text-secondary">
                        {Func.dateTimeDisplay(item.date_create)}
                      </i>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Badge color={item.status_color}>
                      <i className={item.status_icon} /> {item.status_name}
                    </Badge>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider style={{ background: "#e2e2e2" }} variant="middle" />
              </NavLink>
            ))}
          </List>
        </div>
      );
    else return null;
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={id === undefined ? "/home" : "/voucher"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              {id === undefined ? (
                <h3 className="header-title">{t(`menu.voucher`)}</h3>
              ) : (
                <h3 className="header-title">ID : {attr.id}</h3>
              )}
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderSummary = () => {
    return (
      <div className="box-item">
        <Grid container>
          <Grid item xs={12}>
            <img src={attr.filepic} className="item-img" />
          </Grid>
          <Grid item xs={12}>
            <h5>{attr.voucher_name}</h5>
          </Grid>
          <Grid item xs={12} className="point-detail">
            {attr.voucher_detail}
          </Grid>
          <Grid item xs={12}>
            <div className="box-point">
              {Func.numberDisplay(attr.amount)} {t(`shopping.point`)}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderAddress = () => {
    return (
      <div className="box-item">
        <Grid container>
          <Grid item xs={12}>
            <h5>{t(`profile.address`)}</h5>
          </Grid>
          <Grid item xs={12} className="point-detail">
            {attr.name}
          </Grid>
          <Grid item xs={12} className="point-detail">
            {attr.address} <br />
            {attr.address2} <br />
            {attr.province} {attr.zipcode}
          </Grid>
          <Grid item xs={12} className="point-detail">
            {attr.tel}
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderStatus = () => {
    return (
      <h3 className={`item-status item-${attr.status_color}`}>
        <i className={attr.status_icon} /> {attr.status_name}
      </h3>
    );
  };

  const renderList = () => {
    return (
      <>
        {renderBalance()}
        {renderPay()}
        {renderHistory()}
      </>
    );
  };

  const renderLoad = () => {
    return (
      <>
        {renderStatus()}
        {renderSummary()}
        {attr.request_address === "Y" ? renderAddress() : null}
      </>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="shopping">
          {renderAppBar()}
          {id === undefined ? renderList() : renderLoad()}
          {renderLoading()}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Voucher);
