import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink, useHistory } from "react-router-dom";
import liff from "@line/liff";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import i18n from "components/Translation/Translate.js";
import * as Defi from "services/Define";
import { setToken } from "redux/actions/AppStateAction";

// @material-ui/icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
  const dispatch = useDispatch();

  const app = useSelector((state) => state.appState);
  const history = useHistory();

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    if (window.location.hostname !== "localhost") {
      init();
    }
  }, []);

  const init = () => {
    liff.init(
      { liffId: Defi.LIFF_ID },
      () => {
        if (!liff.isLoggedIn()) {
          history.replace("/authen");
        }
      },
      (err) => console.error(err)
    );
  };

  const logout = () => {
    liff.logout();
    dispatch(setToken(""));

    history.replace("/authen");
  };

  const [target, setTarget] = useState("/home");
  const classes = useStyles();
  const { color, brandText } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });

  const renderAppBar = () => {
    return (
      <Grid container>
        <Grid item xs={4}>
          <NavLink to={"/profile"}>
            <IconButton aria-label="">
              <i className="fal fa-user-circle" />
            </IconButton>
          </NavLink>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "center" }}>
          <NavLink to={"/home"}>
            <img src={app.setup.logo} width="70" height="70" />
          </NavLink>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <IconButton onClick={logout}>
            <i className="fal fa-shopping-cart" />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const renderBar = () => {
    const cssIcon = { color: "#ffffff" };
    const cssTitle = { color: "#ffffff" };

    return (
      <Grid container>
        <Grid item xs={2}>
          <NavLink to={target}>
            <IconButton style={cssIcon} aria-label="">
              <ArrowBackIcon />
            </IconButton>
          </NavLink>
        </Grid>
        <Grid item xs={8} style={{ textAlign: "center" }}>
          <h4 style={cssTitle}>{i18n.t(`menu.${props.mod}`)}</h4>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    );
  };

  if (props.mod === "home") {
    // return null;
    return (
      <AppBar position="static" className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>{renderAppBar()}</Toolbar>
      </AppBar>
    );
  } else {
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.container}>{renderBar()}</Toolbar>
      </AppBar>
    );
  }
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
};
