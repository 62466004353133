import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { usePageName } from "react-page-name";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";
import "views/Style/ShoppingStyle.css";

const useStyles = makeStyles(styles);

const initAttr = {
  payment: "",
  chain: "",
};

function Payment({ t }) {
  const mod = "order";
  const classes = useStyles();
  const history = useHistory();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [attr, setAttr] = useState(initAttr);
  const [member, setMember] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);

  usePageName(t(`menu.order`));

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "load", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.order);
        setMember(result.member);
        setList(result.list);
      }
      setLoading(false);
    });
  };

  const confirmPayment = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("payment.confirm_add_title")}
        onConfirm={() => {
          setAlert(null);
          PostData(mod, "paymentrequest", {
            token: app.token,
            lang: app.lang,
            payment: attr.payment,
            chain: attr.chain,
          }).then((result) => {
            window.location.replace("/home");
            // if (result.status === "OK") {
            //   loadCart();
            //   showNotify(t("alert.addcomplete"), "success");
            //   setTimeout(function () {
            //     window.location.replace(result.url);
            //   }, 3000);
            // } else {
            //   showNotify(result.msg, "danger");
            // }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("payment.confirm_add_msg")}
      </SweetAlert>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={"/order"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">{attr.id}</h3>
            </Grid>
            <Grid item xs={2} className="cart"></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderList = () => {
    return (
      <div className="box-item">
        <table width="100%" cellPadding={3} cellSpacing={0} border="0">
          <tbody>
            {list.map((item, i) => (
              <tr key={i}>
                <td width="10%">
                  <img src={item.filepic} className="item-img" />
                </td>
                <td className="item-topic">
                  {item.product_name} [{item.price_name}]
                </td>
                <td width="40%" className="item-amount">
                  {Func.numberDisplay(item.price, 2)} USD
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderSummary = () => {
    return (
      <div className="box-item">
        <table width="100%" cellPadding={3} cellSpacing={0} border="0">
          <tbody>
            <tr>
              <td className="item-sum">Amount</td>
              <td width="40%" className="item-amount">
                {Func.numberDisplay(attr.total, 2)} USD
              </td>
            </tr>
            <tr>
              <td className="item-sum">PV</td>
              <td className="item-amount">
                {Func.numberDisplay(attr.total_pv, 2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderPayment = () => {
    return (
      <div className="box-item">
        <h5>Payment</h5>
        <div
          className={
            classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
          }
        >
          <FormControlLabel
            control={
              <Radio
                checked={attr.payment === "BUSD"}
                onChange={(e) =>
                  setAttr({
                    ...attr,
                    payment: e.target.value,
                  })
                }
                value="BUSD"
                name="rdoPaymenType"
                aria-label="BUSD"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label="BUSD"
          />
        </div>
        <div
          className={
            classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
          }
        >
          <FormControlLabel
            control={
              <Radio
                checked={attr.payment === "WALLET"}
                onChange={(e) =>
                  setAttr({
                    ...attr,
                    payment: e.target.value,
                  })
                }
                value="WALLET"
                name="rdoPaymenType"
                aria-label="WALLET"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label={
              <span>Wallet {Func.numberDisplay(member.balance, 2)} BUSD</span>
            }
          />
        </div>
      </div>
    );
  };

  const renderChain = () => {
    return (
      <div className="box-item">
        <h5>Chain</h5>
        <div
          className={
            classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
          }
        >
          <FormControlLabel
            control={
              <Radio
                checked={attr.chain === "BSC"}
                onChange={(e) =>
                  setAttr({
                    ...attr,
                    chain: e.target.value,
                  })
                }
                value="BSC"
                name="rdoChain"
                aria-label="BSC"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label="Binance (BEP20)"
          />
        </div>
        <div
          className={
            classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
          }
        >
          <FormControlLabel
            control={
              <Radio
                checked={attr.chain === "ETH"}
                onChange={(e) =>
                  setAttr({
                    ...attr,
                    chain: e.target.value,
                  })
                }
                value="ETH"
                name="rdoChain"
                aria-label="ETH"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label={<span>Ethereum (ERC20)</span>}
          />
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <BottomNavigation
        onChange={(e, link) => {
          if (link === "cart") history.push(`/${link}`);
          else if (link === "confirm") confirmPayment();
        }}
        showLabels
        className={"bottom"}
      >
        <BottomNavigationAction
          label={
            <div className="menu">
              <i className="fad fa-arrow-alt-circle-left color-danger" /> Back
            </div>
          }
          value="cart"
        />
        <BottomNavigationAction
          label={
            <div className="menu">
              Confirm <i className="fad fa-arrow-circle-right color-success" />
            </div>
          }
          value="confirm"
        />
      </BottomNavigation>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="shopping">
          {renderAppBar()}
          {renderList()}
          {renderSummary()}
          {renderPayment()}
          {attr.payment === "BUSD" ? renderChain() : null}
          {renderFooter()}
          {renderLoading()}
          {alert}
          {notify}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Payment);
