import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";
import Close from "@material-ui/icons/Close";

// core components
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Backdrop from "@material-ui/core/Backdrop";
import CardFooter from "components/Card/CardFooter.js";
import Slider from "react-slick";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { setToken } from "redux/actions/AppStateAction";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles(styles);

function Dashboard({ t }) {
  const mod = "dashboard";
  const classes = useStyles();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.appState);
  const history = useHistory();
  const [member, setMember] = useState({});
  const [slideshow, setSlideshow] = useState([]);
  const [popup, setPopup] = useState([]);
  const [modalPopup, setModalPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(null);
  const [alert, setAlert] = useState(null);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setMember(result.member);
        setSlideshow(result.slideshow);

        setIsLogin(true);
      } else {
        dispatch(setToken(""));
        history.push(`/authen`);
      }
      setLoading(false);
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderBalance = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Card className="box">
            <CardBody className="body">
              <h4 className="title">
                <i className="fas fa-coins" /> {t("status.balance")}
              </h4>
              <p className="detail">{Func.numberDisplay(member.balance)} $</p>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const renderSlideshow = () => {
    const settings = {
      autoplay: true,
      autoplaySpeed: 3000,
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 1,
      speed: 500,
      arrows: false,
    };
    const cssLink = { marginRight: "5px", display: "block" };
    const cssImg = { width: "100%", borderRadius: "5px" };
    return (
      <Slider {...settings}>
        {slideshow &&
          slideshow.map((item) => (
            <div
              key={item.code}
              onClick={() => {
                console.log(1);
              }}
            >
              <a href={item.url} style={cssLink}>
                <img src={item.filepic} style={cssImg} />
              </a>
            </div>
          ))}
      </Slider>
    );
  };

  const renderButton = () => {
    return (
      <Grid container className="box-btn">
        <Grid item xs={12}>
          <table width="100%" cellPadding="3">
            <tbody>
              <tr>
                <td width="25%">
                  <NavLink to={"/promotion"} className="navlink">
                    <div className="box-icon">
                      <i className="fal fa-gift btn-icon" />
                    </div>
                    <div className={classes.center}>
                      {t("dashboard.promotion")}
                    </div>
                  </NavLink>
                </td>
                <td>
                  <NavLink to={"/master"} className="navlink">
                    <div className="box-icon">
                      <i className="fas fa-user-tie btn-icon" />
                    </div>
                    <div className={classes.center}>{t("menu.master")}</div>
                  </NavLink>
                </td>
                <td width="25%">
                  <NavLink to={"/deposit"} className="navlink">
                    <div className="box-icon">
                      <i className="fal fa-wallet btn-icon" />
                    </div>
                    <div className={classes.center}>
                      {t("dashboard.deposit")}
                    </div>
                  </NavLink>
                </td>
                <td width="25%">
                  <NavLink to={"/withdraw"} className="navlink">
                    <div className="box-icon">
                      <i className="fal fa-hand-holding-usd btn-icon" />
                    </div>
                    <div className={classes.center}>
                      {t("dashboard.withdraw")}
                    </div>
                  </NavLink>
                </td>
              </tr>
              <tr>
                <td width="25%">
                  <NavLink to={"/trade"} className="navlink">
                    <div className="box-icon">
                      <i className="fas fa-chart-line btn-icon" />
                    </div>
                    <div className={classes.center}>Trade</div>
                  </NavLink>
                </td>
                <td>
                  <NavLink to={"/profile"} className="navlink">
                    <div className="box-icon">
                      <i className="fas fa-inbox btn-icon" />
                    </div>
                    <div className={classes.center}>History</div>
                  </NavLink>
                </td>
                <td>
                  <NavLink to={"/profile"} className="navlink">
                    <div className="box-icon">
                      <i className="far fa-user btn-icon" />
                    </div>
                    <div className={classes.center}>
                      {t("dashboard.profile")}
                    </div>
                  </NavLink>
                </td>
                <td>
                  <NavLink to={"/manual"} className="navlink">
                    <div className="box-icon">
                      <i className="fas fa-user-shield btn-icon" />
                    </div>
                    <div className={classes.center}>Admin</div>
                  </NavLink>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    );
  };

  const renderModalPopup = () => {
    const settings = {
      autoplay: true,
      autoplaySpeed: 5000,
      className: "center",
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 1,
      speed: 500,
      arrows: false,
    };
    const cssLink = { display: "block", color: "var(--whites)" };
    const cssImg = { width: "100%", borderRadius: "5px" };

    return (
      <Dialog
        className="game-modal"
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={modalPopup}
        keepMounted
        onClose={() => setModalPopup(false)}
        aria-labelledby="modal-popup-title"
        aria-describedby="modal-popup-description"
      >
        <DialogTitle
          id="classic-modal-popup-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalPopup(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}></h4>
        </DialogTitle>
        <DialogContent
          id="modal-popup-description"
          className={classes.modalBody}
          style={{ padding: "0 10px 10px 10px", width: "350px" }}
        >
          <Slider {...settings}>
            {popup &&
              popup.map((item) => (
                <div
                  key={item.code}
                  onClick={() => {
                    console.log(1);
                  }}
                >
                  <a href={item.url} style={cssLink}>
                    <h4 className={classes.center}>{item.name}</h4>
                    <img src={item.filepic} style={cssImg} />
                    <p style={{ marginTop: "10px" }}>
                      <span dangerouslySetInnerHTML={{ __html: item.detail }} />
                    </p>
                  </a>
                </div>
              ))}
          </Slider>
        </DialogContent>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <div className="dashboard">
      {isLogin ? (
        <div>
          <AuthNavbar mod={mod} />
          <div className={classes.container}>
            {renderBalance()}
            {renderSlideshow()}
            {renderButton()}
          </div>
        </div>
      ) : null}
      {renderModalPopup()}
      {renderLoading()}
      {notify}
      {alert}
    </div>
  );
}

export default withNamespaces()(Dashboard);
