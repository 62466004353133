import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "./redux/store";

import AuthLayout from "layouts/Auth.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import "app.css";
import { PostSecure } from "services/Request";

const hist = createBrowserHistory();

PostSecure("authen", "init", {}).then((result) => {
  if (result.status === "OK") {
    ReactDOM.render(
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={hist}>
            <Switch>
              <Route path="/">
                <AuthLayout setup={result.setup} />
              </Route>
            </Switch>
          </Router>
        </PersistGate>
      </Provider>,
      document.getElementById("root")
    );
  } else {
    ReactDOM.render(
      <h1 style={{ textAlign: "center", color: "white" }}>ปิดระบบ</h1>,
      document.getElementById("root")
    );
  }
});
