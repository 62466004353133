/*================================================*\
*  Author : Programmer895
*  Created Date : 19/10/2020
\*================================================*/

export const DEBUG = false;
export const DEBUG_ID = "U5d3b1a1aec610bf29f449fe1cc1898xx";
export const DEBUG_TOKEN = "eyJraWQiOiIwZjdhYzBmOGEyMmUxMzFiNWZlNz";
export const DEBUG_NAME = "Tester";
export const DEBUG_PIC = "https://picsum.photos/id/1/300/300";
export const DEBUG_STATUS = "Dev Test";
export const DEBUG_EMAIL = "rahuserpent19@gmail.com";

export const APP_NAME = "";
export const API_URL = "";

/* LINE LIFF */
export const LIFF_ID = "1656855564-2WAvvmeB";
