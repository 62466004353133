import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";

import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";

import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import "views/Style/ShoppingStyle.css";

const useStyles = makeStyles(styles);

const initFind = {
  balance: "1000",
  profit: "10",
  month: "12",
  risk: "1",
  type: "grid",
};
const initAttr = {
  capital: "",
  profit: "",
};

function ROI({ t }) {
  const mod = "roi";
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const app = useSelector((state) => state.appState);
  const [notify, setNotify] = useState(null);
  const [find, setFind] = useState(initFind);
  const [attr, setAttr] = useState(initAttr);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("Martingale");

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    searchGrid();
  }, [id]);

  const searchMartingale = () => {
    setLoading(true);

    setAttr(initAttr);
    setFind({ ...find, type: "martingale" });

    PostData(mod, "martingale", {
      token: app.token,
      lang: app.lang,
      data: find,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);
        setList(result.roi);
      } else {
        history.push("/home");
      }
      setLoading(false);
    });
  };

  const searchGrid = () => {
    setLoading(true);

    setAttr(initAttr);
    setFind({ ...find, type: "grid" });

    PostData(mod, "grid", {
      token: app.token,
      lang: app.lang,
      data: find,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);
        setList(result.roi);
      } else {
        history.push("/home");
      }
      setLoading(false);
    });
  };

  const renderSearch = () => {
    return (
      <div className="box-item">
        <form>
          <GridContainer>
            <GridItem xs={12}>
              <TextField
                id="balance"
                fullWidth
                InputProps={{
                  type: "number",
                  value: find.balance,
                  onChange: (e) => {
                    setFind({ ...find, balance: e.target.value });
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>Balance :</span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fal fa-dollar-sign" />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                id="month"
                fullWidth
                InputProps={{
                  type: "number",
                  value: find.month,
                  onChange: (e) => {
                    setFind({ ...find, month: e.target.value });
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>Month :</span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fal fa-calendar-alt" />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                id="profit"
                fullWidth
                InputProps={{
                  type: "number",
                  value: find.profit,
                  onChange: (e) => {
                    setFind({ ...find, profit: e.target.value });
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>Profit / Month :</span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fal fa-percent" />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </GridItem>
            <GridItem xs={12}>{renderRisk()}</GridItem>
            <GridItem xs={6}>
              <Button
                color={find.type === "grid" ? "primary" : null}
                size="sm"
                fullWidth
                onClick={() => searchGrid()}
              >
                <i className="fad fa-check-circle" /> &nbsp; Grid
              </Button>
            </GridItem>
            <GridItem xs={6}>
              <Button
                color={find.type === "martingale" ? "primary" : null}
                size="sm"
                fullWidth
                onClick={() => searchMartingale()}
              >
                <i className="fad fa-check-circle" /> &nbsp; Martingale
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </div>
    );
  };

  const renderRisk = () => {
    return (
      <Grid container>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Radio
                checked={find.risk === "1"}
                onChange={(e) =>
                  setFind({
                    ...find,
                    profit: "10",
                    risk: e.target.value,
                  })
                }
                value="1"
                name="rdoRisk"
                aria-label="LOWRISK"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label={<span>Low</span>}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Radio
                checked={find.risk === "2"}
                onChange={(e) =>
                  setFind({
                    ...find,
                    profit: "20",
                    risk: e.target.value,
                  })
                }
                value="2"
                name="rdoRisk"
                aria-label="MEDIUMRISK"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label={<span>Medium</span>}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Radio
                checked={find.risk === "3"}
                onChange={(e) =>
                  setFind({
                    ...find,
                    profit: "40",
                    risk: e.target.value,
                  })
                }
                value="3"
                name="rdoRisk"
                aria-label="HIGHRISK"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label={<span>High</span>}
          />
        </Grid>
      </Grid>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={`/home`}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">ROI</h3>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderResult = () => {
    return (
      <div className="box-roi">
        <table className="table" width="100%" cellPadding={5} cellSpacing={0}>
          <thead>
            <tr>
              <th colSpan={5}>
                {find.type === "grid" ? "Grid" : "Martingale"}
              </th>
            </tr>
            <tr>
              <th width="20%">Month</th>
              <th width="20%">Balance</th>
              <th width="20%">Lot</th>
              <th width="20%">Profit</th>
              <th width="20%">Net B/L</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, i) => (
              <tr key={i}>
                <td className="text-center">{item.month}</td>
                <td className="text-right">{item.balance}</td>
                <td className="text-right">{item.lot}</td>
                <td className="text-right">{item.profit}</td>
                <td className="text-right">{item.netbalance}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th className="text-right" colSpan={4}>
                Balance
              </th>
              <th className="text-right">{attr.capital}</th>
            </tr>
            <tr>
              <th className="text-right" colSpan={4}>
                Profit
              </th>
              <th className="text-right">{attr.profit}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="shopping">
          {renderAppBar()}
          {renderSearch()}
          {renderResult()}
          {renderLoading()}
          {notify}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(ROI);
