import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Grid from "@material-ui/core/Grid";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";

import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";
import "views/Style/DepositStyle.css";

const useStyles = makeStyles(styles);

const initAttr = {
    api_key: "",
    api_secret: "",
};
function RobotKey({ t }) {
    const mod = "robot";
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();
    const app = useSelector((state) => state.appState);
    const [notify, setNotify] = useState(null);
    const [attr, setAttr] = useState(initAttr);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [amount, setAmount] = useState("");

    useEffect(() => {
        i18n.changeLanguage(app.lang);

        init();
    }, [id]);

    const init = () => {
        setLoading(true);

        setAttr(initAttr);
        setList([]);

        PostData(mod, "load", {
            token: app.token,
            lang: app.lang,
            id: id
        }).then((result) => {
            if (result.status === "OK") {
                // setAttr(result.data);
            } else {
                history.push("/home")
            }
            setLoading(false);
        });
    };

    const showNotify = (msg, color) => {
        setNotify(
            <Snackbar
                place="tc"
                color={color}
                icon={AlertIcon}
                message={msg}
                open={true}
                closeNotification={() => setNotify(null)}
                close
            />
        );

        setTimeout(function () {
            setNotify(null);
        }, 3000);
    };

    const edit = () => {
        setLoading(true);
        PostData(mod, "editkey", {
            token: app.token,
            lang: app.lang,
            id: id,
            data: {
                api_key: attr.api_key,
                api_secret: attr.api_secret,
            }
        }).then((result) => {
            if (result.status === "OK") {
                showNotify(t(result.msg), "success");
                window.location.replace(`/robot/${id}`);
            } else if (result.status === "ALERT") {
                showNotify(t(result.msg), "danger");
            } else {
                history.push("/home");
            }
            setLoading(false);
        });
    };

    const renderEdit = () => {
        return (
            <div className="box-item">
                <form>
                    <GridContainer>
                        <GridItem xs={12}>
                            <TextField
                                id="api_key"
                                fullWidth
                                InputProps={{
                                    type: "text",
                                    className: "text-topup",
                                    value: attr.api_key,
                                    onChange: (e) => {
                                        setAttr({ ...attr, api_key: e.target.value });
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <span>API Key :</span>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="fas fa-key" />
                                        </InputAdornment>
                                    ),
                                    autoComplete: "off",
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <TextField
                                id="api_secret"
                                fullWidth
                                InputProps={{
                                    type: "text",
                                    className: "text-topup",
                                    value: attr.api_secret,
                                    onChange: (e) => {
                                        setAttr({ ...attr, api_secret: e.target.value });
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <span>API Secret :</span>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="fas fa-key" />
                                        </InputAdornment>
                                    ),
                                    autoComplete: "off",
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </form>
            </div>
        );
    };

    const renderAppBar = () => {
        return (
            <AppBar position="static" className="header-bg">
                <Toolbar className="toolbar">
                    <Grid container>
                        <Grid item xs={2}>
                            <NavLink to={`/robot/${id}`}>
                                <IconButton aria-label="">
                                    <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                                </IconButton>
                            </NavLink>
                        </Grid>
                        <Grid item xs={8}>
                            <h3 className="header-title">{t(`menu.robot`)}</h3>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    };

    const renderFooter = () => {
        return (
            <BottomNavigation
                onChange={(e, link) => {
                    if (link === "back") history.push(`/robot/${id}`);
                    else if (link === "save") edit();
                }}
                showLabels
                className={"bottom"}
            >
                <BottomNavigationAction
                    label={
                        <div className="menu">
                            <i className="fad fa-times color-danger" /> {t(`button.cancel`)}
                        </div>
                    }
                    value="back"
                />
                <BottomNavigationAction
                    label={
                        <div className="menu">
                            {t(`button.save`)} <i className="fad fa-save color-success" />
                        </div>
                    }
                    value="save"
                />
            </BottomNavigation>
        );
    };

    const renderLoading = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    };

    return (
        <div className="block">
            <div className={classes.container}>
                <div className="deposit">
                    {renderAppBar()}
                    {renderEdit()}
                    {renderFooter()}
                    {renderLoading()}
                    {notify}
                </div>
            </div>
        </div>
    );
}

export default withNamespaces()(RobotKey);
