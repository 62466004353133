import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "components/Badge/Badge.js";
import Divider from "@material-ui/core/Divider";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";

import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";

const useStyles = makeStyles(styles);

function Message({ t }) {
  const mod = "message";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    setLoading(true);
    setHistory([]);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setHistory(result.data);
      } else {
        Func.exitApp();
      }
      setLoading(false);
    });
  };

  const readMessage = (item) => {
    if (item.readmsg == "N")
      PostData(mod, "read", {
        token: app.token,
        lang: app.lang,
        id: item.code,
      }).then((result) => {
        if (result.status === "OK") {
          init();
        } else {
          Func.exitApp();
        }
      });
  };

  const renderIcon = (item) => {
    switch (item.status) {
      case "1":
        return <AccountBalanceWalletIcon />;
      case "2":
        return <AccountBalanceIcon />;
      default:
        return <DragIndicatorIcon />;
    }
  };

  const amountDisplay = (item) => {
    return <div>$ {Func.numberDisplay(item.amount, 0)}</div>;
  };

  const renderHistory = () => {
    return (
      <Card style={{ marginTop: "0" }}>
        <CardBody className="history">
          <List dense={true}>
            {history.map((item, i) => (
              <div key={i} className={item.readmsg == "Y" ? "read" : "unread"}>
                <ListItem button onClick={() => readMessage(item)}>
                  <ListItemAvatar>
                    <Avatar>{renderIcon(item)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t(`message.${item.message}`)}
                    secondary={
                      <i className="text-secondary">
                        {Func.dateTimeDisplay(item.date_create)}
                      </i>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      className="status"
                    >
                      <Badge color={item.status_color}>
                        {amountDisplay(item)}
                      </Badge>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider
                  style={{ background: "var(--divider)" }}
                  variant="middle"
                />
              </div>
            ))}
          </List>
        </CardBody>
      </Card>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <div className="message">
      <AuthNavbar mod={mod} />
      <div className={classes.container}>
        {renderHistory()}
        {renderLoading()}
      </div>
    </div>
  );
}

export default withNamespaces()(Message);
