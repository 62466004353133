import { SET_CART, CLEAR_CART } from "../types";

const initialState = {
  count: 0,
  cart: null,
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CART:
      return action.payload;
    case CLEAR_CART:
      return action.payload;
    default:
      return state;
  }
};
