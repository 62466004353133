import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";

// core components
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import * as Func from "services/Function";
import "views/Style/ShoppingStyle.css";

const useStyles = makeStyles(styles);

function Product({ t }) {
  const mod = "product";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [attr, setAttr] = useState({});
  const [list, setList] = useState([]);
  const [symbol, setSymbol] = useState([]);
  const [winrate, setWinrate] = useState([]);
  const [price, setPrice] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(null);
  const [cart, setCart] = useState(0);
  const [other, setOther] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    load();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setList(result.list);
        setCart(result.count);
      }
      setLoading(false);
    });
  };

  const addToCart = (item) => {
    setLoading(true);
    PostData(mod, "addtocart", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      if (result.status === "OK") {
        setCart(result.count);
        showNotify(t(result.msg), "primary");
        setTimeout(function () {
          window.location.replace("/cart");
        }, 500);
      } else if (result.status === "ALERT") {
        showNotify(t(result.msg), "danger");
      } else {
        Func.exitApp();
      }
      setLoading(false);
    });
  };

  const load = () => {
    setLoading(true);
    PostData(mod, "load", {
      token: app.token,
      lang: app.lang,
      id: id === undefined ? "" : id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.product);
        setPrice(result.price);
        setCart(result.count);
        setSymbol(result.symbol);
        setWinrate(result.winrate);
        setOther(result.other);
      }
      setLoading(false);
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="bc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={"/home"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">
                <span>
                  {attr.product_name} {attr.product_detail}
                </span>
              </h3>
            </Grid>
            <Grid item xs={2} className="cart">
              {cart > 0 ? (
                <NavLink to={"/cart"}>
                  <IconButton aria-label="">
                    <Badge
                      badgeContent={cart}
                      color="secondary"
                      className="badge"
                    >
                      <i className="fas fa-shopping-cart toolbar-icon" />
                    </Badge>
                  </IconButton>
                </NavLink>
              ) : null}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderList = () => {
    return list.map((item, i) => (
      <div key={i} className="box-item">
        <NavLink to={`/product/${item.code}`}>
          <table width="100%" cellPadding={3} cellSpacing={0} border="0">
            <tbody>
              <tr>
                <td width="50%">
                  <img src={item.filepic} className="item-img" />
                </td>
                <td valign="top">
                  <h3 className="item-title">{item.product_name}</h3>
                  <p className="item-detail">{item.detail}</p>
                  {item.data.map((value, j) => (
                    <div key={j} className="item-box">
                      <table width="100%" cellPadding={3}>
                        <tbody>
                          <tr>
                            <td className="item-price">{value.name}</td>
                            <td className="item-price" align="right">
                              {Func.numberDisplay(value.price, 0)} ฿
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </NavLink>
      </div>
    ));
  };

  const renderItem = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <img src={attr.filecover} className="img" />
        </Grid>
        <Grid item xs={12}>
          <div className="box-price">
            {price.map((value, j) => (
              <div key={j} className="item-box">
                <table width="100%" cellPadding={3}>
                  <tbody>
                    <tr>
                      <td className="product-price" width="30%">
                        {value.name}
                      </td>
                      <td className="product-price" width="20%" align="right">
                        {Func.numberDisplay(value.price, 0)} ฿
                      </td>
                      <td align="right">
                        <Button
                          color="primary"
                          size="sm"
                          className="item-cart"
                          onClick={() => addToCart(value)}
                          round
                        >
                          <i className="fas fa-shopping-cart" />
                          {t(`shopping.buynow`)}
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </Grid>
        {attr.filedetail === "" ? null : (
          <Grid item xs={12}>
            <img src={attr.filedetail} className="img" />
          </Grid>
        )}
        {symbol.length === 0 ? null : (
          <Grid item xs={12}>
            {renderSymbol()}
          </Grid>
        )}
        {winrate.length === 0 ? null : (
          <Grid item xs={12}>
            {renderWinrate()}
          </Grid>
        )}
        <Grid item xs={12}>
          <br />
          <GridContainer>
            {other.map((item, i) => (
              <GridItem key={i} xs={6}>
                <NavLink to={`/product/${item.code}`}>
                  <img src={item.filepic} className="otherimg" />
                </NavLink>
                <h3 className="othertext">{item.product_name}</h3>
              </GridItem>
            ))}
          </GridContainer>
          <br />
        </Grid>
      </Grid>
    );
  };

  const renderSymbol = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <List dense={true}>
            {symbol.map((item, i) => (
              <div key={i} className="read">
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <img src={item.filepic} width="50" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<span className="text-primary">{item.name}</span>}
                    secondary={
                      <span className="text-secondary">
                        <i>{item.detail}</i>
                      </span>
                    }
                  />
                  <ListItemSecondaryAction>
                    <i className="fas fa-coins arrow" />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider className="divider" variant="middle" />
              </div>
            ))}
          </List>
        </Grid>
      </Grid>
    );
  };

  const renderWinrate = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <List dense={true}>
            {winrate.map((item, i) => (
              <div key={i} className="read">
                <ListItem>
                  <ListItemText
                    primary={
                      <span className="text-primary">{item.date_record}</span>
                    }
                    secondary={
                      <span className="text-secondary">
                        Win: {item.tp} / Loss: {item.sl}
                      </span>
                    }
                  />
                  <ListItemSecondaryAction>
                    <span className="text-winrate">{item.per_tp} %</span>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider className="divider" variant="middle" />
              </div>
            ))}
          </List>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="shopping">
          {renderAppBar()}
          {renderItem()}
          {renderLoading()}
          {notify}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Product);
