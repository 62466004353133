import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import IconButton from "@material-ui/core/IconButton";

// core components
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";
import "views/Style/ProfileStyle.css";

const useStyles = makeStyles(styles);

const initAttr = {
  busd: "",
};

const initError = {
  busd: false,
};

function ProfileBusd({ t }) {
  const mod = "profile";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const [alert, setAlert] = useState(null);
  const [attr, setAttr] = useState(initAttr);
  const [error, setError] = useState(initError);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.member);
      } else {
        Func.exitApp();
      }
      setLoading(false);
    });
  };

  const checkEmpty = () => {
    let result = true;

    if (!Func.verifyLength(attr.busd, 5)) result = false;

    return result;
  };

  const changeBusd = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "changebusd", {
              token: app.token,
              lang: app.lang,
              busd: attr.busd,
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                showNotify(t(result.msg), "primary");
              } else if (result.status === "ALERT") {
                showNotify(t(result.msg), "danger");
              } else {
                window.location.replace(`/home`)
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")}
        </SweetAlert>
      );

    }
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="bc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderBUSD = () => {
    return (
      <div className="box-pass">
        <Grid container>
          <Grid item xs={12}>
            <h4 className="title">
              <i className="fas fa-edit" /> BUSD
            </h4>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="busd"
              fullWidth
              helperText={
                error.password ? (
                  <>
                    <i className="fas fa-exclamation-triangle" /> BUSD (BEP20)
                  </>
                ) : null
              }
              InputProps={{
                type: "text",
                className: "text-pass",
                value: attr.busd,
                onChange: (e) => {
                  setAttr({ ...attr, busd: e.target.value });
                  setError({
                    ...error,
                    busd: !Func.verifyLength(e.target.value, 5),
                  });
                },
                startAdornment: (
                  <InputAdornment position="start">
                    BEP20 :
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <VpnKeyIcon />
                  </InputAdornment>
                ),
                autoComplete: "off",
                error: error.busd,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              block
              className="btn-submit"
              onClick={changeBusd}
            >
              <i className="fas fa-save"></i>
              {t(`button.save`)}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={"/profile"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">BUSD</h3>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="profile">
          {renderAppBar()}
          {renderBUSD()}
          {renderLoading()}
          {notify}
          {alert}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(ProfileBusd);
