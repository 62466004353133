import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Badge from "components/Badge/Badge.js";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Grid from "@material-ui/core/Grid";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";

import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";
import "views/Style/DepositStyle.css";

const useStyles = makeStyles(styles);

const initAttr = {
  payment: "BUSD",
  chain: "BSC",
  cooldown: 0,
  timelimit: 0,
  active: "N",
  amount: "0.00",
  secretkey: "",
};

function Receive({ t }) {
  const mod = "receive";
  const classes = useStyles();
  const history = useHistory();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [attr, setAttr] = useState(initAttr);
  const [notify, setNotify] = useState(null);
  const [member, setMember] = useState({});
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    if (id === undefined) init();
    else load();
  }, [id]);

  const init = () => {
    setLoading(true);

    setAttr(initAttr);
    setList([]);
    setMember({});

    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setMember(result.member);
        setList(result.history);
      } else {
        history.push("/home");
      }
      setLoading(false);
    });
  };

  const load = () => {
    setLoading(true);

    setAttr(initAttr);
    setList([]);
    setMember({});

    PostData(mod, "load", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);
        setMember(result.member);
      } else {
        history.push("/home");
      }
      setLoading(false);
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const requestTopup = () => {
    setLoading(true);
    PostData(mod, "request", {
      token: app.token,
      lang: app.lang,
      id: id,
      secretkey: attr.secretkey,
    }).then((result) => {
      if (result.status === "OK") {
        showNotify(t(result.msg), "success");
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } else if (result.status === "ALERT") {
        showNotify(t(result.msg), "danger");
      } else {
        history.push("/home");
      }
      setLoading(false);
    });
  };

  const renderBalance = () => {
    return (
      <div className="box-balance">
        <table width="100%" cellPadding={5} cellSpacing={0}>
          <tbody>
            <tr>
              <td className="icon">
                <i className="fas fa-wallet fa-2x" />
              </td>
              <td className="balance">
                {Func.numberDisplay(member.balance)} $
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderTopup = () => {
    return (
      <div className="box-item">
        <form>
          <GridContainer>
            <GridItem xs={12}>
              <TextField
                id="secretkey"
                fullWidth
                InputProps={{
                  type: "text",
                  className: "text-topup",
                  value: attr.secretkey,
                  onChange: (e) => {
                    setAttr({ ...attr, secretkey: e.target.value });
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>{t(`deposit.key`)} :</span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fad fa-lock" />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </GridItem>
          </GridContainer>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                block
                className="btn-price"
                size="sm"
                color="success"
                onClick={() => requestTopup()}
              >
                <i className="fas fa-comments-dollar" /> {t(`deposit.receive`)}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };

  const renderHistory = () => {
    return (
      <div className="box-data">
        <h4 className="history-title">
          <i className="fas fa-history" /> {t(`deposit.receivehistory`)}
        </h4>
        <List dense={true}>
          {list.map((item) => (
            <NavLink to={`/receive/${item.code}`} key={item.code}>
              <ListItem>
                <ListItemAvatar>
                  <i className="fad fa-envelope-open-dollar fa-2x color-default" />
                </ListItemAvatar>
                <ListItemText
                  primary={<>{Func.numberDisplay(item.amount)} $</>}
                  secondary={
                    <i className="text-secondary">
                      {Func.dateTimeDisplay(item.date_create)}
                    </i>
                  }
                />
                <ListItemSecondaryAction>
                  <Badge color={item.status_color}>
                    <i className={item.status_icon} /> {item.status_name}
                  </Badge>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider style={{ background: "#e2e2e2" }} variant="middle" />
            </NavLink>
          ))}
        </List>
      </div>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={id === undefined ? "/business" : "/receive"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              {id === undefined ? (
                <h3 className="header-title">{t(`menu.receive`)}</h3>
              ) : (
                <h3 className="header-title">ID : {attr.id}</h3>
              )}
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderStatus = () => {
    return (
      <h3 className={`item-status item-${attr.status_color}`}>
        <i className={attr.status_icon} /> {attr.status_name}
      </h3>
    );
  };

  const renderSummary = () => {
    return (
      <div className="box-item">
        <Grid container>
          <Grid item xs={6}>
            {t(`deposit.membersend`)}
          </Grid>
          <Grid item xs={6} className={classes.right}>
            {attr.member_name}
          </Grid>
          <Grid item xs={6}>
            {t(`deposit.amount`)}
          </Grid>
          <Grid item xs={6} className={classes.right}>
            {attr.amount} $
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderCountdown = () => {
    return (
      <div className="box-item">
        <div className="timer-wrapper">
          <CountdownCircleTimer
            isPlaying
            duration={attr.timelimit}
            initialRemainingTime={attr.cooldown}
            colors={["#52f20d", "#fbef0d", "#ff8000", "#A30000"]}
            colorsTime={[150, 100, 50, 0]}
            onComplete={() => {
              window.location.reload();
            }}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>
      </div>
    );
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">{t(`element.timeout`)}</div>;
    }

    var minutes = Math.floor(remainingTime / 60);
    var seconds = remainingTime - minutes * 60;

    return (
      <div className="timer">
        <div className="text">{t(`element.remaining`)}</div>
        <div className="value">
          {minutes}:{seconds.toString().padStart(2, "0")}
        </div>
        <div className="text">{t(`element.minutes`)}</div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <BottomNavigation
        onChange={(e, link) => {
          if (link === "cancel") history.push(`/${link}`);
          else if (link === "reload") window.location.reload();
        }}
        showLabels
        className={"bottom"}
      >
        <BottomNavigationAction
          label={
            <div className="menu">
              <i className="fad fa-times color-danger" /> {t(`button.cancel`)}
            </div>
          }
          value="cancel"
        />
        <BottomNavigationAction
          label={
            <div className="menu">
              <i className="fad fa-sync-alt color-success" /> {t(`button.reload`)}
            </div>
          }
          value="reload"
        />
      </BottomNavigation>
    );
  };

  const renderList = () => {
    return (
      <>
        {renderBalance()}
        {list.length > 0 ? renderHistory() : null}
      </>
    );
  };

  const renderLoad = () => {
    return (
      <>
        {renderBalance()}
        {renderStatus()}
        {renderSummary()}
        {attr.active === "Y" ? renderCountdown() : null}
        {attr.active === "Y" ? renderTopup() : null}
        {attr.active === "Y" ? renderFooter() : null}
      </>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="deposit">
          {renderAppBar()}
          {id === undefined ? renderList() : renderLoad()}
          {renderLoading()}
          {notify}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Receive);
