import { createStore, compose, applyMiddleware } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import thunk from "redux-thunk";

import AsyncStorage from "@react-native-community/async-storage";

import reducers from "./reducers";

const persistConfig = {
  key: "primary",
  storage: AsyncStorage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["appState", "user", "cart"],
};

const pReducer = persistReducer(persistConfig, reducers);
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  pReducer,
  composeEnhancer(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
