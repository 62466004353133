import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// @material-ui/core components
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { setSetup } from "redux/actions/AppStateAction";

// core components
import i18n from "components/Translation/Translate.js";
import Snackbar from "components/Snackbar/Snackbar.js";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";

import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import routes from "routes.js";
import styles from "assets/jss/main-style.js";
import "views/Style/AuthStyle.css";
import pic_logo from "assets/img/logo/logo.png";

const useStyles = makeStyles(styles);

function Pages({ setup, t }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const app = useSelector((state) => state.appState);

  const [value, setValue] = useState("signal");
  const [msg, setMsg] = useState("");
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    dispatch(setSetup(setup));
    document.body.style.overflow = "unset";

    return function cleanup() {};
  }, []);

  const countMessage = () => {
    PostData("message", "alert", {
      token: app.token,
      lang: app.lang,
      path: window.location.pathname,
    }).then((result) => {
      if (result.status === "OK") {
        setMsg(result.count_msg);

        if (result.alert_msg != "") {
          showNotify(t(`message.${result.alert_msg}`), "primary");
        }

        setTimeout(function () {
          if (checkPath()) {
            countMessage();
          }
        }, 60000);
      } else {
        Func.exitApp();
      }
    });
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      return <Route path={prop.path} component={prop.component} key={key} />;
    });
  };

  const getRoutes2 = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes2(prop.views);
      }
      return (
        <Route path={prop.path + "/:id"} component={prop.component} key={key} />
      );
    });
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "-";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const checkPath = () => {
    if (window.location.pathname.indexOf("/home") === 0) {
      return true;
    } else if (window.location.pathname.indexOf("/product") === 0) {
      return true;
    } else if (window.location.pathname.indexOf("/order") === 0) {
      return true;
    } else if (window.location.pathname.indexOf("/robot") === 0) {
      return true;
    } else if (window.location.pathname.indexOf("/roi") === 0) {
      return true;
    } else {
      return false;
    }
  };

  const renderFooter = () => {
    return checkPath() ? (
      <BottomNavigation
        value={value}
        onChange={(e, link) => {
          history.push(`/${link}`);
          setValue(link);
        }}
        showLabels
        className={"footer"}
      >
        <BottomNavigationAction
          label={<div className="menu">{t(`menu.product`)}</div>}
          value="product"
          icon={<i className="fad fa-shopping-bag icon" />}
        />
        <BottomNavigationAction
          label={<div className="menu">{t(`menu.order`)}</div>}
          value="order"
          icon={<i className="fad fa-list-alt icon" />}
        />
        <BottomNavigationAction
          label={
            <Paper elevation={0} className="menu">
              <img src={pic_logo} className="home" />
            </Paper>
          }
          value="home"
        />
        <BottomNavigationAction
          label={<div className="menu">{t(`menu.myitem`)}</div>}
          value="robot"
          icon={<i className="fad fa-robot icon" />}
        />
        <BottomNavigationAction
          label={<div className="menu">{t(`menu.roi`)}</div>}
          value="roi"
          icon={<i className="fad fa-analytics icon" />}
        />
      </BottomNavigation>
    ) : null;
  };

  return (
    <div>
      <Switch>
        {getRoutes2(routes)}
        {getRoutes(routes)}
        <Redirect from="/" to="/authen" />
      </Switch>
      {renderFooter()}
      {notify}
      {alert}
    </div>
  );
}
export default withNamespaces()(Pages);
