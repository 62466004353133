import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import CheckCircle from "@material-ui/icons/CheckCircle";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";
import "views/Style/ShoppingStyle.css";

const useStyles = makeStyles(styles);

const initAttr = {
  payment: "QR",
  total: "",
  discount: "",
  vat: "",
  nettotal: "",
  coupon: "",
};

const initMember = {
  com_name: "",
  com_tel: "",
  com_id: "",
  com_email: "",
  address: "",
};

function CheckOut({ t }) {
  const mod = "cart";
  const classes = useStyles();
  const history = useHistory();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [attr, setAttr] = useState(initAttr);
  const [member, setMember] = useState(initMember);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [condition, setCondition] = useState(false);
  const [receipt, setReceipt] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr({ ...result.cart, payment: "QR", coupon: "" });
        setMember(result.member);
        setList(result.list);
      }
      setLoading(false);
    });
  };

  const confirmPayment = () => {
    if (attr.payment === "COUPON" && attr.nettotal > 0) {
      showNotify("จำนวนคูปองไม่เพียงพอ", "danger");
      return;
    }
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("shopping.confirm_checkout")}
        onConfirm={() => {
          setLoading(true);
          setAlert(null);
          PostData(mod, "paymentrequest", {
            token: app.token,
            lang: app.lang,
            payment: attr.payment,
            coupon: attr.coupon,
            receipt: receipt ? "Y" : "N",
            com_name: receipt ? member.com_name : "",
            com_tel: receipt ? member.com_tel : "",
            com_id: receipt ? member.com_id : "",
            com_email: receipt ? member.com_email : "",
            address: receipt ? member.address : "",
          }).then((result) => {
            if (result.status === "OK") {
              showNotify(t(result.msg), "primary");
              setTimeout(function () {
                window.location.replace(result.url);
              }, 1000);
            } else {
              setLoading(false);
              showNotify(t(result.msg), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      ></SweetAlert>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="bc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const findCoupon = () => {
    if (attr.coupon === "") return;

    setLoading(true);
    PostData(mod, "coupon", {
      token: app.token,
      lang: app.lang,
      coupon: attr.coupon,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr({
          ...attr,
          discount: result.data.discount,
          nettotal: result.data.nettotal,
        });

        showNotify(t(result.msg), "primary");
      } else {
        setAttr({
          ...attr,
          discount: 0,
          nettotal: attr.total,
        });

        showNotify(result.msg, "danger");
      }
      setLoading(false);
    });
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={"/cart"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">{t(`menu.checkout`)}</h3>
            </Grid>
            <Grid item xs={2} className="cart"></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderList = () => {
    return (
      <div className="box-item">
        <table width="100%" cellPadding={3} cellSpacing={0} border="0">
          <tbody>
            {list.map((item, i) => (
              <tr key={i}>
                <td width="10%">
                  <img src={item.filepic} className="item-img" />
                </td>
                <td className="item-topic">
                  {item.product_name} [{item.price_name}]
                </td>
                <td width="30%" className="item-amount">
                  {Func.numberDisplay(item.price, 2)} ฿
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderSummary = () => {
    return (
      <div className="box-item">
        <table width="100%" cellPadding={3} cellSpacing={0} border="0">
          <tbody>
            <tr>
              <td className="item-sum">{t(`shopping.amount`)}</td>
              <td width="40%" className="item-amount">
                {Func.numberDisplay(attr.amount, 2)} ฿
              </td>
            </tr>
            <tr>
              <td className="item-sum">{t(`shopping.vat`)}</td>
              <td className="item-amount">
                {Func.numberDisplay(attr.vat, 2)} ฿
              </td>
            </tr>
            {attr.payment === "COUPON" ? (
              <tr>
                <td className="item-sum">{t(`shopping.discount`)}</td>
                <td className="item-amount">
                  {Func.numberDisplay(attr.discount, 2)} ฿
                </td>
              </tr>
            ) : null}
            {attr.payment === "COUPON" ? (
              <tr>
                <td className="item-sum">{t(`shopping.nettotal`)}</td>
                <td className="item-amount">
                  {Func.numberDisplay(attr.nettotal, 2)} ฿
                </td>
              </tr>
            ) : (
              <tr>
                <td className="item-sum">{t(`shopping.nettotal`)}</td>
                <td className="item-amount">
                  {Func.numberDisplay(attr.total, 2)} ฿
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const renderPayment = () => {
    return (
      <div className="box-item">
        <h5>{t(`shopping.payment`)}</h5>
        <div
          className={
            classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
          }
        >
          <FormControlLabel
            control={
              <Radio
                checked={attr.payment === "QR"}
                onChange={(e) =>
                  setAttr({
                    ...attr,
                    payment: e.target.value,
                  })
                }
                value="QR"
                name="rdoPaymenType"
                aria-label="QR"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label={<span className="color-dark">Scan QR code</span>}
          />
        </div>
        <div
          className={
            classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
          }
        >
          <FormControlLabel
            control={
              <Radio
                checked={attr.payment === "COUPON"}
                onChange={(e) =>
                  setAttr({
                    ...attr,
                    payment: e.target.value,
                  })
                }
                value="COUPON"
                name="rdoPaymenType"
                aria-label="COUPON"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label={
              attr.payment === "COUPON" ? (
                renderCoupon()
              ) : (
                <span className="color-dark">คูปอง</span>
              )
            }
          />
        </div>
      </div>
    );
  };

  const renderAccept = () => {
    return (
      <div className="box-item">
        <h5>{t(`shopping.condition`)}</h5>
        <div className="agree">
          <FormControlLabel
            classes={{
              root: classes.checkboxLabelControl,
              label: classes.checkboxLabel,
            }}
            control={
              <Checkbox
                checked={condition}
                tabIndex={-1}
                onClick={(e) => setCondition(e.target.checked)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={
              <span>
                <span className="color-dark">{t(`shopping.iagree`)}</span>{" "}
                <Button
                  onClick={() => {
                    setModal(true);
                  }}
                  size="sm"
                  simple
                  color="danger"
                  className="text-rule"
                >
                  {t(`shopping.condition`)}
                </Button>
              </span>
            }
          />
        </div>
      </div>
    );
  };

  const renderReceipt = () => {
    return (
      <div className="box-item">
        <h5>{t(`shopping.receipt`)}</h5>
        <div className="agree">
          <FormControlLabel
            classes={{
              root: classes.checkboxLabelControl,
              label: classes.checkboxLabel,
            }}
            control={
              <Checkbox
                checked={receipt}
                tabIndex={-1}
                onClick={(e) => setReceipt(e.target.checked)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={
              <span className="color-dark">{t(`shopping.needreceipt`)}</span>
            }
          />
        </div>
        {receipt ? (
          <div>
            <form>
              <GridContainer>
                <GridItem xs={12}>
                  <TextField
                    id="com_name"
                    fullWidth
                    InputProps={{
                      type: "text",
                      value: member.com_name,
                      onChange: (e) => {
                        setMember({ ...member, com_name: e.target.value });
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>{t(`shopping.com_name`)} :</span>
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextField
                    id="com_tel"
                    fullWidth
                    InputProps={{
                      type: "number",
                      value: member.com_tel,
                      onChange: (e) => {
                        setMember({ ...member, com_tel: e.target.value });
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>{t(`shopping.com_tel`)} :</span>
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextField
                    id="com_id"
                    fullWidth
                    InputProps={{
                      type: "number",
                      value: member.com_id,
                      onChange: (e) => {
                        setMember({ ...member, com_id: e.target.value });
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>{t(`shopping.com_id`)} :</span>
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextField
                    id="com_email"
                    fullWidth
                    InputProps={{
                      type: "text",
                      value: member.com_email,
                      onChange: (e) => {
                        setMember({ ...member, com_email: e.target.value });
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>{t(`shopping.com_email`)} :</span>
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextField
                    id="address"
                    fullWidth
                    InputProps={{
                      type: "text",
                      value: member.address,
                      onChange: (e) => {
                        setMember({ ...member, address: e.target.value });
                      },
                      multiline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>{t(`shopping.address`)} :</span>
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </GridItem>
              </GridContainer>
            </form>
          </div>
        ) : null}
      </div>
    );
  };

  const renderCoupon = () => {
    return (
      <form>
        <GridContainer>
          <GridItem xs={12}>
            <TextField
              id="coupon"
              fullWidth
              InputProps={{
                value: attr.coupon,
                onChange: (e) => {
                  setAttr({ ...attr, coupon: e.target.value });
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <span>คูปอง :</span>
                  </InputAdornment>
                ),
                endAdornment: (
                  <Button color="primary" size="sm" round onClick={findCoupon}>
                    <CheckCircle />
                    ตรวจสอบ
                  </Button>
                ),
                autoComplete: "off",
              }}
            />
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderFooter = () => {
    return (
      <BottomNavigation
        onChange={(e, link) => {
          if (link === "cart") history.push(`/${link}`);
          else if (link === "confirm") confirmPayment();
        }}
        showLabels
        className={"bottom"}
      >
        <BottomNavigationAction
          label={
            <div className="menu">
              <i className="fad fa-arrow-alt-circle-left color-danger" />{" "}
              {t(`button.back`)}
            </div>
          }
          value="cart"
        />
        <BottomNavigationAction
          label={
            <div className="menu">
              {t(`shopping.confirm`)}{" "}
              <i className="fad fa-check-circle color-success" />
            </div>
          }
          value="confirm"
          disabled={!condition}
        />
      </BottomNavigation>
    );
  };

  const renderModal = () => {
    return (
      <Dialog
        classes={{
          paper: classes.modal,
        }}
        open={modal}
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{t(`shopping.condition`)}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
          style={{ overflowY: "scroll", height: "300px" }}
        >
          <ol>
            <li>
              โปรแกรมที่จัดสร้างขึ้นเป็นเครื่องมือเพื่อช่วยอำนวยความสะดวกเท่านั้น
              ระบบไม่มีผลต่อการสร้างผลกำไร หรือขาดทุนจากลูกค้าใด ๆ ทั้งสิ้น
            </li>

            <li>
              ระบบจะไม่รับผิดชอบในการสูญเสีย
              หรือขาดทุนจากการลงทุนด้วยสัญญาณที่แจ้งการลงทุน
            </li>

            <li>
              ระบบจะไม่รับผิดชอบในการใช้เครื่องมือ หรือโปรแกรมในการทำกำไรใด ๆ
              ทั้งสิ้น
            </li>

            <li>
              การลงทุนมีความเสี่ยง
              ผู้ลงทุนจะต้องรับความเสี่ยงในการลงทุนของตนเองเท่านั้น
            </li>
          </ol>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setModal(false)} color="success">
            {t("shopping.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="shopping">
          {renderAppBar()}
          {renderList()}
          {renderSummary()}
          {renderPayment()}
          {attr.payment === "QR" ? renderReceipt() : null}
          {renderAccept()}
          {renderFooter()}
          {renderModal()}
          {renderLoading()}
          {alert}
          {notify}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(CheckOut);
