import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { usePageName } from "react-page-name";
import Slider from "react-slick";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
// import Badge from "components/Badge/Badge.js";
import Badge from "@material-ui/core/Badge";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// @material-ui/icons
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MailIcon from "@material-ui/icons/Mail";

// core components
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import pic_home from "assets/img/cover/home.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "views/Style/RobotStyle.css";

const useStyles = makeStyles(styles);

function RobotList({ t }) {
  const mod = "home";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [attr, setAttr] = useState({});
  const [list, setList] = useState([]);
  const [slideshow, setSlideshow] = useState([]);
  const [monitor, setMonitor] = useState([]);
  const [loading, setLoading] = useState(false);

  usePageName(t(`menu.monitor`));

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    if (id === undefined) init();
    else loadMonitor();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setSlideshow(result.slideshow);
      }
      setLoading(false);
    });
  };

  const loadMonitor = () => {
    setLoading(true);
    PostData(mod, "load", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.trick);

        result.monitor.map((item, i) => {
          setMonitor((monitor) => [
            ...monitor,
            [
              <span className="symbol">{item.symbol}</span>,
              displayOption(item.tf_m5),
              displayOption(item.tf_m15),
              displayOption(item.tf_m30),
              displayOption(item.tf_h1),
              displayOption(item.tf_h4),
              displayOption(item.tf_d1),
              displayOption(item.tf_w1),
            ],
          ]);
        });
      }
      setLoading(false);
    });
  };

  const displayOption = (inp) => {
    if (inp === "B")
      return (
        <code className="color-success">
          <i className="fas fa-chart-line" />
        </code>
      );
    else if (inp === "S")
      return (
        <code className="color-danger">
          <i className="fad fa-chart-line-down" />
        </code>
      );
    else return <span>-</span>;
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={"/home"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">Robot</h3>
            </Grid>
            <Grid item xs={2} className="cart">
              <NavLink to={"/cart"}>
                <IconButton aria-label="">
                  <Badge badgeContent={"1"} color="secondary" className="badge">
                    <i className="fas fa-shopping-cart toolbar-icon" />
                  </Badge>
                </IconButton>
              </NavLink>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderList = () => {
    return slideshow.map((item) => (
      <div key={item.code} className="box-item">
        <NavLink to={`/robot/${item.code}`}>
          <table width="100%" cellPadding={3} cellSpacing={0} border="0">
            <tbody>
              <tr>
                <td colSpan={2}>
                  <h3 className="item-run">
                    <i className="fas fa-running fa-fw" /> RUNNING ...{" "}
                  </h3>
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <img
                    src="https://octatrade.me/img/product/lifestyle.jpg"
                    className="item-img"
                  />
                </td>
                <td valign="top">
                  <h3 className="item-price">ID : 1995</h3>
                  <p className="item-pv">Robot : Moon auto</p>
                  <p className="item-expire">Exp. : 02/05/2022</p>
                </td>
              </tr>
            </tbody>
          </table>
        </NavLink>
      </div>
    ));
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="robot">
          {renderAppBar()}
          {renderList()}
          {renderLoading()}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(RobotList);
