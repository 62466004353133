import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Grid from "@material-ui/core/Grid";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Accordion from "components/Accordion/Accordion.js";

import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";

import { PostData } from "../../services/Request";
import * as Func from "../../services/Function";
import styles from "assets/jss/main-style.js";

const useStyles = makeStyles(styles);

const initAdmin = {
  contact_line: "",
  contact_email: "",
  contact_tel: "",
};

function Contact({ t }) {
  const mod = "contact";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const [admin, setAdmin] = useState(initAdmin);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setAdmin(result.admin);
      } else {
        Func.exitApp();
      }
    });
  };

  const renderAdmin = () => {
    return (
      <Grid container>
        {admin.contact_line === "" ? null : (
          <Grid item xs={12}>
            <Card className="box">
              <CardBody>
                <table cellPadding="5">
                  <tbody>
                    <tr>
                      <td width="90" align="center">
                        <i
                          className="fab fa-line fa-3x"
                          style={{ color: "#00b700" }}
                        ></i>
                      </td>
                      <td>
                        <div className="title">{t(`${mod}.lineid`)}</div>
                        <div>
                          <a
                            href={`https://line.me/ti/p/~${admin.contact_line}`}
                            target="_blank"
                            className="label"
                          >
                            {admin.contact_line}
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Grid>
        )}
        {admin.contact_email === "" ? null : (
          <Grid item xs={12}>
            <Card className="box">
              <CardBody>
                <table cellPadding="5">
                  <tbody>
                    <tr>
                      <td width="90" align="center">
                        <i
                          className="fas fa-envelope fa-3x"
                          style={{ color: "#0080ff" }}
                        ></i>
                      </td>
                      <td>
                        <div className="title">{t(`${mod}.email`)}</div>
                        <div>
                          <a
                            href={`mailto:${admin.contact_line}`}
                            target="_blank"
                            className="label"
                          >
                            {admin.contact_email}
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Grid>
        )}
        {admin.contact_tel === "" ? null : (
          <Grid item xs={12}>
            <Card className="box">
              <CardBody>
                <table cellPadding="5">
                  <tbody>
                    <tr>
                      <td width="90" align="center">
                        <i
                          className="fas fa-phone-volume fa-3x"
                          style={{ color: "#FF8000" }}
                        ></i>
                      </td>
                      <td>
                        <div className="title">{t(`${mod}.tel`)}</div>
                        <div>
                          <a
                            href={`tel:${admin.contact_tel}`}
                            target="_blank"
                            className="label"
                          >
                            {admin.contact_tel}
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <div className="contact">
      <AuthNavbar mod={mod} />
      <div className={classes.container}>{renderAdmin()}</div>
    </div>
  );
}

export default withNamespaces()(Contact);
