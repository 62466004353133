import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";

// core components
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import * as Func from "services/Function";
import "views/Style/SettingStyle.css";

const useStyles = makeStyles(styles);

function RobotRenew({ t }) {
  const mod = "robot";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const history = useHistory();
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "initrenew", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setList(result.list);
      }
      setLoading(false);
    });
  };

  const addToCart = (item) => {
    setLoading(true);
    PostData(mod, "addtocart", {
      token: app.token,
      lang: app.lang,
      robot: id,
      code: item.code,
    }).then((result) => {
      if (result.status === "OK") {
        showNotify(t(result.msg), "primary");
        setTimeout(function () {
          window.location.replace(`/cart`);
        }, 100);
      } else if (result.status === "ALERT") {
        showNotify(t(result.msg), "danger");
      } else {
        // Func.exitApp();
      }
      setLoading(false);
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="bc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={`/robot/${id}`}>
                <IconButton>
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">{t(`robot.robotrenew`)}</h3>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderList = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <List dense={true}>
            {list.map((item, i) => (
              <div key={i} className="read">
                <ListItem button onClick={() => addToCart(item)}>
                  <ListItemAvatar>
                    <Avatar>
                      <img src={item.filepic} width="50" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <span className="text-primary">{item.price_name}</span>
                    }
                    secondary={
                      <span className="text-primary">
                        <i>{item.price} ฿</i>
                      </span>
                    }
                  />
                  <ListItemSecondaryAction>
                    <i className="fas fa-angle-right" />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider variant="middle" />
              </div>
            ))}
          </List>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="robot">
          {renderAppBar()}
          {renderList()}
          {renderLoading()}
          {notify}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(RobotRenew);
