import {
  container,
  defaultFont,
  cardTitle,
  roseColor,
  primaryColor,
  dangerColor,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import authNavbarStyle from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

const mainStyle = (theme) => ({
  ...notificationsStyle,
  ...dashboardStyle,
  ...authNavbarStyle,
  ...buttonStyle,
  ...modalStyle(theme),
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      // paddingBottom: "100px",
      fontSize: "1.2em",
    },
  },
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    // position: "absolute",
    width: "100%",
    paddingTop: "10px",
    zIndex: "1029",
    color: grayColor[6],
    border: "0",
    borderRadius: "3px",
    padding: "0",
    transition: "all 150ms ease 0s",
    // minHeight: "50px",
    display: "block",
  },
  title: {
    ...defaultFont,
    color: whiteColor,
    marginTop: "5vh",
    marginBottom: "30px",
    textAlign: "center",
  },
  description: {
    fontSize: "18px",
    color: whiteColor,
    textAlign: "center",
  },
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + " !important",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  cardCategoryWhite: {
    color: whiteColor,
    marginTop: "10px",
  },
  label: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    transition: "0.3s ease all",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingLeft: "0",
  },
  icon: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid " + grayColor[3],
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  iconWhite: {
    color: whiteColor,
  },
  iconRose: {
    color: roseColor[0],
  },
  iconDanger: {
    color: dangerColor[4],
  },
  width100: {
    width: "100%",
  },
  marginTop30: {
    marginTop: "30px",
  },
  noPadding: {
    padding: "0px !important",
  },
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid rgba(255, 255, 255, .54)",
    borderRadius: "3px",
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid rgba(255, 255, 255, .54)",
    borderRadius: "3px",
  },
});

export default mainStyle;
