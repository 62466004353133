import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { usePageName } from "react-page-name";
import { Tree, TreeNode } from "react-organizational-chart";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

// @material-ui/icons
import CloseIcon from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import "views/Style/AffiliateStyle.css";

const useStyles = makeStyles(styles);

function Binary({ t }) {
  const mod = "binary";
  const prefix = "binary";
  const classes = useStyles();
  const history = useHistory();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [attr, setAttr] = useState({});
  const [member, setMember] = useState({});
  const [downline, setDownline] = useState([]);
  const [upline, setUpline] = useState("");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  usePageName(t(`menu.product`));

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setMember(result.member);
        setDownline(result.downline);
        setUpline(result.upline);
      }
      setLoading(false);
    });
  };

  const goTop = () => {
    history.push("/binary");
  };

  const goLeft = () => {
    PostData(mod, "goleftright", {
      id: id,
      side: "L",
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        history.push("/binary/" + result.member.code);
      }
    });
  };

  const goRight = () => {
    PostData(mod, "goleftright", {
      id: id,
      side: "R",
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        history.push("/binary/" + result.member.code);
      }
    });
  };

  const goHead = () => {
    if (upline === member.code) {
      history.push("/binary");
    } else {
      history.push("/binary/" + upline);
    }
  };

  const openLink = () => {
    history.push("/binary/" + attr.code);
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={id === undefined ? "/business" : "/binary"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">
                {member.name}
              </h3>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderInfo = () => {
    return (
      <Dialog
        open={modal}
        maxWidth={"sm"}
        keepMounted
        fullWidth
        disableBackdropClick
        onClose={() => setModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            color="white"
            round
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="close-modal"
            onClick={() => setModal(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{attr.name}</h4>
        </DialogTitle>

        <DialogContent className={classes.modalBody}>
          <Card style={{ marginTop: "0" }}>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>{t(`${prefix}.leftpointstart`)}</h4>
                </GridItem>
                <GridItem xs={6}>
                  <h4>{attr.PointL}</h4>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>{t(`${prefix}.rightpointstart`)}</h4>
                </GridItem>
                <GridItem xs={6}>
                  <h4>{attr.PointR}</h4>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>{t(`${prefix}.leftpointnew`)}</h4>
                </GridItem>
                <GridItem xs={6}>
                  <h4>{attr.NewL}</h4>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>{t(`${prefix}.rightpointnew`)}</h4>
                </GridItem>
                <GridItem xs={6}>
                  <h4>{attr.NewR}</h4>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>{t(`${prefix}.pointsponsor`)}</h4>
                </GridItem>
                <GridItem xs={6}>
                  <h4>{attr.BonusB}</h4>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>{t(`${prefix}.bonusbinary`)}</h4>
                </GridItem>
                <GridItem xs={6}>
                  <h4>{attr.BonusA}</h4>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>{t(`${prefix}.bonusmatching`)}</h4>
                </GridItem>
                <GridItem xs={6}>
                  <h4>{attr.BonusMatching}</h4>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>{t(`${prefix}.bonussponsor`)}</h4>
                </GridItem>
                <GridItem xs={6}>
                  <h4>{attr.BonusBreak}</h4>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>{t(`${prefix}.datecompile`)}</h4>
                </GridItem>
                <GridItem xs={6}>
                  <h4>{attr.TURN}</h4>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>{t(`${prefix}.position`)}</h4>
                </GridItem>
                <GridItem xs={6}>
                  <h4>{attr.Position}</h4>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>{t(`${prefix}.status`)}</h4>
                </GridItem>
                <GridItem xs={6}>
                  <h4>{attr.Status}</h4>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={openLink} color="success">
            {t(`${prefix}.opennetwork`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderBinary = () => {
    return (
      <Card>
        <CardBody className="binary">
          <Tree
            lineWidth={"1px"}
            lineColor={"#cccccc"}
            lineBorderRadius={"5px"}
            label={
              <div className="memtext">
                <img src={member.filepic} className="avatar" />
                <br />
                {member.name}
              </div>
            }
          >
            {downline &&
              downline.map((item) => (
                <TreeNode
                  label={
                    item.type === "REGISTER" ? <img src={item.image} className="avatar" /> :
                      <NavLink to={`/binary/${item.code}`} className="memtext">
                        <img src={item.image} className="avatar" />
                        <br />
                        {item.name}
                      </NavLink>
                  }
                  key={item.code}
                >
                  {item.child
                    ? item.child.map((item2) => (
                      <TreeNode
                        label={
                          item2.type === "REGISTER" ? <img src={item2.image} className="avatar" /> :
                            <NavLink to={`/binary/${item2.code}`} className="memtext">
                              <img src={item2.image} className="avatar" />
                              <br />
                              {item2.firstname}
                            </NavLink>
                        }
                        key={item2.code}
                      ></TreeNode>
                    ))
                    : null}
                </TreeNode>
              ))}
          </Tree>
        </CardBody>
      </Card>
    );
  };

  const renderFooter = () => {
    return (
      <BottomNavigation
        onChange={(e, link) => {
          switch (link) {
            case "left": goLeft(); break;
            case "top": goTop(); break;
            case "right": goRight(); break;
          }
        }}
        showLabels
        className={"bottom"}
      >
        <BottomNavigationAction
          label={
            <div className="menu">
              <i className="fas fa-arrow-circle-left color-primary" /> Left
            </div>
          }
          value="left"
        />
        <BottomNavigationAction
          label={
            <div className="menu">
              <i className="fas fa-arrow-circle-up color-primary" /> Top
            </div>
          }
          value="top"
        />
        <BottomNavigationAction
          label={
            <div className="menu">
              Right{" "}
              <i className="fas fa-arrow-circle-right color-primary" />
            </div>
          }
          value="right"
        />
      </BottomNavigation>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="affiliate">
          {renderAppBar()}
          {renderBinary()}
          {renderInfo()}
          {renderFooter()}
          {renderLoading()}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Binary);
