import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import { useSelector } from "react-redux";
// import {i18n as i18} from 'components/Translation/Translate.js';

import translationEN from "./en-US.json";
import translationTH from "./th-TH.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  th: {
    translation: translationTH,
  },
};

i18n
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: useSelector,
    lng: "en",
    fallbackLng: "en",

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
