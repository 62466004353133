import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { usePageName } from "react-page-name";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "components/Badge/Badge.js";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// @material-ui/icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";

const useStyles = makeStyles(styles);

function Signal({ t }) {
  const mod = "signal";
  const classes = useStyles();
  const history = useHistory();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [member, setMember] = useState({});
  const [list, setList] = useState([]);
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    if (id === undefined) init();
    else loadProduct();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setList(result.list);
      }
      setLoading(false);
    });
  };

  const loadProduct = () => {
    setLoading(true);
    PostData(mod, "load", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setProduct(result.product);
      }
      setLoading(false);
    });
  };

  const renderList = () => {
    return (
      <Card className="card">
        <CardBody className="card-body">
          <List dense={false}>
            {list.map((item) => (
              <div key={item.code}>
                <NavLink to={`/signal/${item.code}`}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img src={item.filepic} width="50" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <span className="item">{item.product_name}</span>
                      }
                      secondary={
                        <i className="text-secondary">
                          {item.master_name} [Exp. {item.date_expire}]
                        </i>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        className="status"
                      >
                        <Badge>
                          <i className="chevron fas fa-chevron-right fa-fw" />
                        </Badge>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider className="divider" variant="middle" />
                </NavLink>
              </div>
            ))}
          </List>
        </CardBody>
      </Card>
    );
  };

  const request = () => {
    setLoading(true);
    PostData(mod, "request", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        window.location.replace(`/order/${result.code}`);
      } else {
      }
      setLoading(false);
    });
  };

  const renderProduct = () => {
    return (
      <Card style={{ margin: "0" }}>
        <CardBody className="historys">
          <Grid container>
            <Grid item xs={12}>
              <img src={product.filepic} style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={6}>
              {product.product_name}
            </Grid>
            <Grid item xs={6}>
              {product.master_name}
            </Grid>
            <Grid item xs={6}>
              {product.price}
            </Grid>
            <Grid item xs={6}>
              {product.service_life}
            </Grid>
            <Grid item xs={12}>
              <Button
                color="success"
                className="btn-submit"
                onClick={request}
                block
              >
                <i className="fas fa-paper-plane"></i> ต่ออายุ
              </Button>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.container}>
          <Grid container>
            <Grid item xs={2}>
              {id === undefined ? null : (
                <NavLink to="/signal">
                  <IconButton className="appbar-icon" aria-label="">
                    <ArrowBackIcon />
                  </IconButton>
                </NavLink>
              )}
            </Grid>
            <Grid item xs={8} style={{ textAlign: "center" }}>
              <h4 className="appbar-title">
                {id === undefined
                  ? i18n.t(`menu.signal`)
                  : product.product_name}
              </h4>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  return (
    <div className="block">
      {renderAppBar()}
      <div className={classes.container}>
        {id === undefined ? renderList() : renderProduct()}
        {renderLoading()}
      </div>
    </div>
  );
}

export default withNamespaces()(Signal);
