import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import copy from "copy-to-clipboard";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import Button from "components/CustomButtons/Button.js";
import * as Func from "services/Function";
import "views/Style/AffiliateStyle.css";

const useStyles = makeStyles(styles);

const initAttr = {
  secretkey: "",
  urlpromote: "",
  linkpromote: "",
  lineliff: "",
  linelink: "",
  lineid: "",
  linechat: "",
};

function Affiliate({ t }) {
  const mod = "profile";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const [attr, setAttr] = useState(initAttr);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    setLoading(true);
    PostData(mod, "loadprofile", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.member);
      }
      setLoading(false);
    });
  };

  const checkEmpty = () => {
    let result = true;

    if (!Func.verifyLength(attr.secretkey, 5)) result = false;

    return result;
  };

  const changeKey = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setLoading(true);

      PostData(mod, "changekey", {
        token: app.token,
        lang: app.lang,
        secretkey: attr.secretkey,
      }).then((result) => {
        setLoading(false);

        if (result.status === "OK") {
          showNotify(t(`${result.msg}`), "primary");
        } else if (result.status === "ALERT") {
          showNotify(t(`${result.msg}`), "danger");
        } else {
          Func.exitApp();
        }
      });
    }
  };

  const copyText = (text) => {
    copy(text);
    showNotify(t(`alert.copysuccess`), "primary");
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="bc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={"/home"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">{t(`menu.affiliate`)}</h3>
            </Grid>
            <Grid item xs={2} className="cart"></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderItem = () => {
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <div className="box-item-right">
              <table width="100%" cellPadding={3} cellSpacing={0} border="0">
                <tbody>
                  <tr>
                    <td width="50%">
                      <h3 className="item-left">
                        <i className="fas fa-chevron-circle-right" /> {t(`affiliate.linkpromote`)}
                      </h3>
                    </td>
                  </tr>
                  <tr>
                    <td >
                      <Button
                        type="button"
                        block
                        color="success"
                        size="sm"
                        className="btn-copy"
                        onClick={() => copyText(attr.linkpromote)}
                      >
                        <i className="fal fa-copy" /> {t(`affiliate.copy`)}
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="box-bg">
        <GridContainer className="form">
          <GridItem xs={12} className="form-input">
            <TextField
              fullWidth
              InputProps={{
                type: "text",
                className: "text",
                value: attr.secretkey,
                onChange: (e) => {
                  setAttr({ ...attr, secretkey: e.target.value });
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="text-title">{t(`affiliate.id`)} : </span>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="button"
                      className="form-save"
                      onClick={changeKey}
                    >
                      <i className="fas fa-save" />
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
            />
          </GridItem>
          <GridItem xs={12} className="form-input">
            <TextField
              fullWidth
              InputProps={{
                type: "text",
                className: "text-mini",
                value: attr.urlpromote + attr.secretkey,
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="text-title">Url : </span>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="button"
                      className="form-icon"
                      onClick={() => copyText(attr.urlpromote + attr.secretkey)}
                    >
                      <i className="fal fa-copy" />
                    </IconButton>
                  </InputAdornment>
                ),
                disabled: true,
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  const renderLine = () => {
    return (
      <div className="box-bg">
        <GridContainer className="form">
          <GridItem xs={12} className="form-input">
            <TextField
              fullWidth
              InputProps={{
                type: "text",
                className: "text-mini",
                value: attr.lineid,
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="text-title">Add Line : </span>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="button"
                      className="form-icon"
                      onClick={() => copyText(attr.lineid)}
                    >
                      <i className="fal fa-copy" />
                    </IconButton>
                  </InputAdornment>
                ),
                disabled: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} className="form-input">
            <TextField
              fullWidth
              InputProps={{
                type: "text",
                className: "text-mini",
                value: attr.linelink,
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="text-title">Line OA : </span>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="button"
                      className="form-icon"
                      onClick={() => copyText(attr.linelink)}
                    >
                      <i className="fal fa-copy" />
                    </IconButton>
                  </InputAdornment>
                ),
                disabled: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} className="form-input">
            <TextField
              fullWidth
              InputProps={{
                type: "text",
                className: "text-mini",
                value: attr.linechat,
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="text-title">Chat admin : </span>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="button"
                      className="form-icon"
                      onClick={() => copyText(attr.linechat)}
                    >
                      <i className="fal fa-copy" />
                    </IconButton>
                  </InputAdornment>
                ),
                disabled: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} className="form-input">
            <TextField
              fullWidth
              InputProps={{
                type: "text",
                className: "text-mini",
                value: attr.lineliff,
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="text-title">Liff App : </span>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="button"
                      className="form-icon"
                      onClick={() => copyText(attr.lineliff)}
                    >
                      <i className="fal fa-copy" />
                    </IconButton>
                  </InputAdornment>
                ),
                disabled: true,
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="affiliate">
          {renderAppBar()}
          {renderItem()}
          {renderForm()}
          {renderLine()}
          {renderLoading()}
          {notify}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Affiliate);
