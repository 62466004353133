import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { usePageName } from "react-page-name";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// @material-ui/icons

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import { dateDisplay } from "services/Function";

const useStyles = makeStyles(styles);

function Trend({ t }) {
  const mod = "trend";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  usePageName(t(`menu.trend`));

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setList(result.list);
      }
      setLoading(false);
    });
  };

  const renderList = () => {
    return (
      <Card className="card">
        <CardBody className="card-body">
          <table width="100%" cellPadding={2} cellSpacing={0} className="table">
            <tbody>
              {list.map((item, i) => (
                <tr key={item.code}>
                  <th align="left">{dateDisplay(item.date_sort)}</th>
                  <td className="td-1">{item.symbol_1}</td>
                  <td className="td-2">{item.symbol_2}</td>
                  <td className="td-3">{item.symbol_3}</td>
                  <td className="td-4">{item.symbol_4}</td>
                  <td className="td-5">{item.symbol_5}</td>
                  <td className="td-6">{item.symbol_6}</td>
                  <td className="td-7">{item.symbol_7}</td>
                  <td className="td-8">{item.symbol_8}</td>
                  <td className="td-9">{item.symbol_9}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>
      </Card>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.container}>
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={8} style={{ textAlign: "center" }}>
              <h4 className="appbar-title">{i18n.t(`menu.trend`)}</h4>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  return (
    <div className="block">
      {renderAppBar()}
      <div className={classes.container}>
        {renderList()}
        {renderLoading()}
      </div>
    </div>
  );
}

export default withNamespaces()(Trend);
