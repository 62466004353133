import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Badge from "components/Badge/Badge.js";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Grid from "@material-ui/core/Grid";

import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";

import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";
import "views/Style/DepositStyle.css";

const useStyles = makeStyles(styles);

function Donate({ t }) {
  const mod = "donate";
  const classes = useStyles();
  const history = useHistory();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [notify, setNotify] = useState(null);
  const [member, setMember] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [list, setList] = useState([]);
  const [amount, setAmount] = useState("");

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const init = () => {
    setLoading(true);

    setMember({});
    setList([]);
    setAmount("");

    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setMember(result.member);
        setList(result.history);
      } else {
        history.push("/home");
      }
      setLoading(false);
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tc"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const requestTopup = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_add_title")}
        onConfirm={() => {
          setAlert(null);

          setLoading(true);
          PostData(mod, "request", {
            token: app.token,
            lang: app.lang,
            amount: amount,
            id: id,
          }).then((result) => {
            if (result.status === "OK") {
              showNotify(t(result.msg), "success");
              history.push("/donate");
            } else if (result.status === "ALERT") {
              showNotify(t(result.msg), "danger");
            } else {
              history.push("/home");
            }
            setLoading(false);
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_add_msg")}
      </SweetAlert>
    );
  };

  const renderBalance = () => {
    if (id === "wallet")
      return (
        <div className="box-balance">
          <table width="100%" cellPadding={5} cellSpacing={0}>
            <tbody>
              <tr>
                <td className="icon">
                  <i className="fas fa-wallet" /> Wallet
                </td>
                <td className="balance">{member.balance} $</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    else if (id === "point")
      return (
        <div className="box-balance">
          <table width="100%" cellPadding={5} cellSpacing={0}>
            <tbody>
              <tr>
                <td className="icon">
                  <i className="fas fa-gift" /> Point
                </td>
                <td className="balance">{member.point} $</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
  };

  const renderTopup = () => {
    return (
      <div className="box-item">
        <form>
          <GridContainer>
            <GridItem xs={12}>
              <TextField
                id="amount"
                fullWidth
                InputProps={{
                  type: "number",
                  className: "text-topup",
                  value: amount,
                  onChange: (e) => {
                    setAmount(e.target.value);
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>{t(`deposit.amount`)} :</span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fas fa-dollar-sign" />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </GridItem>
          </GridContainer>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Button
                block
                className="btn-price"
                size="sm"
                color="tumblr"
                onClick={() => setAmount(`1`)}
              >
                1 $
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                block
                className="btn-price"
                size="sm"
                color="tumblr"
                onClick={() => setAmount(`10`)}
              >
                10 $
              </Button>
            </Grid>
            {id === "point" && member.point > 0 ?
              <Grid item xs={4}>
                <Button
                  block
                  className="btn-price"
                  size="sm"
                  color="tumblr"
                  onClick={() => setAmount(member.point)}
                >
                  {member.point} $
                </Button>
              </Grid> :
              <Grid item xs={4}>
                <Button
                  block
                  className="btn-price"
                  size="sm"
                  color="tumblr"
                  onClick={() => setAmount(`100`)}
                >
                  100 $
                </Button>
              </Grid>
            }
            <Grid item xs={12}>
              <Button
                block
                className="btn-submit"
                size="sm"
                color="success"
                onClick={() => requestTopup()}
              >
                <i className="fas fa-hand-holding-usd" /> {t(`deposit.donate`)}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };

  const renderHistory = () => {
    return (
      <div className="box-data">
        <h4 className="history-title">
          <i className="fas fa-history" /> {t(`deposit.donatehistory`)}
        </h4>
        <List dense={true}>
          {list.map((item) => (
            <div key={item.code}>
              <ListItem>
                <ListItemAvatar>
                  <i className="fad fa-envelope-open-dollar fa-2x color-default" />
                </ListItemAvatar>
                <ListItemText
                  primary={<>{Func.numberDisplay(item.amount)} $</>}
                  secondary={
                    <i className="text-secondary">
                      {Func.dateTimeDisplay(item.date_create)}
                    </i>
                  }
                />
                <ListItemSecondaryAction>
                  <Badge color={item.status_color}>
                    <i className={item.status_icon} /> {item.status_name}
                  </Badge>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider style={{ background: "#e2e2e2" }} variant="middle" />
            </div>
          ))}
        </List>
      </div>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={id === undefined ? "/home" : "/donate"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">{t(`menu.donate`)}</h3>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderList = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <List dense={true} className="box-data">
            {renderWallet()}
            {renderPoint()}
          </List>
        </Grid>
      </Grid>
    );
  };

  const renderWallet = () => {
    return (
      <NavLink to="/donate/wallet">
        <ListItem>
          <ListItemAvatar>
            <i className="fal fa-hand-holding-usd fa-2x color-primary" />
          </ListItemAvatar>
          <ListItemText
            primary={<div className="text-primary">{t(`deposit.donateby`)}  Wallet</div>}
            secondary={<i className="text-secondary">{member.balance} $</i>}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="listitem" className="status">
              <i className="fas fa-chevron-right" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </NavLink>
    );
  };

  const renderPoint = () => {
    return (
      <NavLink to="/donate/point">
        <ListItem>
          <ListItemAvatar>
            <i className="fal fa-hand-holding-heart fa-2x color-primary" />
          </ListItemAvatar>
          <ListItemText
            primary={<div className="text-primary">{t(`deposit.donateby`)} Point</div>}
            secondary={<i className="text-secondary">{member.point} $</i>}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="listitem" className="status">
              <i className="fas fa-chevron-right" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </NavLink>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="deposit">
          {renderAppBar()}
          {id === undefined ? (
            <div>
              {renderList()}
              {list.length > 0 ? renderHistory() : null}
            </div>
          ) : null}
          {id === undefined ? null : (
            <div>
              {renderBalance()}
              {renderTopup()}
            </div>
          )}
          {renderLoading()}
          {alert}
          {notify}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Donate);
