import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons

// core components
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import * as Func from "services/Function";

const useStyles = makeStyles(styles);

function Crypto({ t }) {
  const mod = "crypto";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [attr, setAttr] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setList(result.data);
      }
      setLoading(false);
    });
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={id === undefined ? "/home" : "/product"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">Crypto</h3>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderItem = () => {
    return (
      <div className="box-bg">
        <List dense={true}>
          {list.map((item, i) => (
            <div key={i} className="read">
              <ListItem button onClick={() => console.log(item)}>
                <ListItemAvatar>
                  <Avatar>
                    <img src={item.image} width="50" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<span>{item.name}</span>}
                  secondary={
                    item.price_change_percentage_24h > 0 ? (
                      <i className="color-success">
                        <i className="fas fa-clock" /> +
                        {item.price_change_percentage_24h} %
                      </i>
                    ) : (
                      <i className="color-danger">
                        <i className="fas fa-clock" />{" "}
                        {item.price_change_percentage_24h} %
                      </i>
                    )
                  }
                />
                <ListItemSecondaryAction>
                  {item.current_price} <i className="fas fa-dollar-sign" />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="middle" />
            </div>
          ))}
        </List>
      </div>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="shopping">
          {renderAppBar()}
          {renderItem()}
          {renderLoading()}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Crypto);
