import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import md5 from "md5";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// core components
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import "views/Style/SettingStyle.css";

const useStyles = makeStyles(styles);

function Business({ t }) {
  const mod = "profile";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const history = useHistory();
  const { id } = useParams();
  const [member, setMember] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setMember(result.member);
      }
      setLoading(false);
    });
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={"/home"}>
                <IconButton>
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              <h3 className="header-title">{t(`menu.business`)}</h3>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderList = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <List dense={true} className="box-biz">
            {renderBinary()}
            {renderSponsor()}
            {renderTransfer()}
            {renderReceive()}
            {renderCommission()}
            {renderCashflow()}
          </List>
        </Grid>
      </Grid>
    );
  };

  const renderBinary = () => {
    return (
      <NavLink to="/binary">
        <ListItem>
          <ListItemAvatar>
            <i className="fal fa-sitemap fa-2x color-primary" />
          </ListItemAvatar>
          <ListItemText
            primary={
              <div className="text-primary">{t(`business.network`)}</div>
            }
            secondary={
              <i className="text-secondary">{t(`business.network_detail`)}</i>
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="listitem" className="status">
              <i className="fas fa-chevron-right" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </NavLink>
    );
  };

  const renderSponsor = () => {
    return (
      <NavLink to="/sponsor">
        <ListItem>
          <ListItemAvatar>
            <i className="fal fa-address-book fa-2x color-primary" />
          </ListItemAvatar>
          <ListItemText
            primary={
              <div className="text-primary">{t(`business.sponsor`)}</div>
            }
            secondary={
              <i className="text-secondary">{t(`business.sponsor_detail`)}</i>
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="listitem" className="status">
              <i className="fas fa-chevron-right" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </NavLink>
    );
  };

  const renderTransfer = () => {
    return (
      <NavLink to="/transfer">
        <ListItem>
          <ListItemAvatar>
            <i className="fal fa-comments-dollar fa-2x color-primary" />
          </ListItemAvatar>
          <ListItemText
            primary={
              <div className="text-primary">{t(`business.transfer`)}</div>
            }
            secondary={
              <i className="text-secondary">{t(`business.transfer_detail`)}</i>
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="listitem" className="status">
              <i className="fas fa-chevron-right" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </NavLink>
    );
  };

  const renderReceive = () => {
    return (
      <NavLink to="/receive">
        <ListItem>
          <ListItemAvatar>
            <i className="fal fa-funnel-dollar fa-2x color-primary" />
          </ListItemAvatar>
          <ListItemText
            primary={
              <div className="text-primary">{t(`business.receive`)}</div>
            }
            secondary={
              <i className="text-secondary">{t(`business.receive_detail`)}</i>
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="listitem" className="status">
              <i className="fas fa-chevron-right" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </NavLink>
    );
  };

  const renderCommission = () => {
    return (
      <NavLink to="/commedit">
        <ListItem>
          <ListItemAvatar>
            <i className="fal fa-sack-dollar fa-2x color-primary" />
          </ListItemAvatar>
          <ListItemText
            primary={
              <div className="text-primary">{t(`business.commission`)}</div>
            }
            secondary={
              <i className="text-secondary">
                {member.payment === "1"
                  ? "Wallet"
                  : member.payment === "2"
                  ? "BUSD : " + member.busd
                  : "-"}
              </i>
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="listitem" className="status">
              <i className="fas fa-chevron-right" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </NavLink>
    );
  };

  const renderCashflow = () => {
    return (
      <NavLink to="/cashflow">
        <ListItem>
          <ListItemAvatar>
            <i className="fal fa-file-contract fa-2x color-primary" />
          </ListItemAvatar>
          <ListItemText
            primary={
              <div className="text-primary">{t(`business.rp_cashflow`)}</div>
            }
            secondary={
              <i className="text-secondary">
                {t(`business.rp_cashflow_detail`)}
              </i>
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="listitem" className="status">
              <i className="fas fa-chevron-right" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {/* <Divider className="divider" variant="middle" /> */}
      </NavLink>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="affiliate">
          {renderAppBar()}
          {renderList()}
          {renderLoading()}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Business);
