import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import CheckIcon from "@material-ui/icons/Check";

// core components
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import styles from "assets/jss/main-style.js";
import * as Func from "services/Function";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "views/Style/RobotStyle.css";

const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  id: "",
  date_expire: "",
  enable: "N",
  product_code: "",
  status: "",
  ea_code: "",
  port_id: "",
  port_margin: "0.00",
  port_credit: "0.00",
  port_profit: "0.00",
  port_equity: "0.00",
  port_balance: "0.00",
  port_winloss: "0.00",
  port_deposit: "0.00",
  port_withdraw: "0.00",
  accname: "",
  robot_version: "",
  leverage: "0",
  check_key: "N",
  check_secret: "Y",
  active: "OFF",
  symbol_code: "0",
  symbol: "",
  api_key: "",
  api_secret: "",
  expired: "N",
  showsignal: "N",
  signal_show: "OFF",
  cat_name: "",
  cat_code: "",
  product_name: "",
  broker_name: "",
  filepic: "",
  status_name: "",
  status_icon: "",
  status_color: "",
};

function Robot({ t }) {
  const mod = "robot";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [attr, setAttr] = useState(initAttr);
  const [list, setList] = useState([]);
  const [symbol, setSymbol] = useState([]);
  const [check, setCheck] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    if (id === undefined) init();
    else load();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setList(result.list);
      }
      setLoading(false);
    });
  };

  const load = () => {
    setLoading(true);
    PostData(mod, "load", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);
        setSymbol(result.symbol);

        let temp = {};
        result.check.map((item, i) => {
          temp[`check_${item.code}`] = item.enable;
        });

        setCheck(temp);
      }
      setLoading(false);
    });
  };

  const editStatus = () => {
    setLoading(true);
    setAttr({});
    PostData(mod, "changestatus", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        load();
      }
      setLoading(false);
    });
  };

  const editSignal = () => {
    setLoading(true);
    setAttr({});
    PostData(mod, "changesignal", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        load();
      }
      setLoading(false);
    });
  };

  const editCheck = (symbol_code, enable) => {
    PostData(mod, "editcheck", {
      token: app.token,
      lang: app.lang,
      id: id,
      symbol_code: symbol_code,
      enable: enable ? "Y" : "N",
    }).then((result) => {
      if (result.status === "OK") {
      }
    });
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              <NavLink to={id === undefined ? "/home" : "/robot"}>
                <IconButton aria-label="">
                  <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                </IconButton>
              </NavLink>
            </Grid>
            <Grid item xs={8}>
              {id === undefined ? (
                <h3 className="header-title">{t(`menu.robot`)}</h3>
              ) : (
                <h3 className="header-title">
                  {attr.cat_name} : {attr.id}
                </h3>
              )}
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderRobot = () => {
    return (
      <Grid container>
        <Grid item xs={12} style={{ padding: "5px 0" }}>
          {statusDisplay(attr)}
        </Grid>
        <Grid item xs={12}>
          <img src={attr.filepic} className="img" />
        </Grid>
        <Grid item xs={12}>
          {attr.cat_code === "1" ? (
            <List dense={true} className="box-item">
              {renderProductFix()}
              {renderStatus(t(`robot.status`), attr.active)}
              {renderSignal("Signal", attr.signal_show)}
              {renderBroker(
                "Broker",
                attr.broker_name === "" ? "-" : attr.broker_name,
                "fal fa-server"
              )}
              {renderPort(
                "Port No.",
                attr.port_id === "" ? "-" : attr.port_id,
                "fal fa-id-card-alt"
              )}
              {renderExpire(
                t(`robot.expired`),
                Func.dateDisplay(attr.date_expire),
                "fal fa-calendar-times"
              )}
            </List>
          ) : attr.cat_code === "2" ? (
            <List dense={true} className="box-item">
              {renderProduct()}
              {renderStatus(t(`robot.status`), attr.active)}
              {renderExpire(
                t(`robot.expired`),
                Func.dateDisplay(attr.date_expire),
                "fal fa-calendar-times"
              )}
            </List>
          ) : attr.cat_code === "3" ? (
            <List dense={true} className="box-item">
              {renderProductFix()}
              {renderStatus(t(`robot.status`), attr.active)}
              {renderBroker(
                "Broker",
                attr.broker_name === "" ? "-" : attr.broker_name,
                "fal fa-server"
              )}
              {renderPort(
                "Port No.",
                attr.port_id === "" ? "-" : attr.port_id,
                "fal fa-id-card-alt"
              )}
              {renderExpire(
                t(`robot.expired`),
                Func.dateDisplay(attr.date_expire),
                "fal fa-calendar-times"
              )}
            </List>
          ) : null}
        </Grid>
        {symbol.length === 0 ? null : (
          <Grid item xs={12}>
            {renderCoinBase()}
          </Grid>
        )}
        {attr.ea_code > 0 ? (
          <Grid item xs={12}>
            {renderPortStatus()}
          </Grid>
        ) : null}
      </Grid>
    );
  };

  const renderProduct = () => {
    return (
      <div>
        <ListItem>
          <ListItemAvatar>
            <i className="fal fa-robot fa-2x color-primary" />
          </ListItemAvatar>
          <ListItemText
            primary={<div>{t(`robot.robot`)}</div>}
            secondary={<i className="text-secondary">{attr.product_name}</i>}
          />
          <ListItemSecondaryAction>
            <NavLink to={`/robotchange/${id}`}>
              <IconButton edge="end" aria-label="delete" className="status">
                <i className="fal fa-edit" />
              </IconButton>
            </NavLink>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </div>
    );
  };

  const renderProductFix = () => {
    return (
      <div>
        <ListItem>
          <ListItemAvatar>
            <i className="fal fa-robot fa-2x color-primary" />
          </ListItemAvatar>
          <ListItemText
            primary={<div>{t(`robot.robot`)}</div>}
            secondary={<i className="text-secondary">{attr.product_name}</i>}
          />
        </ListItem>
        <Divider className="divider" variant="middle" />
      </div>
    );
  };

  const renderStatus = (name, value) => {
    let icon =
      value === "ON"
        ? "fal fa-toggle-on color-success"
        : "fal fa-toggle-off color-danger";
    return (
      <div>
        <ListItem>
          <ListItemAvatar>
            <i className={`${icon} fa-2x color-primary`} />
          </ListItemAvatar>
          <ListItemText
            primary={<div>{name}</div>}
            secondary={<i className="text-secondary">{value}</i>}
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="delete"
              className="status"
              onClick={editStatus}
            >
              <i className="fal fa-sync" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </div>
    );
  };

  const renderSignal = (name, value) => {
    let icon = value === "ON" ? "fal fa-toggle-on" : "fal fa-toggle-off";
    return (
      <div>
        <ListItem>
          <ListItemAvatar>
            <i className={`${icon} fa-2x color-primary`} />
          </ListItemAvatar>
          <ListItemText
            primary={<div>{name}</div>}
            secondary={<i className="text-secondary">{value}</i>}
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="delete"
              className="status"
              onClick={editSignal}
            >
              <i className="fal fa-sync" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </div>
    );
  };

  const renderEdit = (name, value, icon) => {
    return (
      <div>
        <ListItem>
          <ListItemAvatar>
            <i className={`${icon} fa-2x color-primary`} />
          </ListItemAvatar>
          <ListItemText
            primary={<div>{name}</div>}
            secondary={<i className="text-secondary">{value}</i>}
          />
          <ListItemSecondaryAction>
            <NavLink to={`/robotedit/${id}`}>
              <IconButton edge="end" aria-label="delete" className="status">
                <i className="fal fa-edit" />
              </IconButton>
            </NavLink>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </div>
    );
  };

  const renderSymbol = (name, value, icon) => {
    return (
      <div>
        <ListItem>
          <ListItemAvatar>
            <i className={`${icon} fa-2x color-primary`} />
          </ListItemAvatar>
          <ListItemText
            primary={<div>{name}</div>}
            secondary={<i className="text-secondary">{value}</i>}
          />
          <ListItemSecondaryAction>
            <NavLink to={`/robotsymbol/${id}`}>
              <IconButton edge="end" aria-label="delete" className="status">
                <i className="fal fa-edit" />
              </IconButton>
            </NavLink>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </div>
    );
  };

  const renderKey = (name, value, icon) => {
    return (
      <div>
        <ListItem>
          <ListItemAvatar>
            <i className={`${icon} fa-2x color-primary`} />
          </ListItemAvatar>
          <ListItemText
            primary={<div>{name}</div>}
            secondary={<i className="text-secondary">{value}</i>}
          />
          <ListItemSecondaryAction>
            <NavLink to={`/robotkey/${id}`}>
              <IconButton edge="end" aria-label="delete" className="status">
                <i className="fal fa-edit" />
              </IconButton>
            </NavLink>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </div>
    );
  };

  const renderBroker = (name, value, icon) => {
    return (
      <div>
        <ListItem>
          <ListItemAvatar>
            <i className={`${icon} fa-2x color-primary`} />
          </ListItemAvatar>
          <ListItemText
            primary={<div>{name}</div>}
            secondary={<i className="text-secondary">{value}</i>}
          />
          <ListItemSecondaryAction>
            <NavLink to={`/editbroker/${id}`}>
              <IconButton edge="end" aria-label="delete" className="status">
                <i className="fal fa-edit" />
              </IconButton>
            </NavLink>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </div>
    );
  };

  const renderPort = (name, value, icon) => {
    return (
      <div>
        <ListItem>
          <ListItemAvatar>
            <i className={`${icon} fa-2x color-primary`} />
          </ListItemAvatar>
          <ListItemText
            primary={<div>{name}</div>}
            secondary={<i className="text-secondary">{value}</i>}
          />
          <ListItemSecondaryAction>
            <NavLink to={`/editport/${id}`}>
              <IconButton edge="end" aria-label="delete" className="status">
                <i className="fal fa-edit" />
              </IconButton>
            </NavLink>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </div>
    );
  };

  const renderExpire = (name, value, icon) => {
    return (
      <div>
        <ListItem>
          <ListItemAvatar>
            <i className={`${icon} fa-2x color-primary`} />
          </ListItemAvatar>
          <ListItemText
            primary={<div>{name}</div>}
            secondary={<i className="text-secondary">{value}</i>}
          />
          <ListItemSecondaryAction>
            <NavLink to={`/renew/${id}`}>
              <IconButton edge="end" aria-label="add" className="status">
                <i className="fal fa-plus-square" />
              </IconButton>
            </NavLink>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className="divider" variant="middle" />
      </div>
    );
  };

  const statusDisplay = (item) => {
    if (item.cat_code === "1") {
      if (item.expired === "Y") {
        return (
          <h3 className="item-stop">
            <i className="fas fa-calendar-times" /> {t(`robot.expired`)}
          </h3>
        );
      } else if (item.enable === "N") {
        return (
          <h3 className="item-stop">
            <i className="far fa-stop-circle" /> {t(`robot.stop`)}
          </h3>
        );
      } else if (item.port_id === "") {
        return (
          <h3 className="item-stop">
            <i className="far fa-stop-circle" /> {t(`robot.stop`)}
          </h3>
        );
      } else if (item.enable === "Y") {
        return (
          <h3 className="item-run">
            <i className="fas fa-running" /> {t(`robot.running`)}
          </h3>
        );
      } else {
        return null;
      }
    } else if (item.cat_code === "2") {
      if (item.expired === "Y") {
        return (
          <h3 className="item-stop">
            <i className="fas fa-calendar-times" /> {t(`robot.expired`)}
          </h3>
        );
      } else if (item.enable === "N") {
        return (
          <h3 className="item-stop">
            <i className="far fa-stop-circle" /> {t(`robot.stop`)}
          </h3>
        );
      } else if (item.enable === "Y") {
        return (
          <h3 className="item-run">
            <i className="fas fa-running" /> {t(`robot.running`)}
          </h3>
        );
      } else {
        return null;
      }
    } else if (item.cat_code === "3") {
      if (item.expired === "Y") {
        return (
          <h3 className="item-stop">
            <i className="fas fa-calendar-times" /> {t(`robot.expired`)}
          </h3>
        );
      } else if (item.enable === "N") {
        return (
          <h3 className="item-stop">
            <i className="far fa-stop-circle" /> {t(`robot.stop`)}
          </h3>
        );
      } else if (item.enable === "Y") {
        return (
          <h3 className="item-run">
            <i className="fas fa-running" /> {t(`robot.running`)}
          </h3>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const renderList = () => {
    return list.map((item) => (
      <div key={item.code} className="box-item">
        <NavLink to={`/robot/${item.code}`}>
          <table width="100%" cellPadding={3} cellSpacing={0} border="0">
            <tbody>
              <tr>
                <td colSpan={2}>{statusDisplay(item)}</td>
              </tr>
              <tr>
                <td width="50%">
                  <img src={item.filepic} className="item-img" />
                </td>
                {item.cat_code === "1" ? (
                  <td valign="top">
                    <h3 className="item-price">ID : {item.id}</h3>
                    <p className="item-pv">Robot : {item.ea_name}</p>
                    <p className="item-pv">Port ID : {item.port_id}</p>
                    <p className="item-pv">Balance : {item.port_balance} $</p>
                    <p className="item-pv">Profit : {item.port_winloss} $</p>
                    <p className="item-expire">
                      {t(`robot.expired`)} :{" "}
                      {Func.dateDisplay(item.date_expire)}
                    </p>
                  </td>
                ) : item.cat_code === "2" ? (
                  <td valign="top">
                    <h3 className="item-price">ID : {item.id}</h3>
                    <h3 className="item-price">
                      {t(`robot.type`)} : {item.cat_name}
                    </h3>
                    <p className="item-pv">
                      {t(`robot.robot`)} : {item.product_name}
                    </p>
                    <p className="item-expire">
                      {t(`robot.expired`)} :{" "}
                      {Func.dateDisplay(item.date_expire)}
                    </p>
                  </td>
                ) : item.cat_code === "3" ? (
                  <td valign="top">
                    <h3 className="item-price">ID : {item.id}</h3>
                    <h3 className="item-price">
                      {t(`robot.type`)} : {item.cat_name}
                    </h3>
                    <p className="item-pv">
                      {t(`robot.robot`)} : {item.product_name}
                    </p>
                    <p className="item-pv">Broker : {item.broker_name}</p>
                    <p className="item-pv">Port ID : {item.port_id}</p>
                    <p className="item-expire">
                      {t(`robot.expired`)} :{" "}
                      {Func.dateDisplay(item.date_expire)}
                    </p>
                  </td>
                ) : null}
              </tr>
            </tbody>
          </table>
        </NavLink>
      </div>
    ));
  };

  const renderCoinBase = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <List dense={true}>
            {symbol.map((item, i) => (
              <div key={i} className="read">
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <img src={item.filepic} width="50" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<span className="text-primary">{item.name}</span>}
                    secondary={
                      <span className="text-secondary">
                        <i>{item.detail}</i>
                      </span>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      checked={check[`check_${item.code}`] === "Y"}
                      name={`check_${item.code}`}
                      tabIndex={-1}
                      onChange={(e) => {
                        editCheck(item.code, e.target.checked);
                        setCheck({
                          ...check,
                          [e.target.name]: e.target.checked ? "Y" : "N",
                        });
                      }}
                      checkedIcon={
                        <CheckIcon className={classes.checkedIcon} />
                      }
                      icon={<CheckIcon className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider className="divider" variant="middle" />
              </div>
            ))}
          </List>
        </Grid>
      </Grid>
    );
  };

  const renderPortStatus = () => {
    return (
      <Grid container className="box-item">
        <Grid item xs={12}>
          <table
            className="portstatus"
            width="100%"
            border={0}
            cellPadding={5}
            cellSpacing={0}
          >
            <tbody>
              <tr>
                <td width="30%">Robot :</td>
                <td>{attr.ea_name}</td>
              </tr>
              <tr>
                <td>Version :</td>
                <td>{attr.robot_version}</td>
              </tr>
              <tr>
                <td>Port name :</td>
                <td>{attr.accname}</td>
              </tr>
              <tr>
                <td>Port No. :</td>
                <td>{attr.port_id}</td>
              </tr>
              <tr>
                <td>Borker :</td>
                <td>{attr.broker_name}</td>
              </tr>
              <tr>
                <td>Leverage :</td>
                <td>{attr.leverage}</td>
              </tr>
              <tr>
                <td>Balance :</td>
                <td>{attr.port_balance}</td>
              </tr>
              <tr>
                <td>Credit :</td>
                <td>{attr.port_credit}</td>
              </tr>
              <tr>
                <td>Margin :</td>
                <td>{attr.port_margin}</td>
              </tr>
              <tr>
                <td>Profit :</td>
                <td>{attr.port_profit}</td>
              </tr>
              <tr>
                <td>Equity :</td>
                <td>{attr.port_equity}</td>
              </tr>
              <tr>
                <td>Deposit :</td>
                <td>{attr.port_deposit}</td>
              </tr>
              <tr>
                <td>Withdraw :</td>
                <td>{attr.port_withdraw}</td>
              </tr>
              <tr>
                <td>Profit/Loss :</td>
                <td>{attr.port_winloss}</td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="robot">
          {renderAppBar()}
          {id === undefined ? renderList() : renderRobot()}
          {renderLoading()}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Robot);
