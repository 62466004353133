import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import YouTube from "react-youtube";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import IconButton from "@material-ui/core/IconButton";

// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import i18n from "components/Translation/Translate.js";

import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";
import "views/Style/ProfileStyle.css";

const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  detail: "",
  filepic: "",
  name: "",
  youtube: "",
};

function Manual({ t }) {
  const mod = "manual";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [manual, setManual] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [list, setList] = useState([]);

  useEffect(() => {
    // i18n.changeLanguage(app.lang);
    i18n.changeLanguage("th");

    if (id === undefined) {
      init();
    } else {
      load();
    }
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: "th",
    }).then((result) => {
      if (result.status === "OK") {
        setManual(result.manual);
      }
      setLoading(false);
    });
  };

  const load = () => {
    setAttr(initAttr);
    PostData(mod, "load", {
      token: app.token,
      lang: "th",
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);
        setList(result.list);
      } else {
        Func.exitApp();
      }
    });
  };

  const renderAppBar = () => {
    return (
      <AppBar position="static" className="header-bg">
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={2}>
              {id == undefined ? (
                <IconButton aria-label="">
                  <i className="fas fa-question-circle toolbar-icon" />
                </IconButton>
              ) : (
                <NavLink to={"/manual"}>
                  <IconButton aria-label="">
                    <i className="fas fa-chevron-left fa-fw toolbar-icon" />
                  </IconButton>
                </NavLink>
              )}
            </Grid>
            <Grid item xs={8}>
              {id === undefined ? (
                <h3 className="header-title">{t(`menu.manual`)}</h3>
              ) : (
                <h3 className="header-title">{attr.name}</h3>
              )}
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  const renderManual = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <br />
        </Grid>
        {manual.map((item) => (
          <Grid item xs={12} key={item.code}>
            <Card className="box">
              <CardHeader className="box-header">
                <h4 className="manual-title">
                  <i className="fad fa-bullseye-pointer" /> {item.name}
                </h4>
              </CardHeader>
              <CardBody>
                <div className="fileinput">
                  <NavLink to={`/manual/${item.code}`}>
                    <img
                      src={item.filepic}
                      className={"thumbnail"}
                      style={{ maxWidth: "100%" }}
                    />
                  </NavLink>
                </div>
                <div className="manual-detail">
                  <span dangerouslySetInnerHTML={{ __html: item.detail }} />
                </div>
              </CardBody>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderList = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <br />
        </Grid>
        {attr.youtube === "" ? null : (
          <Grid item xs={12}>
            <Card className="box-in">
              <CardBody>
                <YouTube
                  videoId={attr.youtube}
                  opts={{
                    height: "190",
                    width: "340",
                    playerVars: {
                      autoplay: 0,
                    },
                  }}
                  onReady={(e) => {
                    e.target.pauseVideo();
                  }}
                />
              </CardBody>
            </Card>
          </Grid>
        )}
        {list.map((item, i) => (
          <Grid item xs={12} key={item.code}>
            <Card className="box-in">
              <CardHeader className="box-header">
                <h4 className="manual-intitle">
                  {i + 1}. {item.name}
                </h4>
              </CardHeader>
              <CardBody>
                <div className="fileinput">
                  <img
                    src={item.filepic}
                    className={"thumbnail"}
                    style={{ maxWidth: "100%" }}
                  />
                </div>
                <div className="manual-indetail">
                  <span dangerouslySetInnerHTML={{ __html: item.detail }} />
                </div>
              </CardBody>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <div className="block">
      <div className={classes.container}>
        <div className="profile">
          {renderAppBar()}
          {id === undefined ? renderManual() : renderList()}
          {renderLoading()}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Manual);
